import { useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { verifyPayment } from "../../../network/network";
import { AppContext } from "../../../context/AppContextProvider";
import NotificationWithIcon from "../../../components/NotificationWithIcon";
import { useTranslation } from "react-i18next";

const Payments = () => {
  const { setFullPageLoading } = useContext(AppContext);
  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    if (window.location.search !== "") {
      let params = new URLSearchParams(window.location.search);
      if (params.get("id")) {
        setFullPageLoading(true);
        setTimeout(function () {
          verifyPayment({ checkoutID: params.get("id") })
            .then((response) => {
              if (
                response.data &&
                response.data.result &&
                response.data.result.code &&
                response.data.result.code.split(".")[0] === "000"
              ) {
                // Payment successfull
                NotificationWithIcon(
                  "success",
                  t("payment_successful"),
                  t("your_payment_successfully")
                );
              } else {
                // TODO: Payment Unsuccessful
                NotificationWithIcon(
                  "error",
                  t("payment_failed"),
                  t("try_again_payment_failed")
                );
              }
              navigate("/invoices");
            })
            .finally(() => {
              setFullPageLoading(false);
            });
        }, 1000);
      } else {
        navigate("/");
      }
    } else {
      navigate("/");
    }
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <div
        className="overlay"
        style={{
          position: "absolute",
          left: "0",
          top: "0",
          background: "#ffffff",
          width: "100%",
          height: "100%",
          opacity: "0.5",
          zIndex: "1",
        }}
      ></div>
      <div style={{ position: "relative" }}>
        <div
          style={{
            position: "absolute",
            top: "120px",
            left: "calc(50% - 160px)",
            zIndex: "9",
          }}
        >
          <img
            src="/images/payment-animation.gif"
            alt="Payment Processing"
            style={{ width: "350px", height: "325px" }}
          />
        </div>
      </div>
    </>
  );
};

export default Payments;
