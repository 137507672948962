import "./Employees.css";
import React, { useEffect, useState, useContext } from "react";
import {
  Card,
  Form,
  Select,
  Input,
  Button,
  Table,
  Modal,
  Tag,
  Radio,
  Popconfirm,
  Dropdown,
  Col,
  Row,
  Space,
  Drawer,
} from "antd";
import { PageHeader } from "@ant-design/pro-layout";
import {
  HomeOutlined,
  TeamOutlined,
  EditTwoTone,
  LockOutlined,
  UnlockOutlined,
  DeleteTwoTone,
  DownOutlined,
  CheckOutlined,
  CloseOutlined,
  MinusOutlined,
  PlusOutlined,
  EyeTwoTone,
} from "@ant-design/icons";
import {
  getEmployees,
  getBranches,
  createEmployee,
  updateEmployee,
  activateEmployee,
  deactivateEmployee,
  getSubscriptions,
  generateLicenseInvoice,
  verifyPayment,
  deleteEmployee,
  machineUserToggle,
  getdevices,
  sendEmployeeToOdoo,
} from "../../../network/network";
import CustomBreadcrumb from "../../../components/CustomBreadcrumb";
import NotificationWithIcon from "../../../components/NotificationWithIcon";
import { useTranslation } from "react-i18next";
import {
  ADDON_CUSTOMERS_MANAGEMENT,
  ADDON_JOB_MANAGEMENT,
  ADDON_SALES_INVOICE,
  ADDON_STOCK_MANAGEMENT,
  ADDON_WAITER_MANAGEMENT,
  PLAN_TYPE_PRO,
  USER_TYPE_BUSINESS_OWNER,
} from "../../../configs/constants";
import { AppContext } from "../../../context/AppContextProvider";
import HyperPayPaymentModal from "../../../components/HyperPayPaymentModal";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

const Employees = () => {
  const { authUser, appConfig } = useContext(AppContext);
  const ADD_EMPLOYEE = "Add Employee";
  const { setFullPageLoading } = useContext(AppContext);
  const [loading, setLoading] = useState(false);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [drawerData, setDrawerData] = useState([]);
  const [addEmployeeDrawer, setAddEmployeeDrawer] = useState(false);
  const [editEmployeeDrawer, setEditEmployeeDrawer] = useState(false);
  const [drawerTitle, SetDrawerTitle] = useState(ADD_EMPLOYEE);
  const [addLicenseModalVisible, setAddLicenseModalVisible] = useState(false);
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [reloadTableData, setReloadTableData] = useState(false);
  const [validatePassword, setValidatePassword] = useState(true);
  const [activeSubscription, setActiveSubscription] = useState(false);
  const [freeTrialOver, setFreeTrialOver] = useState(false);
  const [usersCount, setUsersCount] = useState(1);
  const [branches, setBranches] = useState([]);
  const [employeesTableData, setEmployeesTableData] = useState([]);
  const [invoice, setInvoice] = useState(false);
  const [paymentModalVisible, setPaymentModalVisible] = useState(false);
  const [machineUserEmployee, setMachineUserEmployee] = useState([]);
  const [devicesData, setDevicesData] = useState([]);
  const [employeeForm] = Form.useForm();
  const [addLicenseForm] = Form.useForm();
  const { t } = useTranslation();

  const is_waiter_management_active = authUser.active_addons.some(
    (addon) => addon.id && addon.name === ADDON_WAITER_MANAGEMENT
  );
  const is_job_management_active = authUser.active_addons.some(
    (addon) => addon.id && addon.name === ADDON_JOB_MANAGEMENT
  );
  const is_stock_management_active = authUser.active_addons.some(
    (addon) => addon.id && addon.name === ADDON_STOCK_MANAGEMENT
  );
  const is_customer_management_active = authUser.active_addons.some(
    (addon) => addon.id && addon.name === ADDON_CUSTOMERS_MANAGEMENT
  );
  const is_sales_invoice_active = authUser.active_addons.some(
    (addon) => addon.id && addon.name === ADDON_SALES_INVOICE
  );

  useEffect(() => {
    setIsDataLoading(true);
    getEmployees()
      .then((response) => {
        setEmployeesTableData(
          response.data.data.owner.concat(response.data.data.employees)
        );
        getSubscriptions().then((response) => {
          setActiveSubscription(response.data.data.active_subscription);
          setFreeTrialOver(response.data.data.is_free_trial_over);
        });
      })
      .finally(() => {
        setIsDataLoading(false);
      });
  }, [reloadTableData]);

  useEffect(() => {
    setIsDataLoading(true);
  }, [reloadTableData]);

  useEffect(() => {
    if (window.location.search !== "") {
      let params = new URLSearchParams(window.location.search);
      if (params.get("id")) {
        setFullPageLoading(true);
        setTimeout(function () {
          verifyPayment({ checkoutID: params.get("id") })
            .then((response) => {
              if (
                response.data &&
                response.data &&
                response.data.result &&
                response.data.result.code
              ) {
                // Payment successfull
                if (response.data.result.code.split(".")[0] === "000") {
                  window.location =
                    window.location.pathname + "?payment_successful=1";
                } else {
                  window.location =
                    window.location.pathname + "?payment_successful=0";
                }
              }
            })
            .finally(() => {
              setFullPageLoading(false);
            });
        }, 1000);
      } else if (params.get("payment_successful")) {
        if (params.get("payment_successful") === "1") {
          NotificationWithIcon(
            "success",
            t("payment_successfull"),
            t("payment_successfull")
          );
        } else {
          NotificationWithIcon(
            "error",
            t("something_went_wrong"),
            t("payment_not_successfull")
          );
        }
      }
    }
  }, [setFullPageLoading, t]);

  useEffect(() => {
    setIsDataLoading(true);
    getBranches().then((response) => {
      setBranches(response.data.data.branches);
    });
    getdevices()
      .then((response) => {
        setDevicesData(response.data.data.devices);
      })
      .finally(() => {
        setIsDataLoading(false);
      });
  }, []);

  const showAddModal = () => {
    setAddEmployeeDrawer(true);
    setEditEmployeeDrawer(false);
    // Set Modal Title
    SetDrawerTitle(t("employee_add"));
    // set Form Fields
    employeeForm.setFieldsValue({
      allow_editable_price: false,
      allow_discount: false,
      can_see_transactions: false,
      can_refund_transaction: false,
      is_machine_user: false,
      can_add_edit_product: false,
      is_waiter: false,
      can_request_stock_transfer: false,
      can_approve_stock_transfer: false,
      can_request_stock_adjustment: false,
      can_add_edit_customer: false,
      can_view_customer: false,
      can_add_pay_sales_invoice: false,
      can_view_sales_invoice: false,
    });
    // Show Modal
    setDrawerVisible(true);
    // Enable Password Validation
    setValidatePassword(true);
  };

  const handleAPIResponse = (response, type) => {
    let data = response.data || response.response.data;
    if (data.success === true || typeof data.success !== "undefined") {
      setReloadTableData(!reloadTableData);
      // Success
      if (type === ADD_EMPLOYEE) {
        NotificationWithIcon(
          "success",
          t("employee_added"),
          t("employee_added_successfully")
        );
      } else {
        NotificationWithIcon(
          "success",
          t("employee_updated"),
          t("employee_updated_successfully")
        );
      }
      // Reset Form Fields
      employeeForm.resetFields();
      // Hide Modal
      setDrawerVisible(false);
    } else {
      // Error
      let errors = response.response.data.errors;
      if (errors && Object.keys(errors).length) {
        NotificationWithIcon(
          "error",
          t("something_went_wrong"),
          errors[Object.keys(errors)[0]]
        );
      }
    }
  };

  const handleAddEditEmployee = () => {
    employeeForm
      .validateFields()
      .then((values) => {
        // Set Loading True
        setLoading(true);

        // Sending Request to API
        if (drawerTitle === ADD_EMPLOYEE || drawerTitle === t("employee_add")) {
          createEmployee(values)
            .then((response) => handleAPIResponse(response, ADD_EMPLOYEE))
            .catch((info) => {
              console.log("API Failed:", info.response);
              // Error
              NotificationWithIcon(
                "error",
                t("something_went_wrong"),
                info.response.data.message
              );
            })
            .finally(() => {
              // Set Loading False
              setLoading(false);
            });
        } else {
          updateEmployee(values)
            .then((response) => handleAPIResponse(response, null))
            .catch((info) => {
              console.log("API Failed:", info.response);
              // Error
              NotificationWithIcon(
                "error",
                t("something_went_wrong"),
                info.response.data.message
              );
            })
            .finally(() => {
              // Set Loading False
              setLoading(false);
            });
        }
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
  };

  const handleViewDrawer = (employee) => {
    // Set Modal Title
    SetDrawerTitle(employee.name);
    setDrawerData(employee);
    // Show Modal
    setDrawerVisible(true);
    setEditEmployeeDrawer(false);
    setAddEmployeeDrawer(false);
  };

  const handleEditModal = (employee) => {
    // Show Modal
    setEditEmployeeDrawer(true);
    // Explicitly reset Form Fields before setting them for edit
    // This ensures the form is in a clean state before being repopulated
    employeeForm.resetFields();

    // Set Form Fields
    employeeForm.setFieldsValue({
      id: employee.id,
      first_name: employee.first_name,
      last_name: employee.last_name,
      phone: employee.phone,
      branch_id: employee.branch_id,
      allow_editable_price: employee.allow_editable_price ? true : false,
      allow_discount: employee.allow_discount ? true : false,
      can_see_transactions: employee.can_see_transactions ? true : false,
      can_refund_transaction: employee.can_refund_transaction ? true : false,
      is_machine_user: employee.is_machine_user ? true : false,
      can_add_edit_product: employee.can_add_edit_product ? true : false,
      is_waiter: employee.is_waiter ? true : false,
      can_request_stock_transfer: employee.can_request_stock_transfer
        ? true
        : false,
      can_approve_stock_transfer: employee.can_approve_stock_transfer
        ? true
        : false,
      can_request_stock_adjustment: employee.can_request_stock_adjustment
        ? true
        : false,
      can_add_edit_customer: employee.can_add_edit_customer ? true : false,
      can_view_customer: employee.can_view_customer ? true : false,
      can_add_pay_sales_invoice: employee.can_add_pay_sales_invoice
        ? true
        : false,
      can_view_sales_invoice: employee.can_view_sales_invoice ? true : false,
    });

    // Disable Password Validation
    setValidatePassword(false);
  };

  const handleCancel = () => {
    // Reset Form Fields
    employeeForm.resetFields();
    setDrawerVisible(false);
    setEditEmployeeDrawer(false);
    setAddEmployeeDrawer(false);
    setDrawerData([]);
  };

  const handleDeleteEmployee = (id) => {
    deleteEmployee(id)
      .then((response) => {
        if (response.data.success === true) {
          setReloadTableData(!reloadTableData);
          handleCancel();
          // Success
          NotificationWithIcon(
            "success",
            t("employee_deleted"),
            t("employee_deleted_successfully")
          );
        } else {
          // Error
          NotificationWithIcon(
            "error",
            t("something_went_wrong"),
            response.data.message
          );
        }
      })
      .catch((info) => {
        // Error
        console.log("Delete error: ", info.response.data.message);
        NotificationWithIcon(
          "error",
          t("something_went_wrong"),
          info.response.data.message
        );
      });
  };

  const passwordValueChanged = (e) => {
    if (
      e.target.value.length === 0 &&
      !(drawerTitle === ADD_EMPLOYEE || drawerTitle === t("employee_add"))
    ) {
      setValidatePassword(false);
    } else {
      setValidatePassword(true);
    }
  };

  const handleActivate = (employee_id) => {
    activateEmployee(employee_id).then((response) => {
      if (response.data.success === true) {
        setReloadTableData(!reloadTableData);
        NotificationWithIcon(
          "success",
          t("employee_activated"),
          t("employee_activated_successfully")
        );
      } else {
        // Error
        NotificationWithIcon(
          "error",
          t("something_went_wrong"),
          response.data.message
        );
      }
    });
  };

  const handleDeactivate = (employee_id) => {
    deactivateEmployee(employee_id).then((response) => {
      if (response.data.success === true) {
        setReloadTableData(!reloadTableData);
        NotificationWithIcon(
          "success",
          t("employee_deactivated"),
          t("employee_deactivated_successfully")
        );
      } else {
        // Error
        NotificationWithIcon(
          "error",
          t("something_went_wrong"),
          response.data.message
        );
      }
    });
  };

  const showBuyMoreLicenseNotification = () => {
    // Error
    NotificationWithIcon(
      "error",
      t("buy_more_license"),
      t("buy_more_license_error")
    );
  };

  const showAddLicenseModal = () => {
    setUsersCount(1);
    setAddLicenseModalVisible(true);
  };

  const handleAddLicense = () => {
    generateLicenseInvoice({ users_count: usersCount })
      .then((response) => {
        if (response.data.success === true) {
          setInvoice(response.data.data.invoice);
          setPaymentModalVisible(true);
          setReloadTableData(!reloadTableData);
          NotificationWithIcon(
            "success",
            t("user_licenses_added_successfully"),
            t("user_licenses_have_been_added_successfully")
          );
        } else {
          // Error
          NotificationWithIcon(
            "error",
            t("something_went_wrong"),
            t(response.data.message)
          );
        }
      })
      .finally(() => {
        setAddLicenseModalVisible(false);
      });
  };

  const handleMachineUserToggle = (e) => {
    machineUserToggle(machineUserEmployee).then((response) => {
      let data = response.data || response.response.data;
      if (data.success === true) {
        setReloadTableData(!reloadTableData);
        NotificationWithIcon(
          "success",
          t("employee_updated"),
          t("employee_updated_successfully")
        );
      } else {
        // Error
        NotificationWithIcon("error", t("something_went_wrong"), data.message);
      }
    });
  };

  const handleSendToOdoo = (id) => {
    sendEmployeeToOdoo(id)
      .then((response) => {
        let data = response.data || response.response.data;
        if (data.success === true) {
          setReloadTableData(!reloadTableData);
          // Success
          NotificationWithIcon(
            "success",
            t("send_to_odoo"),
            t("item_send_to_odoo_successfully")
          );
        } else {
          // Error
          NotificationWithIcon(
            "error",
            t("something_went_wrong"),
            data.message
          );
        }
      })
      .catch((info) => {
        console.log("API Failed:", info.response);
      });
  };

  const menuYes = [
    {
      key: "1",
      label: <div onClick={() => handleMachineUserToggle()}>{t("no")}</div>,
    },
  ];

  const menuNo = [
    {
      key: "2",
      label: <div onClick={() => handleMachineUserToggle()}>{t("yes")}</div>,
    },
  ];

  const breadcrumbRoutes = [
    {
      path: "/",
      breadcrumbName: t("menu_home"),
      icon: <HomeOutlined />,
    },
    {
      path: "employees",
      breadcrumbName: t("menu_employees"),
      icon: <TeamOutlined />,
    },
  ];

  const detailsPartColumns = [
    {
      title: "#",
      key: "index",
      width: "5%",
      render: (text, record, index) => {
        return <>{index + 1}</>;
      },
    },
    {
      title: t("name"),
      dataIndex: "name",
    },
    {
      title: t("phone_number"),
      dataIndex: "phone",
    },
    {
      title: t("branch"),
      align: "center",
      dataIndex: "branch_name",
    },
    {
      title: t("col_type"),
      align: "center",
      render: (record) => {
        return (
          <>
            {record.type === USER_TYPE_BUSINESS_OWNER ? (
              <Tag color="green">{t("owner")}</Tag>
            ) : (
              <Tag color="blue">{t("employee")}</Tag>
            )}
            {(is_waiter_management_active || is_job_management_active) &&
              record.is_waiter && (
                <Tag style={{ marginTop: "5px" }} color="orange">
                  {is_waiter_management_active ? t("waiter") : t("job_person")}
                </Tag>
              )}
          </>
        );
      },
    },
    {
      title: t("col_status"),
      align: "center",
      render: (employee) => {
        return (
          <>
            {employee.is_active ? (
              <Button
                type="link"
                onClick={() =>
                  employee.type !== USER_TYPE_BUSINESS_OWNER &&
                  handleDeactivate(employee.id)
                }
                style={{ fontSize: "18px", color: "#087c08" }}
              >
                <UnlockOutlined title={t("active")} />
              </Button>
            ) : (
              <Button
                type="link"
                onClick={() =>
                  employee.type !== USER_TYPE_BUSINESS_OWNER &&
                  handleActivate(employee.id)
                }
                style={{ fontSize: "18px", color: "#ff0000" }}
              >
                <LockOutlined title={t("inactive")} />
              </Button>
            )}
          </>
        );
      },
    },
    {
      title: t("is_machine_user"),
      render: (employee) => {
        return (
          <>
            {employee.type !== USER_TYPE_BUSINESS_OWNER &&
            devicesData.length !== 0 ? (
              <div>
                {employee.is_machine_user ? (
                  <Dropdown
                    menu={{ items: menuYes }}
                    trigger={["click"]}
                    onClick={() => setMachineUserEmployee(employee.id)}
                  >
                    <Button className="btn-success">
                      {t("yes")}
                      <DownOutlined />
                    </Button>
                  </Dropdown>
                ) : (
                  <Dropdown
                    menu={{ items: menuNo }}
                    trigger={["click"]}
                    onClick={() => setMachineUserEmployee(employee.id)}
                  >
                    <Button danger>
                      {t("no")}
                      <DownOutlined />
                    </Button>
                  </Dropdown>
                )}
              </div>
            ) : employee.is_machine_user ? (
              <Button className="btn-success">{t("yes")}</Button>
            ) : (
              <Button danger>{t("no")}</Button>
            )}
          </>
        );
      },
    },
  ];

  const odooStatusColumn = [
    {
      title: t("col_odoo_status"),
      align: "center",
      render: (record) => {
        return (
          <>
            {record.odoo_reference_id ? (
              <CheckOutlined style={{ fontSize: "22px", color: "#52c41a" }} />
            ) : (
              <>
                <CloseOutlined
                  style={{
                    fontSize: "22px",
                    color: "#ff0000",
                    display: "block",
                    paddingBottom: "5px",
                  }}
                />
                <Button
                  type="primary"
                  size="small"
                  style={{ marginLeft: "10px" }}
                  loading={loading}
                  onClick={() => handleSendToOdoo(record.id)}
                >
                  {t("send_to_odoo")}
                </Button>
              </>
            )}
          </>
        );
      },
    },
  ];

  const actionPartColumn = [
    {
      title: t("col_action"),
      align: "center",
      width: "100px",
      render: (employee) => {
        return (
          <>
            <Space
              style={{
                display: "flex",
                justifyContent: "center",
                columnGap: "20px",
                padding: "5px 0",
              }}
            >
              {employee.type !== USER_TYPE_BUSINESS_OWNER && (
                <Button
                  type="link"
                  key="edit"
                  onClick={() => {
                    setDrawerVisible(true);
                    SetDrawerTitle(employee.name);
                    handleEditModal(employee);
                  }}
                  title={t("edit")}
                  style={{ padding: "0" }}
                >
                  <EditTwoTone twoToneColor="#0000ff" />
                </Button>
              )}
              <Button
                type="link"
                key="View"
                title="View Details"
                onClick={() => handleViewDrawer(employee)}
                style={{ padding: "0" }}
              >
                <EyeTwoTone twoToneColor="#0000ff" />
              </Button>
              {employee.type !== USER_TYPE_BUSINESS_OWNER && (
                <Popconfirm
                  title={t("warning_delete")}
                  onConfirm={() => handleDeleteEmployee(employee.id)}
                  okText={t("yes")}
                  cancelText={t("no")}
                  key="delete"
                >
                  <DeleteTwoTone
                    twoToneColor="#ff0000"
                    title={t("delete_entity")}
                  />
                </Popconfirm>
              )}
            </Space>
          </>
        );
      },
    },
  ];

  return (
    <>
      <Card style={{ marginBottom: "20px" }}>
        <CustomBreadcrumb menus={breadcrumbRoutes} />
        <PageHeader
          style={{ padding: "0" }}
          title={t("menu_employees")}
          ghost={false}
          extra={[
            activeSubscription && (
              <div key={1}>
                Licenses Used {activeSubscription.used_users_count} out of{" "}
                {activeSubscription
                  ? activeSubscription.user_licenses_count
                  : "N/A"}
              </div>
            ),

            activeSubscription &&
              activeSubscription.used_users_count !==
                activeSubscription.user_licenses_count && (
                <Button
                  key="open-add-employee-modal"
                  className="btn-success"
                  onClick={
                    activeSubscription.used_users_count <
                    activeSubscription.user_licenses_count
                      ? showAddModal
                      : showBuyMoreLicenseNotification
                  }
                >
                  + {t("employee_add")}
                </Button>
              ),

            activeSubscription && (
              <Button
                key="open-add-license-modal"
                type="primary"
                onClick={showAddLicenseModal}
              >
                +{" "}
                {activeSubscription.is_trial &&
                activeSubscription.type === PLAN_TYPE_PRO &&
                freeTrialOver === false
                  ? t("add_free_license")
                  : t("add_license")}
              </Button>
            ),
          ]}
        />
      </Card>
      <Card>
        <Table
          columns={
            authUser.external_integrations.length
              ? [
                  ...detailsPartColumns,
                  ...odooStatusColumn,
                  ...actionPartColumn,
                ]
              : [...detailsPartColumns, ...actionPartColumn]
          }
          dataSource={employeesTableData}
          loading={isDataLoading}
          rowKey="id"
          pagination={false}
        />
      </Card>
      {/* Add Edit Employee Modal */}
      <Drawer
        title={drawerTitle}
        placement={appConfig.direction === "rtl" ? "left" : "right"}
        onClose={handleCancel}
        open={drawerVisible}
        width={1000}
        closable={false}
        footer={
          editEmployeeDrawer || addEmployeeDrawer ? (
            <Space
              style={{
                display: "flex",
                justifyContent: "center",
                columnGap: "20px",
                padding: "5px 0",
              }}
            >
              {editEmployeeDrawer && (
                <Button key="back" loading={loading} onClick={handleCancel}>
                  {t("cancel")}
                </Button>
              )}
              <Button
                key="submit"
                type="primary"
                loading={loading}
                onClick={handleAddEditEmployee}
              >
                {drawerTitle === ADD_EMPLOYEE ||
                drawerTitle === t("employee_add")
                  ? t("employee_add")
                  : t("update")}
              </Button>
            </Space>
          ) : (
            drawerData.type !== USER_TYPE_BUSINESS_OWNER && (
              <Space
                style={{
                  display: "flex",
                  justifyContent: "center",
                  columnGap: "20px",
                  padding: "5px 0",
                }}
              >
                <EditTwoTone
                  key="edit"
                  onClick={() => handleEditModal(drawerData)}
                  style={{
                    padding: 6,
                    fontSize: "18px",
                    border: "1px solid #1890ff",
                  }}
                  twoToneColor="#1890ff"
                />
                <Popconfirm
                  title={t("warning_delete")}
                  onConfirm={() => handleDeleteEmployee(drawerData.id)}
                  okText={t("yes")}
                  cancelText={t("no")}
                  key="delete"
                >
                  <DeleteTwoTone
                    style={{
                      padding: 6,
                      fontSize: "18px",
                      border: "1px solid #ff0000",
                    }}
                    twoToneColor="#ff0000"
                    title={t("delete_entity")}
                  />
                </Popconfirm>
              </Space>
            )
          )
        }
        extra={
          <Button
            danger
            type="link"
            style={{ padding: 0 }}
            onClick={handleCancel}
          >
            <CloseOutlined />
          </Button>
        }
      >
        {editEmployeeDrawer || addEmployeeDrawer ? (
          <Form
            name="add-employee-form"
            layout="vertical"
            onFinish={handleAddEditEmployee}
            style={{ width: "100%", margin: "0" }}
            form={employeeForm}
          >
            <Row gutter={[20, 20]}>
              <Col
                className="gutter-row"
                xs={24}
                sm={12}
                md={8}
                lg={8}
                xl={8}
                xxl={8}
              >
                <Form.Item
                  name="first_name"
                  label={t("first_name")}
                  rules={[
                    {
                      required: true,
                      message: t("validate_name_required"),
                      whitespace: true,
                    },
                    {
                      min: 3,
                      message: t("validate_name_min_characters"),
                    },
                  ]}
                  hasFeedback
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col
                className="gutter-row"
                xs={24}
                sm={12}
                md={8}
                lg={8}
                xl={8}
                xxl={8}
              >
                <Form.Item
                  name="last_name"
                  label={t("last_name")}
                  rules={[
                    {
                      required: true,
                      message: t("validate_name_required"),
                      whitespace: true,
                    },
                    {
                      min: 3,
                      message: t("validate_name_min_characters"),
                    },
                  ]}
                  hasFeedback
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col
                className="gutter-row"
                xs={24}
                sm={12}
                md={8}
                lg={8}
                xl={8}
                xxl={8}
              >
                {" "}
                <Form.Item
                  name="phone"
                  label={t("phone_number") + " eg: +966 5xxxxxxx"}
                  rules={[
                    {
                      required: true,
                      message: t("validate_phone_required"),
                    },
                    {
                      min: 12,
                      message: t("validate_phone_exact_limit"),
                    },
                    {
                      max: 12,
                      message: t("validate_phone_exact_limit"),
                    },
                  ]}
                >
                  <PhoneInput
                    localization={["sa"]}
                    masks={{ sa: "(...) ..-..-.." }}
                    placeholder="+966 (123) 45-67-89"
                    country={"sa"}
                    onlyCountries={["sa"]}
                    style={{
                      width: "100%",
                      direction: "ltr",
                    }}
                  />
                </Form.Item>
              </Col>
              <Col
                className="gutter-row"
                xs={24}
                sm={12}
                md={8}
                lg={8}
                xl={8}
                xxl={8}
              >
                <Form.Item
                  name="branch_id"
                  label={t("branch")}
                  rules={[
                    {
                      required: true,
                      message: t("validate_branch_required"),
                    },
                  ]}
                  hasFeedback
                >
                  <Select
                    options={branches.map((type) => {
                      return {
                        label: type.name,
                        value: type.id,
                      };
                    })}
                  ></Select>
                </Form.Item>
              </Col>
              <Col
                className="gutter-row"
                xs={24}
                sm={12}
                md={8}
                lg={8}
                xl={8}
                xxl={8}
              >
                <Form.Item
                  name="password"
                  label={t("password")}
                  rules={
                    validatePassword && [
                      {
                        required: true,
                        message: t("validate_password_required"),
                      },
                      {
                        min: 6,
                        message: t("validate_password_min_characters"),
                      },
                    ]
                  }
                  hasFeedback
                >
                  <Input.Password onChange={passwordValueChanged} />
                </Form.Item>
              </Col>
              <Col
                className="gutter-row"
                xs={24}
                sm={12}
                md={8}
                lg={8}
                xl={8}
                xxl={8}
              >
                <Form.Item
                  name="password_confirmation"
                  label={t("confirm_password")}
                  dependencies={["password"]}
                  hasFeedback
                  rules={
                    validatePassword && [
                      {
                        required: true,
                        message: t("validate_password_confirm_not_matched"),
                      },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (!value || getFieldValue("password") === value) {
                            return Promise.resolve();
                          }

                          return Promise.reject(
                            new Error(
                              t("validate_password_confirm_not_matched")
                            )
                          );
                        },
                      }),
                    ]
                  }
                >
                  <Input.Password />
                </Form.Item>
              </Col>
              <Col
                className="gutter-row"
                xs={24}
                sm={12}
                md={8}
                lg={8}
                xl={8}
                xxl={8}
              >
                <Form.Item
                  name="allow_editable_price"
                  label={t("user_can_edit_price_on_pos")}
                >
                  <Radio.Group buttonStyle="solid" key="allow-editable-price">
                    <Radio.Button
                      key="allow-editable-price-0"
                      value={false}
                      className="button_no"
                    >
                      {t("no")}
                    </Radio.Button>
                    <Radio.Button
                      key="allow-editable-price-1"
                      value={true}
                      className="button_yes"
                    >
                      {t("yes")}
                    </Radio.Button>
                  </Radio.Group>
                </Form.Item>
              </Col>
              <Col
                className="gutter-row"
                xs={24}
                sm={12}
                md={8}
                lg={8}
                xl={8}
                xxl={8}
              >
                <Form.Item
                  name="allow_discount"
                  label={t("user_can_apply_discount")}
                >
                  <Radio.Group buttonStyle="solid" key="allow-discount">
                    <Radio.Button
                      key="allow-discount-0"
                      value={false}
                      className="button_no"
                    >
                      {t("no")}
                    </Radio.Button>
                    <Radio.Button
                      key="allow-discount-1"
                      value={true}
                      className="button_yes"
                    >
                      {t("yes")}
                    </Radio.Button>
                  </Radio.Group>
                </Form.Item>
              </Col>
              <Col
                className="gutter-row"
                xs={24}
                sm={12}
                md={8}
                lg={8}
                xl={8}
                xxl={8}
              >
                <Form.Item
                  name="can_see_transactions"
                  label={t("user_see_transactions")}
                >
                  <Radio.Group buttonStyle="solid" key="see-transactions">
                    <Radio.Button
                      key="see-transactions-0"
                      value={false}
                      className="button_no"
                    >
                      {t("no")}
                    </Radio.Button>
                    <Radio.Button
                      key="see-transactions-1"
                      value={true}
                      className="button_yes"
                    >
                      {t("yes")}
                    </Radio.Button>
                  </Radio.Group>
                </Form.Item>
              </Col>
              <Col
                className="gutter-row"
                xs={24}
                sm={12}
                md={8}
                lg={8}
                xl={8}
                xxl={8}
              >
                <Form.Item
                  name="can_refund_transaction"
                  label={t("user_can_refund_transaction")}
                >
                  <Radio.Group buttonStyle="solid" key="allow-refund">
                    <Radio.Button
                      key="allow-refund-0"
                      value={false}
                      className="button_no"
                    >
                      {t("no")}
                    </Radio.Button>
                    <Radio.Button
                      key="allow-refund-1"
                      value={true}
                      className="button_yes"
                    >
                      {t("yes")}
                    </Radio.Button>
                  </Radio.Group>
                </Form.Item>
              </Col>
              <Col
                className="gutter-row"
                xs={24}
                sm={12}
                md={8}
                lg={8}
                xl={8}
                xxl={8}
              >
                <Form.Item
                  name="can_add_edit_product"
                  label={t("user_can_add_edit_products")}
                >
                  <Radio.Group buttonStyle="solid" key="allow-add-product">
                    <Radio.Button
                      key="allow-add-product-0"
                      value={false}
                      className="button_no"
                    >
                      {t("no")}
                    </Radio.Button>
                    <Radio.Button
                      key="allow-add-product-1"
                      value={true}
                      className="button_yes"
                    >
                      {t("yes")}
                    </Radio.Button>
                  </Radio.Group>
                </Form.Item>
              </Col>
              {(is_waiter_management_active || is_job_management_active) && (
                <Col
                  className="gutter-row"
                  xs={24}
                  sm={12}
                  md={8}
                  lg={8}
                  xl={8}
                  xxl={8}
                >
                  <Form.Item
                    name="is_waiter"
                    label={
                      is_waiter_management_active
                        ? t("is_waiter")
                        : t("is_job_person")
                    }
                  >
                    <Radio.Group buttonStyle="solid" key="is_waiter">
                      <Radio.Button
                        key="is_waiter-0"
                        value={false}
                        className="button_no"
                      >
                        {t("no")}
                      </Radio.Button>
                      <Radio.Button
                        key="is_waiter-1"
                        value={true}
                        className="button_yes"
                      >
                        {t("yes")}
                      </Radio.Button>
                    </Radio.Group>
                  </Form.Item>
                </Col>
              )}
              {is_stock_management_active && (
                <>
                  <Col
                    className="gutter-row"
                    xs={24}
                    sm={12}
                    md={8}
                    lg={8}
                    xl={8}
                    xxl={8}
                  >
                    <Form.Item
                      name="can_request_stock_transfer"
                      label={t("user_can_request_stock_transfer")}
                    >
                      <Radio.Group
                        buttonStyle="solid"
                        key="user-can-request-stock-transfer"
                      >
                        <Radio.Button
                          key="user-can-request-stock-transfer-0"
                          value={false}
                          className="button_no"
                        >
                          {t("no")}
                        </Radio.Button>
                        <Radio.Button
                          key="user-can-request-stock-transfer-1"
                          value={true}
                          className="button_yes"
                        >
                          {t("yes")}
                        </Radio.Button>
                      </Radio.Group>
                    </Form.Item>
                  </Col>
                  <Col
                    className="gutter-row"
                    xs={24}
                    sm={12}
                    md={8}
                    lg={8}
                    xl={8}
                    xxl={8}
                  >
                    <Form.Item
                      name="can_approve_stock_transfer"
                      label={t("user_can_approve_stock_transfer")}
                    >
                      <Radio.Group
                        buttonStyle="solid"
                        key="user-can-approve-stock-transfer"
                      >
                        <Radio.Button
                          key="user-can-approve-stock-transfer-0"
                          value={false}
                          className="button_no"
                        >
                          {t("no")}
                        </Radio.Button>
                        <Radio.Button
                          key="user-can-approve-stock-transfer-1"
                          value={true}
                          className="button_yes"
                        >
                          {t("yes")}
                        </Radio.Button>
                      </Radio.Group>
                    </Form.Item>
                  </Col>
                  <Col
                    className="gutter-row"
                    xs={24}
                    sm={12}
                    md={8}
                    lg={8}
                    xl={8}
                    xxl={8}
                  >
                    <Form.Item
                      name="can_request_stock_adjustment"
                      label={t("user_can_allow_stock_adjustment")}
                    >
                      <Radio.Group
                        buttonStyle="solid"
                        key="user-can-request-stock-adjustment"
                      >
                        <Radio.Button
                          key="user-can-request-stock-adjustment-0"
                          value={false}
                          className="button_no"
                        >
                          {t("no")}
                        </Radio.Button>
                        <Radio.Button
                          key="user-can-request-stock-adjustment-1"
                          value={true}
                          className="button_yes"
                        >
                          {t("yes")}
                        </Radio.Button>
                      </Radio.Group>
                    </Form.Item>
                  </Col>
                </>
              )}
              {is_customer_management_active && (
                <>
                  <Col
                    className="gutter-row"
                    xs={24}
                    sm={12}
                    md={8}
                    lg={8}
                    xl={8}
                    xxl={8}
                  >
                    <Form.Item
                      name="can_add_edit_customer"
                      label={t("user_can_add_edit_customers")}
                    >
                      <Radio.Group
                        buttonStyle="solid"
                        key="user-can-add-edit-customer"
                        onChange={(e) =>
                          e.target.value
                            ? employeeForm.setFieldsValue({
                                can_view_customer: e.target.value,
                              })
                            : ""
                        }
                      >
                        <Radio.Button
                          key="user-can-add-edit-customer-0"
                          value={false}
                          className="button_no"
                        >
                          {t("no")}
                        </Radio.Button>
                        <Radio.Button
                          key="user-can-add-edit-customer-1"
                          value={true}
                          className="button_yes"
                        >
                          {t("yes")}
                        </Radio.Button>
                      </Radio.Group>
                    </Form.Item>
                  </Col>
                  <Col
                    className="gutter-row"
                    xs={24}
                    sm={12}
                    md={8}
                    lg={8}
                    xl={8}
                    xxl={8}
                  >
                    <Form.Item
                      name="can_view_customer"
                      label={t("user_can_view_customers")}
                    >
                      <Radio.Group
                        buttonStyle="solid"
                        key="user-can-view-customer"
                      >
                        <Radio.Button
                          key="user-can-view-customer-0"
                          value={false}
                          className="button_no"
                        >
                          {t("no")}
                        </Radio.Button>
                        <Radio.Button
                          key="user-can-view-customer-1"
                          value={true}
                          className="button_yes"
                        >
                          {t("yes")}
                        </Radio.Button>
                      </Radio.Group>
                    </Form.Item>
                  </Col>
                </>
              )}
              {is_sales_invoice_active && (
                <>
                  <Col
                    className="gutter-row"
                    xs={24}
                    sm={12}
                    md={8}
                    lg={8}
                    xl={8}
                    xxl={8}
                  >
                    <Form.Item
                      name="can_add_pay_sales_invoice"
                      label={t("user_can_add_pay_sales_invoices")}
                    >
                      <Radio.Group
                        buttonStyle="solid"
                        key="user-can-add-pay-sales-invoices"
                        onChange={(e) =>
                          e.target.value
                            ? employeeForm.setFieldsValue({
                                can_view_sales_invoice: e.target.value,
                              })
                            : ""
                        }
                      >
                        <Radio.Button
                          key="user-can-add-pay-sales-invoices-0"
                          value={false}
                          className="button_no"
                        >
                          {t("no")}
                        </Radio.Button>
                        <Radio.Button
                          key="user-can-add-pay-sales-invoices-1"
                          value={true}
                          className="button_yes"
                        >
                          {t("yes")}
                        </Radio.Button>
                      </Radio.Group>
                    </Form.Item>
                  </Col>
                  <Col
                    className="gutter-row"
                    xs={24}
                    sm={12}
                    md={8}
                    lg={8}
                    xl={8}
                    xxl={8}
                  >
                    <Form.Item
                      name="can_view_sales_invoice"
                      label={t("user_can_view_sales_invoices")}
                    >
                      <Radio.Group
                        buttonStyle="solid"
                        key="user-can-view-customer"
                      >
                        <Radio.Button
                          key="user-can-view-customer-0"
                          value={false}
                          className="button_no"
                        >
                          {t("no")}
                        </Radio.Button>
                        <Radio.Button
                          key="user-can-view-customer-1"
                          value={true}
                          className="button_yes"
                        >
                          {t("yes")}
                        </Radio.Button>
                      </Radio.Group>
                    </Form.Item>
                  </Col>
                </>
              )}
              {activeSubscription &&
                activeSubscription.is_trial === 1 &&
                activeSubscription.type === PLAN_TYPE_PRO &&
                activeSubscription.validity_days > 90 && (
                  <Col
                    className="gutter-row"
                    xs={24}
                    sm={12}
                    md={8}
                    lg={8}
                    xl={8}
                    xxl={12}
                  >
                    <Form.Item
                      name="is_machine_user"
                      label={t("is_machine_user")}
                    >
                      <Radio.Group buttonStyle="solid" key="is-machine-user">
                        <Radio.Button
                          key="is-machine-user-0"
                          value={false}
                          className="button_no"
                        >
                          {t("no")}
                        </Radio.Button>
                        <Radio.Button
                          key="is-machine-user-1"
                          value={true}
                          className="button_yes"
                        >
                          {t("yes")}
                        </Radio.Button>
                      </Radio.Group>
                    </Form.Item>
                  </Col>
                )}
              <Col
                className="gutter-row"
                xs={24}
                sm={24}
                md={24}
                lg={24}
                xl={24}
                xxl={24}
              >
                <Form.Item name="id" style={{ display: "none" }}>
                  <input type="hidden" />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        ) : (
          <Row gutter={[20, 20]}>
            {drawerData.first_name && (
              <Col
                className="gutter-row"
                xs={24}
                sm={12}
                md={8}
                lg={8}
                xl={8}
                xxl={8}
              >
                <div
                  style={{
                    fontWeight: "bold",
                    paddingBottom: "10px",
                  }}
                >
                  {t("first_name")}
                </div>
                <div style={{ border: "1px solid #d9d9d9", padding: "10px" }}>
                  {drawerData.first_name}
                </div>
              </Col>
            )}
            {drawerData.last_name && (
              <Col
                className="gutter-row"
                xs={24}
                sm={12}
                md={8}
                lg={8}
                xl={8}
                xxl={8}
              >
                <div
                  style={{
                    fontWeight: "bold",
                    paddingBottom: "10px",
                  }}
                >
                  {t("last_name")}
                </div>
                <div style={{ border: "1px solid #d9d9d9", padding: "10px" }}>
                  {drawerData.last_name}
                </div>
              </Col>
            )}
            {drawerData.phone && (
              <Col
                className="gutter-row"
                xs={24}
                sm={12}
                md={8}
                lg={8}
                xl={8}
                xxl={8}
              >
                <div
                  style={{
                    fontWeight: "bold",
                    paddingBottom: "10px",
                  }}
                >
                  {t("phone_number")}
                </div>
                <div style={{ border: "1px solid #d9d9d9", padding: "10px" }}>
                  {drawerData.phone}
                </div>
              </Col>
            )}
            {drawerData.branch_name && (
              <Col
                className="gutter-row"
                xs={24}
                sm={12}
                md={8}
                lg={8}
                xl={8}
                xxl={8}
              >
                <div
                  style={{
                    fontWeight: "bold",
                    paddingBottom: "10px",
                  }}
                >
                  {t("branch")}
                </div>
                <div style={{ border: "1px solid #d9d9d9", padding: "10px" }}>
                  {drawerData.branch_name}
                </div>
              </Col>
            )}
            {drawerData.type && (
              <Col
                className="gutter-row"
                xs={24}
                sm={12}
                md={8}
                lg={8}
                xl={8}
                xxl={8}
              >
                <div
                  style={{
                    fontWeight: "bold",
                    paddingBottom: "10px",
                  }}
                >
                  {t("col_type")}
                </div>
                <div style={{ border: "1px solid #d9d9d9", padding: "9px" }}>
                  {drawerData.type === USER_TYPE_BUSINESS_OWNER ? (
                    <Tag color="green">{t("owner")}</Tag>
                  ) : (
                    <Tag color="blue">{t("employee")}</Tag>
                  )}
                  {(is_waiter_management_active || is_job_management_active) &&
                    drawerData.is_waiter && (
                      <Tag color="orange">
                        {is_waiter_management_active
                          ? t("waiter")
                          : t("job_person")}
                      </Tag>
                    )}
                </div>
              </Col>
            )}
            <Col
              className="gutter-row"
              xs={24}
              sm={12}
              md={8}
              lg={8}
              xl={8}
              xxl={8}
            >
              <div
                style={{
                  fontWeight: "bold",
                  paddingBottom: "10px",
                }}
              >
                {t("col_status")}
              </div>
              <div style={{ border: "1px solid #d9d9d9", padding: "9px" }}>
                {drawerData.is_active ? (
                  <Tag color="green">{t("active")}</Tag>
                ) : (
                  <Tag color="red">{t("inactive")}</Tag>
                )}
              </div>
            </Col>
            <Col
              className="gutter-row"
              xs={24}
              sm={12}
              md={8}
              lg={8}
              xl={8}
              xxl={8}
            >
              <div
                style={{
                  fontWeight: "bold",
                  paddingBottom: "10px",
                }}
              >
                {t("is_machine_user")}
              </div>
              <div style={{ border: "1px solid #d9d9d9", padding: "9px" }}>
                {drawerData.is_machine_user ? (
                  <Tag color="green">{t("yes")}</Tag>
                ) : (
                  <Tag color="red">{t("no")}</Tag>
                )}
              </div>
            </Col>
            <Col
              className="gutter-row"
              xs={24}
              sm={12}
              md={8}
              lg={8}
              xl={8}
              xxl={8}
            >
              <div
                style={{
                  fontWeight: "bold",
                  paddingBottom: "10px",
                }}
              >
                {t("user_can_edit_price_on_pos")}
              </div>
              <div style={{ border: "1px solid #d9d9d9", padding: "9px" }}>
                {drawerData.allow_editable_price ? (
                  <Tag color="green">{t("yes")}</Tag>
                ) : (
                  <Tag color="red">{t("no")}</Tag>
                )}
              </div>
            </Col>
            <Col
              className="gutter-row"
              xs={24}
              sm={12}
              md={8}
              lg={8}
              xl={8}
              xxl={8}
            >
              <div
                style={{
                  fontWeight: "bold",
                  paddingBottom: "10px",
                }}
              >
                {t("user_can_apply_discount")}
              </div>
              <div style={{ border: "1px solid #d9d9d9", padding: "9px" }}>
                {drawerData.allow_discount ? (
                  <Tag color="green">{t("yes")}</Tag>
                ) : (
                  <Tag color="red">{t("no")}</Tag>
                )}
              </div>
            </Col>
            <Col
              className="gutter-row"
              xs={24}
              sm={12}
              md={8}
              lg={8}
              xl={8}
              xxl={8}
            >
              <div
                style={{
                  fontWeight: "bold",
                  paddingBottom: "10px",
                }}
              >
                {t("user_see_transactions")}
              </div>
              <div style={{ border: "1px solid #d9d9d9", padding: "9px" }}>
                {drawerData.can_see_transactions ? (
                  <Tag color="green">{t("yes")}</Tag>
                ) : (
                  <Tag color="red">{t("no")}</Tag>
                )}
              </div>
            </Col>
            <Col
              className="gutter-row"
              xs={24}
              sm={12}
              md={8}
              lg={8}
              xl={8}
              xxl={8}
            >
              <div
                style={{
                  fontWeight: "bold",
                  paddingBottom: "10px",
                }}
              >
                {t("user_can_refund_transaction")}
              </div>
              <div style={{ border: "1px solid #d9d9d9", padding: "9px" }}>
                {drawerData.can_refund_transaction ? (
                  <Tag color="green">{t("yes")}</Tag>
                ) : (
                  <Tag color="red">{t("no")}</Tag>
                )}
              </div>
            </Col>
            <Col
              className="gutter-row"
              xs={24}
              sm={12}
              md={8}
              lg={8}
              xl={8}
              xxl={8}
            >
              <div
                style={{
                  fontWeight: "bold",
                  paddingBottom: "10px",
                }}
              >
                {t("user_can_add_edit_products")}
              </div>
              <div style={{ border: "1px solid #d9d9d9", padding: "9px" }}>
                {drawerData.can_add_edit_product ? (
                  <Tag color="green">{t("yes")}</Tag>
                ) : (
                  <Tag color="red">{t("no")}</Tag>
                )}
              </div>
            </Col>
            {is_stock_management_active && (
              <>
                <Col
                  className="gutter-row"
                  xs={24}
                  sm={12}
                  md={8}
                  lg={8}
                  xl={8}
                  xxl={8}
                >
                  <div
                    style={{
                      fontWeight: "bold",
                      paddingBottom: "10px",
                    }}
                  >
                    {t("user_can_approve_stock_transfer")}
                  </div>
                  <div style={{ border: "1px solid #d9d9d9", padding: "9px" }}>
                    {drawerData.can_approve_stock_transfer ? (
                      <Tag color="green">{t("yes")}</Tag>
                    ) : (
                      <Tag color="red">{t("no")}</Tag>
                    )}
                  </div>
                </Col>
                <Col
                  className="gutter-row"
                  xs={24}
                  sm={12}
                  md={8}
                  lg={8}
                  xl={8}
                  xxl={8}
                >
                  <div
                    style={{
                      fontWeight: "bold",
                      paddingBottom: "10px",
                    }}
                  >
                    {t("user_can_request_stock_transfer")}
                  </div>
                  <div style={{ border: "1px solid #d9d9d9", padding: "9px" }}>
                    {drawerData.can_request_stock_transfer ? (
                      <Tag color="green">{t("yes")}</Tag>
                    ) : (
                      <Tag color="red">{t("no")}</Tag>
                    )}
                  </div>
                </Col>
                <Col
                  className="gutter-row"
                  xs={24}
                  sm={12}
                  md={8}
                  lg={8}
                  xl={8}
                  xxl={8}
                >
                  <div
                    style={{
                      fontWeight: "bold",
                      paddingBottom: "10px",
                    }}
                  >
                    {t("user_can_allow_stock_adjustment")}
                  </div>
                  <div style={{ border: "1px solid #d9d9d9", padding: "9px" }}>
                    {drawerData.can_request_stock_adjustment ? (
                      <Tag color="green">{t("yes")}</Tag>
                    ) : (
                      <Tag color="red">{t("no")}</Tag>
                    )}
                  </div>
                </Col>
              </>
            )}
            {is_customer_management_active && (
              <>
                <Col
                  className="gutter-row"
                  xs={24}
                  sm={12}
                  md={8}
                  lg={8}
                  xl={8}
                  xxl={8}
                >
                  <div
                    style={{
                      fontWeight: "bold",
                      paddingBottom: "10px",
                    }}
                  >
                    {t("user_can_add_edit_customers")}
                  </div>
                  <div style={{ border: "1px solid #d9d9d9", padding: "9px" }}>
                    {drawerData.can_add_edit_customer ? (
                      <Tag color="green">{t("yes")}</Tag>
                    ) : (
                      <Tag color="red">{t("no")}</Tag>
                    )}
                  </div>
                </Col>
                <Col
                  className="gutter-row"
                  xs={24}
                  sm={12}
                  md={8}
                  lg={8}
                  xl={8}
                  xxl={8}
                >
                  <div
                    style={{
                      fontWeight: "bold",
                      paddingBottom: "10px",
                    }}
                  >
                    {t("user_can_view_customers")}
                  </div>
                  <div style={{ border: "1px solid #d9d9d9", padding: "9px" }}>
                    {drawerData.can_view_customer ? (
                      <Tag color="green">{t("yes")}</Tag>
                    ) : (
                      <Tag color="red">{t("no")}</Tag>
                    )}
                  </div>
                </Col>
              </>
            )}
            {is_sales_invoice_active && (
              <>
                <Col
                  className="gutter-row"
                  xs={24}
                  sm={12}
                  md={8}
                  lg={8}
                  xl={8}
                  xxl={8}
                >
                  <div
                    style={{
                      fontWeight: "bold",
                      paddingBottom: "10px",
                    }}
                  >
                    {t("user_can_add_pay_sales_invoices")}
                  </div>
                  <div style={{ border: "1px solid #d9d9d9", padding: "9px" }}>
                    {drawerData.can_add_pay_sales_invoice ? (
                      <Tag color="green">{t("yes")}</Tag>
                    ) : (
                      <Tag color="red">{t("no")}</Tag>
                    )}
                  </div>
                </Col>
                <Col
                  className="gutter-row"
                  xs={24}
                  sm={12}
                  md={8}
                  lg={8}
                  xl={8}
                  xxl={8}
                >
                  <div
                    style={{
                      fontWeight: "bold",
                      paddingBottom: "10px",
                    }}
                  >
                    {t("user_can_view_sales_invoices")}
                  </div>
                  <div style={{ border: "1px solid #d9d9d9", padding: "9px" }}>
                    {drawerData.can_view_sales_invoice ? (
                      <Tag color="green">{t("yes")}</Tag>
                    ) : (
                      <Tag color="red">{t("no")}</Tag>
                    )}
                  </div>
                </Col>
              </>
            )}
          </Row>
        )}
      </Drawer>
      {/* user add License Modal */}
      {activeSubscription && (
        <Modal
          open={addLicenseModalVisible}
          title={t("add_license")}
          onOk={handleAddLicense}
          onCancel={() => setAddLicenseModalVisible(false)}
          footer={[
            <Button
              key="back"
              loading={loading}
              onClick={() => setAddLicenseModalVisible(false)}
            >
              {t("cancel")}
            </Button>,
            <Button
              key="submit"
              type="primary"
              loading={loading}
              onClick={handleAddLicense}
            >
              {t("add_license")}
            </Button>,
          ]}
        >
          <Form
            name="add-license-form"
            layout="vertical"
            onFinish={handleAddLicense}
            style={{ width: "100%", margin: "0" }}
            form={addLicenseForm}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                columnGap: "20px",
                marginTop: "20px",
                marginBottom: "20px",
              }}
              className="users_count"
            >
              <MinusOutlined
                style={{
                  fontSize: "14px",
                  padding: "8px",
                  backgroundColor: "#6301f2",
                  color: "#ffffff",
                }}
                onClick={() => {
                  usersCount > 1 && setUsersCount(usersCount - 1);
                }}
              />
              <div style={{ fontSize: "18px" }}>{usersCount}</div>
              <PlusOutlined
                style={{
                  fontSize: "14px",
                  padding: "8px",
                  backgroundColor: "#6301f2",
                  color: "#ffffff",
                }}
                onClick={() => setUsersCount(usersCount + 1)}
              />
              {t("licenses")}
            </div>
          </Form>
        </Modal>
      )}
      {invoice && (
        <HyperPayPaymentModal
          invoiceId={invoice.id}
          amount={invoice.amount_charged}
          paymentModalVisible={paymentModalVisible}
          setPaymentModalVisible={setPaymentModalVisible}
        />
      )}
    </>
  );
};

export default Employees;
