import "./Home.css";
import { useState, useEffect, useContext, useRef } from "react";
import { Statistic, Col, Row, Card } from "antd";
import { Column, Pie } from "@ant-design/charts";
import {
  CopyOutlined,
  DollarCircleOutlined,
  BulbOutlined,
} from "@ant-design/icons";
import { AppContext } from "../../../context/AppContextProvider";
import { currencyFormatter } from "../../../utils";
import { getHomeDataSummary } from "../../../network/network";
import moment from "moment";
import { useTranslation } from "react-i18next";

const Home = () => {
  const { authUser, appConfig, displayWebView } = useContext(AppContext);
  const [isLoading, setIsLoading] = useState(false);
  const [deviceWidth, setDeviceWidth] = useState("");
  const [dashboardData, setDashboardData] = useState({
    totals: {
      amount: 0,
      tax: 0,
      refunds: 0,
    },
    quarterly_transactions: [],
    transaction_by_types: {},
    last_15_days_sale: [],
  });
  const { t } = useTranslation();
  const windowWidth = useRef(window.innerWidth);

  useEffect(() => {
    let start_date = moment().subtract(14, "d");
    let end_date = moment();

    setIsLoading(true);
    getHomeDataSummary().then((response) => {
      let columnChartDataArray = [];
      while (end_date.diff(start_date, "days") >= 0) {
        columnChartDataArray.push({
          date: start_date.format("Do MMM"),
          sales: response.data.data.last_15_days_sale[
            start_date.format("YYYY-MM-DD")
          ]
            ? response.data.data.last_15_days_sale[
                start_date.format("YYYY-MM-DD")
              ].total_sales
            : 0,
        });

        start_date.add(1, "d");
      }
      response.data.data.last_15_days_sale = columnChartDataArray;
      setDashboardData(response.data.data);
      setIsLoading(false);
    });
  }, []);

  useEffect(() => {
    setDeviceWidth(windowWidth.current);
  }, [windowWidth]);

  useEffect(() => {
    if (authUser.is_onboarding_complete === 1 && displayWebView === "webview") {
      window.location.href = "https://anyposmobileapp.page.link/76UZ";
    }
  }, [authUser, displayWebView]);

  const columnChartData = {
    data: dashboardData.last_15_days_sale,
    xField: "date",
    yField: "sales",
    label: {
      position: "top",
      style: {
        fill: "#000",
        opacity: 0.6,
      },
    },
    xAxis: {
      label: {
        autoHide: true,
        autoRotate: false,
      },
    },
    meta: {
      date: { alias: "Date" },
      sales: {
        formatter: function formatter(v) {
          return currencyFormatter.format(v);
        },
      },
    },
  };

  const pie1ChartData = {
    appendPadding: 10,
    height: 270,
    data: dashboardData.quarterly_transactions.map((transaction) => {
      return {
        type: transaction.month_year,
        value: transaction.total_sales.toFixed(2) * 1,
      };
    }),
    angleField: "value",
    colorField: "type",
    radius: 1,
    innerRadius: 0.6,
    interactions: [{ type: "element-selected" }, { type: "element-active" }],
    meta: {
      value: {
        formatter: function formatter(v) {
          return currencyFormatter.format(v);
        },
      },
    },
    label: {
      text: "value",
      style: {
        textAlign: "center",
        fontSize: 14,
      },
    },
    statistic: {
      title: false,
      content: {
        style: {
          whiteSpace: "pre-wrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        },
        customHtml: () => {
          let sum = 0;
          dashboardData.quarterly_transactions.forEach((transaction) => {
            sum += transaction.total_sales;
          });
          return (
            "<div><span style='font-weight:300;font-size:18px'>SAR</span><br>" +
            new Intl.NumberFormat("en-US").format(sum) +
            "</div>"
          );
        },
      },
    },
    color: ["#056aaf", "#0a2895", "#8e8315"],
  };

  const pie2ChartData = {
    appendPadding: 10,
    height: 270,
    data: [
      {
        type: t("cash"),
        value: dashboardData.transaction_by_types[1]
          ? dashboardData.transaction_by_types[1].count
          : 0,
      },
      // {
      //   type: "Mada",
      //   value: dashboardData.transaction_by_types[2]
      //     ? dashboardData.transaction_by_types[2].count
      //     : 0,
      // },
      // {
      //   type: "STC Pay",
      //   value: dashboardData.transaction_by_types[3]
      //     ? dashboardData.transaction_by_types[3].count
      //     : 0,
      // },
      {
        type: t("credit_card"),
        value: dashboardData.transaction_by_types[4]
          ? dashboardData.transaction_by_types[4].count
          : 0,
      },
      {
        type: t("multi_payment"),
        value: dashboardData.transaction_by_types[5]
          ? dashboardData.transaction_by_types[5].count
          : 0,
      },
    ].filter((item) => item.value !== 0),
    angleField: "value",
    colorField: "type",
    radius: 1,
    innerRadius: 0.6,
    label: {
      text: "value",
      style: {
        textAlign: "center",
        fontSize: 14,
      },
    },
    interactions: [{ type: "element-selected" }, { type: "element-active" }],

    color: ["#5c3c92", "#a2d5c6", "#077b8a", "#e7caf9"],
  };

  const pie3ChartData = {
    appendPadding: 10,
    height: 270,
    data: dashboardData.quarterly_transactions.map((transaction) => {
      return {
        type: transaction.month_year,
        value: transaction.taxes.toFixed(2) * 1,
      };
    }),
    angleField: "value",
    colorField: "type",
    radius: 1,
    innerRadius: 0.6,
    label: {
      text: "value",
      style: {
        textAlign: "center",
        fontSize: 14,
      },
    },
    interactions: [{ type: "element-selected" }, { type: "element-active" }],
    meta: {
      value: {
        formatter: function formatter(v) {
          return currencyFormatter.format(v);
        },
      },
    },
    statistic: {
      title: false,
      content: {
        style: {
          whiteSpace: "pre-wrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        },
        customHtml: () => {
          let sum = 0;
          dashboardData.quarterly_transactions.forEach((transaction) => {
            sum += transaction.taxes;
          });
          return (
            "<div><span style='font-weight:300;font-size:18px'>SAR</span><br>" +
            new Intl.NumberFormat("en-US").format(sum) +
            "</div>"
          );
        },
      },
    },
    color: ["#EEC710", "#0D1239", "#6A7B83"],
  };

  return (
    <>
      <Row style={{ marginBottom: 20 }}>
        <Col span={24}>
          <Card className="fade-in-from-left" loading={isLoading}>
            <h3 style={{ direction: appConfig.direction, margin: 0 }}>
              {t("hi")} {authUser.name + " 👋"} , {t("welcome_anypos")}
            </h3>
          </Card>
        </Col>
      </Row>
      {deviceWidth > 768 && (
        <>
          <Row style={{ marginBottom: 20 }} gutter={20}>
            <Col span={8}>
              <Card className="fade-in-from-left" loading={isLoading}>
                <Statistic
                  title={t("total_sales")}
                  valueStyle={{ color: "#000" }}
                  value={currencyFormatter.format(dashboardData.totals.amount)}
                  prefix={<DollarCircleOutlined />}
                />
              </Card>
            </Col>
            <Col span={8}>
              <Card className="fade-in-from-left" loading={isLoading}>
                <Statistic
                  title={t("total_vat")}
                  value={currencyFormatter.format(dashboardData.totals.tax)}
                  valueStyle={{ color: "#1890ff" }}
                  prefix={<BulbOutlined />}
                />
              </Card>
            </Col>
            <Col span={8}>
              <Card className="fade-in-from-left" loading={isLoading}>
                <Statistic
                  title={t("refunds")}
                  value={currencyFormatter.format(
                    Math.abs(dashboardData.totals.refunds)
                  )}
                  valueStyle={{ color: "#ff1414" }}
                  prefix={<CopyOutlined />}
                />
              </Card>
            </Col>
          </Row>

          <Row style={{ marginBottom: 20 }} gutter={20}>
            <Col span={8}>
              <Card
                title={t("quarterly_sales")}
                className="fade-in-from-left"
                loading={isLoading}
              >
                <Pie {...pie1ChartData} />
              </Card>
            </Col>
            <Col span={8}>
              <Card
                title={t("payment_types")}
                className="fade-in-from-left"
                loading={isLoading}
              >
                <Pie {...pie2ChartData} />
              </Card>
            </Col>
            <Col span={8}>
              <Card
                title={t("quarterly_vat")}
                className="fade-in-from-left"
                loading={isLoading}
              >
                <Pie {...pie3ChartData} />
              </Card>
            </Col>
          </Row>

          <Row>
            <Col span={24}>
              <Card
                title={t("sales_overview")}
                className="fade-in-from-left"
                loading={isLoading}
              >
                <Column {...columnChartData} />
              </Card>
            </Col>
          </Row>
        </>
      )}
    </>
  );
};

export default Home;
