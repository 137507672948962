import React, { useContext, useEffect, useRef, useState } from "react";
import { Button, Card, Col, Modal, Row, Spin, Tag } from "antd";
import { PageHeader } from "@ant-design/pro-layout";
import moment from "moment";
import CustomBreadcrumb from "../../../components/CustomBreadcrumb";
import { useTranslation } from "react-i18next";
import {
  EyeOutlined,
  HomeOutlined,
  ReconciliationOutlined,
} from "@ant-design/icons";
import { useNavigate, useParams } from "react-router-dom";
import {
  getSingleTransaction,
  ChangeTransactionStatus,
} from "../../../network/network";
import {
  ADDON_JOB_MANAGEMENT,
  ADDON_TABLE_MANAGEMENT,
  ADDON_WAITER_MANAGEMENT,
  TRANSACTION_STATUS_CANCELLED,
  TRANSACTION_STATUS_COMPLETED,
  TRANSACTION_STATUS_INPROGRESS,
  TRANSACTION_STATUS_PENDING,
  TRANSACTION_TYPE_CASH,
  TRANSACTION_TYPE_CREDIT,
  TRANSACTION_TYPE_MULTI,
  USER_TYPE_BUSINESS_OWNER,
} from "../../../configs/constants";
import { AppContext } from "../../../context/AppContextProvider";
import CurrencyFormatter from "../../../components/CurrencyFormatter";
import ReactToPrint from "react-to-print";
import NotificationWithIcon from "../../../components/NotificationWithIcon";
import UpdateWaiter from "./UpdateWaiter";
import UpdateTable from "./UpdateTable";
import TransactionSlip from "../../../components/TransactionSlip/TransactionSlip";

const ViewOrder = () => {
  const { authUser, appConfig } = useContext(AppContext);
  const [data, setData] = useState(false);
  const [loading, setLoading] = useState(false);
  const [reload, setReload] = useState(false);
  const [transaction, setTransaction] = useState({});
  const [slipModalVisible, setSlipModalVisible] = useState(false);
  let { orderId } = useParams();
  const { t } = useTranslation();
  const transactionSlipRef = useRef();
  let navigate = useNavigate();

  useEffect(() => {
    setLoading(true);
    getSingleTransaction(orderId)
      .then((response) => {
        setData(response.data.data.transaction);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [orderId, reload]);

  const breadcrumbRoutes = [
    {
      path: "/",
      breadcrumbName: t("menu_home"),
      icon: <HomeOutlined />,
    },
    {
      path: "/orders",
      breadcrumbName: t("menu_orders"),
      icon: <ReconciliationOutlined />,
    },
    {
      path: "view-orders",
      breadcrumbName: t("menu_view_order"),
      icon: <EyeOutlined />,
    },
  ];

  const updateStatus = (id, status) => {
    if (status === TRANSACTION_STATUS_COMPLETED) {
      navigate("/checkout", {
        state: {
          diningTableId: data.dining_table && data.dining_table.id,
          waiterId: data.waiter && data.waiter.id,
          items: data.items,
          orderStatus: TRANSACTION_STATUS_COMPLETED,
          id: data.id,
        },
      });
    } else {
      const formData = new FormData();
      if (status) {
        formData.append("id", id);
      }
      if (status) {
        formData.append("status", status);
      }

      ChangeTransactionStatus(formData)
        .then((response) => {
          let data = response.data || response.response.data;
          if (data.success === true) {
            // Success
            NotificationWithIcon(
              "success",
              t("order_updated"),
              t("order_updated_successfully")
            );
            navigate("/orders");
          } else {
            // Error
            NotificationWithIcon(
              "error",
              t("something_went_wrong"),
              data.message
            );
          }
        })
        .catch((info) => {
          console.log("API Failed:", info.response);
          // Error
          NotificationWithIcon(
            "error",
            t("something_went_wrong"),
            info.response.data.data.errors.name
          );
        });
    }
  };

  const showSlipModal = (transaction) => {
    setTransaction(transaction);
    setSlipModalVisible(true);
  };

  const hideSlipModal = () => {
    setSlipModalVisible(false);
    setTransaction({});
  };

  const getTransactionsType = (type) => {
    if (type === TRANSACTION_TYPE_CASH) {
      return <Tag color="green">{t("cash")}</Tag>;
    } else if (type === TRANSACTION_TYPE_CREDIT) {
      return <Tag color="blue">{t("credit_card")}</Tag>;
    } else if (type === TRANSACTION_TYPE_MULTI) {
      return <Tag color="gold">{t("multi_payment")}</Tag>;
    }
  };

  const is_waiter_management_active = authUser.active_addons.some(
    (addon) => addon.id && addon.name === ADDON_WAITER_MANAGEMENT
  );

  const is_job_management_active = authUser.active_addons.some(
    (addon) => addon.id && addon.name === ADDON_JOB_MANAGEMENT
  );

  const is_table_management_active = authUser.active_addons.some(
    (addon) => addon.id && addon.name === ADDON_TABLE_MANAGEMENT
  );

  return (
    <div style={{ position: "relative" }}>
      <Card style={{ marginBottom: "20px" }}>
        <CustomBreadcrumb menus={breadcrumbRoutes} />
        <PageHeader
          title={t("menu_view_order")}
          onBack={() => window.history.back()}
          ghost={false}
          style={{ padding: "0" }}
        />
      </Card>
      <Spin spinning={loading}>
        <Card>
          {data && (
            <Row gutter={[20, 20]}>
              <Col
                className="gutter-row"
                xs={24}
                sm={24}
                md={12}
                lg={12}
                xl={8}
                xxl={8}
              >
                <div
                  style={{
                    fontWeight: "bold",
                    paddingBottom: "10px",
                  }}
                >
                  {t("col_transaction_id")}
                </div>
                <div style={{ border: "1px solid #d9d9d9", padding: "10px" }}>
                  {data.uid ? data.uid : "-"}
                </div>
              </Col>
              <Col
                className="gutter-row"
                xs={24}
                sm={24}
                md={12}
                lg={12}
                xl={8}
                xxl={8}
              >
                <div
                  style={{
                    fontWeight: "bold",
                    paddingBottom: "10px",
                  }}
                >
                  {t("order_id")}
                </div>
                <div style={{ border: "1px solid #d9d9d9", padding: "10px" }}>
                  {data.order_id ? data.order_id : "-"}
                </div>
              </Col>
              <Col
                className="gutter-row"
                xs={24}
                sm={24}
                md={12}
                lg={12}
                xl={8}
                xxl={8}
              >
                <div
                  style={{
                    fontWeight: "bold",
                    paddingBottom: "10px",
                  }}
                >
                  {t("col_status")}
                </div>
                <div style={{ border: "1px solid #d9d9d9", padding: "10px" }}>
                  {data.status.value === TRANSACTION_STATUS_PENDING && (
                    <Tag color="warning">{data.status.name}</Tag>
                  )}
                  {data.status.value === TRANSACTION_STATUS_INPROGRESS && (
                    <Tag color="blue">{data.status.name}</Tag>
                  )}
                  {data.status.value === TRANSACTION_STATUS_COMPLETED && (
                    <Tag color="green">{data.status.name}</Tag>
                  )}
                  {data.status.value === TRANSACTION_STATUS_CANCELLED && (
                    <Tag color="red">{data.status.name}</Tag>
                  )}
                </div>
              </Col>
              <Col
                className="gutter-row"
                xs={24}
                sm={24}
                md={12}
                lg={12}
                xl={8}
                xxl={8}
              >
                <div
                  style={{
                    fontWeight: "bold",
                    paddingBottom: "10px",
                  }}
                >
                  {t("col_source")}
                </div>
                <div
                  style={{ border: "1px solid #d9d9d9", padding: "5px 10px" }}
                >
                  {data.order_source ? (
                    <>
                      {data.order_source.name === "Pos" && (
                        <img
                          src="/icons/pos.svg"
                          alt="pos code"
                          style={{
                            width: "25px",
                            height: "auto",
                            marginRight: "10px",
                          }}
                        />
                      )}
                      {data.order_source.name === "QrOrder" &&
                        (data.dining_table?.is_drive_thru ? (
                          <img
                            src="/icons/car.svg"
                            alt="qr code"
                            style={{
                              width: "25px",
                              height: "auto",
                              marginRight: "10px",
                            }}
                          />
                        ) : (
                          <img
                            src="/icons/qr.svg"
                            alt="qr code"
                            style={{
                              width: "25px",
                              height: "auto",
                              marginRight: "10px",
                            }}
                          />
                        ))}
                      {data.order_source.name === "Kiosk" && (
                        <img
                          src="/icons/kiosk.svg"
                          alt="kiosk code"
                          style={{
                            width: "25px",
                            height: "auto",
                            marginRight: "10px",
                          }}
                        />
                      )}
                    </>
                  ) : (
                    "-"
                  )}
                </div>
              </Col>
              {data.dining_table && is_table_management_active && (
                <Col
                  className="gutter-row"
                  xs={24}
                  sm={24}
                  md={12}
                  lg={12}
                  xl={8}
                  xxl={8}
                >
                  <div
                    style={{
                      fontWeight: "bold",
                      paddingBottom: "10px",
                    }}
                  >
                    {t("dining_table")}
                  </div>
                  <div style={{ border: "1px solid #d9d9d9", padding: "10px" }}>
                    {data.dining_table?.name}
                  </div>
                </Col>
              )}
              {authUser.type === USER_TYPE_BUSINESS_OWNER && (
                <Col
                  className="gutter-row"
                  xs={24}
                  sm={24}
                  md={12}
                  lg={12}
                  xl={8}
                  xxl={8}
                >
                  <div
                    style={{
                      fontWeight: "bold",
                      paddingBottom: "10px",
                    }}
                  >
                    {t("branch")}
                  </div>
                  <div style={{ border: "1px solid #d9d9d9", padding: "10px" }}>
                    {data.branch ? data.branch : "-"}
                  </div>
                </Col>
              )}
              {data.created_at && (
                <Col
                  className="gutter-row"
                  xs={24}
                  sm={24}
                  md={12}
                  lg={12}
                  xl={8}
                  xxl={8}
                >
                  <div
                    style={{
                      fontWeight: "bold",
                      paddingBottom: "10px",
                    }}
                  >
                    {t("col_created_at")}
                  </div>
                  <div style={{ border: "1px solid #d9d9d9", padding: "10px" }}>
                    {moment(data?.created_at).format("MMM. D, YYYY hh:mm a")}
                  </div>
                </Col>
              )}
              {data.user && (
                <Col
                  className="gutter-row"
                  xs={24}
                  sm={24}
                  md={12}
                  lg={12}
                  xl={8}
                  xxl={8}
                >
                  <div
                    style={{
                      fontWeight: "bold",
                      paddingBottom: "10px",
                    }}
                  >
                    {t("col_created_by")}
                  </div>
                  <div style={{ border: "1px solid #d9d9d9", padding: "10px" }}>
                    {data.user}
                  </div>
                </Col>
              )}
              {data.waiter &&
                (is_waiter_management_active || is_job_management_active) && (
                  <Col
                    className="gutter-row"
                    xs={24}
                    sm={24}
                    md={12}
                    lg={12}
                    xl={8}
                    xxl={8}
                  >
                    <div
                      style={{
                        fontWeight: "bold",
                        paddingBottom: "10px",
                      }}
                    >
                      {is_waiter_management_active
                        ? t("waiter")
                        : t("job_person")}
                    </div>
                    <div
                      style={{ border: "1px solid #d9d9d9", padding: "10px" }}
                    >
                      {data.waiter?.name}
                    </div>
                  </Col>
                )}
              {data.type &&
                data.status.value === TRANSACTION_STATUS_COMPLETED && (
                  <Col
                    className="gutter-row"
                    xs={24}
                    sm={24}
                    md={12}
                    lg={12}
                    xl={8}
                    xxl={8}
                  >
                    <div
                      style={{
                        fontWeight: "bold",
                        paddingBottom: "10px",
                      }}
                    >
                      {t("col_type")}
                    </div>
                    <div
                      style={{ border: "1px solid #d9d9d9", padding: "10px" }}
                    >
                      {getTransactionsType(data.type)}
                    </div>
                  </Col>
                )}
              {data.status.value === TRANSACTION_STATUS_COMPLETED && (
                <Col
                  className="gutter-row"
                  xs={24}
                  sm={24}
                  md={12}
                  lg={12}
                  xl={8}
                  xxl={8}
                >
                  <div
                    style={{
                      fontWeight: "bold",
                      paddingBottom: "10px",
                    }}
                  >
                    {t("col_status")}
                  </div>
                  <div style={{ border: "1px solid #d9d9d9", padding: "10px" }}>
                    {data.reference_transaction ? (
                      <>
                        <Tag color="error">{t("status_refunded")}</Tag>
                        <br />
                        Ref: <strong>{data.reference_transaction.uid}</strong>
                      </>
                    ) : (
                      <Tag color="success">{t("status_paid")}</Tag>
                    )}
                  </div>
                </Col>
              )}
              {data.customer_name && (
                <Col
                  className="gutter-row"
                  xs={24}
                  sm={24}
                  md={12}
                  lg={12}
                  xl={8}
                  xxl={8}
                >
                  <div
                    style={{
                      fontWeight: "bold",
                      paddingBottom: "10px",
                    }}
                  >
                    {t("customer_name")}
                  </div>
                  <div style={{ border: "1px solid #d9d9d9", padding: "10px" }}>
                    {data.customer_name}
                  </div>
                </Col>
              )}
              {data.vehicle_color && (
                <Col
                  className="gutter-row"
                  xs={24}
                  sm={24}
                  md={12}
                  lg={12}
                  xl={8}
                  xxl={8}
                >
                  <div
                    style={{
                      fontWeight: "bold",
                      paddingBottom: "10px",
                    }}
                  >
                    {t("vehicle_color")}
                  </div>
                  <div style={{ border: "1px solid #d9d9d9", padding: "10px" }}>
                    {data.vehicle_color}
                  </div>
                </Col>
              )}
              {data.vehicle_number && (
                <Col
                  className="gutter-row"
                  xs={24}
                  sm={24}
                  md={12}
                  lg={12}
                  xl={8}
                  xxl={8}
                >
                  <div
                    style={{
                      fontWeight: "bold",
                      paddingBottom: "10px",
                    }}
                  >
                    {t("vehicle_number")}
                  </div>
                  <div style={{ border: "1px solid #d9d9d9", padding: "10px" }}>
                    {data.vehicle_number}
                  </div>
                </Col>
              )}
              <Col
                className="gutter-row"
                xs={24}
                sm={24}
                md={24}
                lg={24}
                xl={24}
                xxl={24}
              >
                <div style={{ border: "1px solid #d9d9d9", padding: "10px" }}>
                  {data.items && (
                    <>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          borderBottom: "1px dashed #adadad",
                          padding: "10px 0",
                        }}
                      >
                        <span
                          style={{
                            fontWeight: "bold",
                          }}
                        >
                          {appConfig.direction === "ltr" ? "Items" : "الصنف"}
                        </span>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            fontWeight: "bold",
                          }}
                        >
                          <span style={{ width: "100px", textAlign: "center" }}>
                            {appConfig.direction === "ltr"
                              ? "Unit Price"
                              : "سعر الوحدة"}
                          </span>
                          <span
                            style={{
                              width: "250px",
                              textAlign:
                                appConfig.direction === "ltr"
                                  ? "right"
                                  : "left",
                            }}
                          >
                            {appConfig.direction === "ltr"
                              ? "Price including tax"
                              : "السعر مع الضريبة"}
                          </span>
                        </div>
                      </div>
                      <div
                        style={{
                          paddingBottom: "10px",
                          borderBottom: "1px solid #adadad",
                        }}
                      >
                        {data.items?.map((item) => (
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                              paddingTop: "10px",
                            }}
                            key={"product_items_" + item.id}
                          >
                            <span>
                              {appConfig.direction === "ltr" ? (
                                <>
                                  {item.quantity} x <b>{item.name_en}</b>
                                </>
                              ) : (
                                <>
                                  {item.quantity} x <b>{item.name}</b>
                                </>
                              )}
                            </span>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                              }}
                            >
                              <span
                                style={{ width: "100px", textAlign: "center" }}
                              >
                                <CurrencyFormatter
                                  value={item.price}
                                  currencyCode={false}
                                />
                              </span>
                              <span
                                style={{
                                  width: "250px",
                                  textAlign:
                                    appConfig.direction === "ltr"
                                      ? "right"
                                      : "left",
                                }}
                              >
                                <CurrencyFormatter
                                  value={item.subtotal}
                                  currencyCode={false}
                                />
                              </span>
                            </div>
                          </div>
                        ))}
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          paddingTop: "10px",
                        }}
                      >
                        {appConfig.direction === "ltr"
                          ? "Total without tax"
                          : "الإجمالي بدون الضريبة"}
                        <span>
                          <CurrencyFormatter
                            value={data.amount_charged - data.tax}
                          />
                        </span>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          padding: "10px 0",
                          borderBottom: "1px solid #adadad",
                        }}
                      >
                        {appConfig.direction === "ltr"
                          ? "Total VAT"
                          : "مجموع ضریبة القیمة المضافة"}
                        <span>
                          <CurrencyFormatter value={data.tax} />
                        </span>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          padding: "10px 0",
                          borderBottom: "1px solid #adadad",
                        }}
                      >
                        {appConfig.direction === "ltr"
                          ? authUser.is_vat_exempt
                            ? "Total"
                            : "Total with tax"
                          : authUser.is_vat_exempt
                          ? "الإجمالي"
                          : "الإجمالي مع الضريبة"}

                        <b>
                          <CurrencyFormatter value={data.amount_charged} />
                        </b>
                      </div>
                    </>
                  )}
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      flexWrap: "wrap",
                      alignItems: "center",
                      padding: " 10px 0",
                    }}
                  >
                    {/* store owner or creater or assign user and transaction not cancelled and */}
                    {(authUser.type === USER_TYPE_BUSINESS_OWNER ||
                      authUser.name === data.user ||
                      authUser.name === data.waiter?.name) &&
                      data.status.value !== TRANSACTION_STATUS_CANCELLED &&
                      data.status.value !== TRANSACTION_STATUS_COMPLETED && (
                        <>
                          {data.status.value === TRANSACTION_STATUS_PENDING && (
                            <Button
                              className="btn-success"
                              onClick={() =>
                                updateStatus(
                                  data.id,
                                  TRANSACTION_STATUS_INPROGRESS
                                )
                              }
                            >
                              {t("mark_as_in_progress")}
                            </Button>
                          )}
                          {data.status.value ===
                            TRANSACTION_STATUS_INPROGRESS && (
                            <Button
                              className="btn-success"
                              onClick={() =>
                                updateStatus(
                                  data.id,
                                  TRANSACTION_STATUS_COMPLETED
                                )
                              }
                            >
                              {t("mark_as_completed")}
                            </Button>
                          )}
                          <Button
                            type="primary"
                            danger
                            onClick={() =>
                              updateStatus(
                                data.id,
                                TRANSACTION_STATUS_CANCELLED
                              )
                            }
                          >
                            {t("cancel_order")}
                          </Button>
                          {is_table_management_active && (
                            <UpdateTable
                              order={data}
                              reloadCallback={() => setReload(!reload)}
                            />
                          )}
                          {(is_waiter_management_active ||
                            is_job_management_active) &&
                            !authUser.is_waiter && (
                              <UpdateWaiter
                                order={data}
                                reloadCallback={() => setReload(!reload)}
                              />
                            )}
                          <Button
                            type="primary"
                            onClick={() => {
                              navigate("/orders/" + data.id + "/edit", {
                                state: data,
                              });
                            }}
                          >
                            {t("menu_edit_order")}
                          </Button>
                        </>
                      )}

                    {data.status.value === TRANSACTION_STATUS_COMPLETED ? (
                      <Button
                        type="primary"
                        onClick={() => showSlipModal(data)}
                      >
                        {t("preview")}
                      </Button>
                    ) : (
                      <ReactToPrint
                        trigger={() => (
                          <Button key="print" type="primary">
                            {t("print")}
                          </Button>
                        )}
                        content={() => transactionSlipRef.current}
                      />
                    )}
                  </div>
                </div>
              </Col>
            </Row>
          )}
        </Card>
        {/* invoice printing code */}
        {data && (
          <div
            ref={transactionSlipRef}
            direction={appConfig.direction}
            style={{
              padding: "10px 20px",
              width: "100%",
              position: "absolute",
              top: "0",
              zIndex: "-9999",
            }}
          >
            {data.order_id && (
              <div
                style={{
                  paddingBottom: "10px",
                  paddingTop: "10px",
                  borderBottom: "1px solid #adadad",
                }}
              >
                {t("order_id")}: <b>{data.order_id}</b>
              </div>
            )}
            {data.order_source &&
              (data.dining_table?.is_drive_thru ? (
                <div
                  style={{
                    paddingBottom: "10px",
                    paddingTop: "10px",
                    borderBottom: "1px solid #adadad",
                  }}
                >
                  {t("col_source")}: <b>{t("is_drive_thru")}</b>
                </div>
              ) : (
                <div
                  style={{
                    paddingBottom: "10px",
                    paddingTop: "10px",
                    borderBottom: "1px solid #adadad",
                  }}
                >
                  {t("col_source")}: <b>{data.order_source.name}</b>
                </div>
              ))}
            {data.dining_table && is_table_management_active && (
              <div
                style={{
                  paddingBottom: "10px",
                  paddingTop: "10px",
                  borderBottom: "1px solid #adadad",
                }}
              >
                {t("dining_table")}: <b>{data.dining_table?.name}</b>
              </div>
            )}
            {authUser.type === USER_TYPE_BUSINESS_OWNER && (
              <div
                style={{
                  paddingBottom: "10px",
                  paddingTop: "10px",
                  borderBottom: "1px solid #adadad",
                }}
              >
                {t("branch")}: <b>{data.branch}</b>
              </div>
            )}
            {data.waiter &&
              (is_waiter_management_active || is_job_management_active) && (
                <div
                  style={{
                    paddingBottom: "10px",
                    paddingTop: "10px",
                    borderBottom: "1px solid #adadad",
                  }}
                >
                  {is_waiter_management_active ? t("waiter") : t("job_person")}:{" "}
                  <b>{data.waiter.name}</b>
                </div>
              )}
            {data.user && (
              <div
                style={{
                  paddingBottom: "10px",
                  paddingTop: "10px",
                  borderBottom: "1px solid #adadad",
                }}
              >
                {t("col_created_by")}: <b>{data.user}</b>
              </div>
            )}
            {data.created_at && (
              <div
                style={{
                  paddingBottom: "10px",
                  paddingTop: "10px",
                  borderBottom: "1px solid #adadad",
                }}
              >
                {t("col_created_at")}:{" "}
                <b>{moment(data.created_at).format("MMM. D, YYYY hh:mm a")}</b>
              </div>
            )}
            {data.status && (
              <div
                style={{
                  paddingBottom: "10px",
                  paddingTop: "10px",
                  borderBottom: "1px solid #adadad",
                }}
              >
                {t("col_status")}: <b>{data.status.name}</b>
              </div>
            )}
            {data.customer_name && (
              <div
                style={{
                  paddingBottom: "10px",
                  paddingTop: "10px",
                  borderBottom: "1px solid #adadad",
                }}
              >
                {t("customer_name")}: <b>{data.customer_name}</b>
              </div>
            )}
            {data.vehicle_color && (
              <div
                style={{
                  paddingBottom: "10px",
                  paddingTop: "10px",
                  borderBottom: "1px solid #adadad",
                }}
              >
                {t("vehicle_color")}: <b>{data.vehicle_color}</b>
              </div>
            )}
            {data.vehicle_number && (
              <div
                style={{
                  paddingBottom: "10px",
                  paddingTop: "10px",
                  borderBottom: "1px solid #adadad",
                }}
              >
                {t("vehicle_number")}: <b>{data.vehicle_number}</b>
              </div>
            )}
            {data.items && (
              <>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    paddingBottom: "10px",
                    paddingTop: "10px",
                    borderBottom: "1px dashed #adadad",
                  }}
                >
                  <span>
                    {appConfig.direction === "ltr" ? "Items" : "الصنف"}
                  </span>
                </div>
                <div
                  style={{
                    paddingBottom: "10px",
                    borderBottom: "1px solid #adadad",
                  }}
                >
                  {data.items?.map((item) => (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        paddingTop: "10px",
                      }}
                      key={"product_items_" + item.id}
                    >
                      <span>
                        {appConfig.direction === "ltr" ? (
                          <>
                            {item.quantity} x <b>{item.name_en}</b>
                          </>
                        ) : (
                          <>
                            {item.quantity} x <b>{item.name}</b>
                          </>
                        )}
                      </span>
                    </div>
                  ))}
                </div>
              </>
            )}
            <div style={{ textAlign: "center" }}>
              <small>Printed by https://anypos.app</small>
            </div>
          </div>
        )}
      </Spin>
      <Modal
        open={slipModalVisible}
        title={t("col_transaction_id") + " # " + transaction.uid}
        onCancel={hideSlipModal}
        footer={[
          <ReactToPrint
            trigger={() => (
              <Button key="print" type="primary">
                {t("print")}
              </Button>
            )}
            content={() => transactionSlipRef.current}
            key="1"
          />,
          <Button key="back" onClick={hideSlipModal}>
            {t("back")}
          </Button>,
        ]}
      >
        <TransactionSlip
          ref={transactionSlipRef}
          transaction={transaction}
          shop={{
            name: authUser.business_name,
            vat: authUser.vat,
            logo: authUser.logo,
            is_vat_exempt: authUser.is_vat_exempt,
          }}
        />
      </Modal>
    </div>
  );
};

export default ViewOrder;
