import "./App.less";
import { useEffect, useContext } from "react";
import { ConfigProvider, Spin } from "antd";
import ar_EG from "antd/lib/locale/ar_EG";
import en_GB from "antd/lib/locale/en_GB";
import Login from "../Auth/Login/Login";
import Register from "../Auth/Register/Register";
import Dashboard from "../Dashboard/Dashboard";
import { AppContext } from "../../context/AppContextProvider";
import { Route, Routes, useNavigate } from "react-router-dom";
import { getMyProfile } from "../../network/network";
import { useTranslation } from "react-i18next";
import { messaging as FCMMessaging } from "../../firebaseInit";
import { onMessage } from "firebase/messaging";
import NotificationWithIcon from "../../components/NotificationWithIcon";
import Forgot from "../Auth/Forgot/Forgot";
import TransactionsInvoice from "../Content/Transactions/TransactionsInvoice";
import { useLocation } from "react-router-dom";
import QRordering from "../Content/QRorders/QRordering";
import QRcart from "../Content/QRorders/QRcart";
import QRorderHistory from "../Content/QRorders/QRorderHistory";

const App = () => {
  const {
    authUser,
    setAuthUser,
    fullPageLoading,
    setFullPageLoading,
    setActiveMenu,
    appConfig,
    displayWebView,
    setDisplayWebView,
  } = useContext(AppContext);
  let navigate = useNavigate();
  const { i18n } = useTranslation();
  const { search } = useLocation();

  useEffect(() => {
    const query = new URLSearchParams(search);
    const viewType = query.get("view");
    if (viewType === "webview") {
      setDisplayWebView(viewType);
    }
  }, [search, setDisplayWebView]);

  useEffect(() => {
    var access_token = localStorage.getItem("access_token");
    if (window.location.pathname === "/transactions/invoice") {
      navigate(window.location.pathname + window.location.search);
    } else if (window.location.pathname === "/qr/ordering") {
      navigate(window.location.pathname + window.location.search);
    } else if (window.location.pathname === "/qr/cart") {
      navigate(window.location.pathname + window.location.search);
    } else if (window.location.pathname === "/qr/history") {
      navigate(window.location.pathname + window.location.search);
    } else if (access_token) {
      setFullPageLoading(true);
      getMyProfile()
        .then((response) => {
          setAuthUser(response.data.data.user);
          if (response.data.data.user) {
            if (response.data.data.user.is_onboarding_complete === 0) {
              navigate("/register-confirmed");
            }
            // Redirect to last visited page
            else if (window.location.pathname !== "/") {
              if (localStorage.getItem("last_visited_page")) {
                const lastVisitedPage = JSON.parse(
                  localStorage.getItem("last_visited_page")
                );
                if (lastVisitedPage.key && lastVisitedPage.path) {
                  setActiveMenu(lastVisitedPage.key);
                  navigate(lastVisitedPage.path);
                } else {
                  navigate("/home");
                }
              } else {
                navigate("/home");
              }
            }
          }
        })
        .catch((error) => {})
        .finally(() => {
          setFullPageLoading(false);
        });
    } else if (window.location.pathname === "/register") {
      if (displayWebView) {
        const params = new URLSearchParams();
        params.append("view", displayWebView);
        navigate({
          pathname: "/register",
          search: "?" + params.toString(),
        });
      }
    } else {
      navigate("/login");
    }
    // eslint-disable-next-line
  }, [
    setAuthUser,
    setFullPageLoading,
    setActiveMenu,
    authUser.is_onboarding_complete,
    search,
    displayWebView,
  ]);

  useEffect(() => {
    if (appConfig.direction === "rtl") {
      i18n.changeLanguage("ar-EG");
    } else {
      i18n.changeLanguage("en-GB");
    }
  }, [appConfig, i18n]);

  // Register FCM Listener
  onMessage(FCMMessaging, (payload) => {
    console.log(payload);
    if (
      payload &&
      payload.notification &&
      payload.notification.body === "RELOAD"
    ) {
      window.location.reload();
    } else if (
      payload &&
      payload.notification.body &&
      payload.notification.title &&
      payload.notification.body
    ) {
      NotificationWithIcon(
        "info",
        payload.notification.title,
        payload.notification.body
      );
    }
  });

  return (
    <ConfigProvider
      direction={appConfig.direction}
      locale={appConfig.direction === "ltr" ? en_GB : ar_EG}
      theme={{
        token: {
          colorPrimary: "#6301f2",
        },
      }}
    >
      <div className={appConfig.direction === "rtl" ? "app_rtl" : "app_ltr"}>
        {fullPageLoading && (
          <div
            style={{
              textAlign: "center",
              paddingTop: "45vh",
              height: "100vh",
              position: "fixed",
              left: 0,
              top: 0,
              width: "100vw",
              background: "#ffffff",
              zIndex: "999999",
            }}
          >
            <Spin size="large" />
          </div>
        )}
        {!fullPageLoading && !authUser ? (
          <Routes>
            <Route
              exact
              path="/login"
              name="Login"
              //   component={Login}
              element={<Login />}
              key="login"
            />
            <Route
              exact
              path="/register"
              name="Register"
              //   component={Register}
              element={<Register />}
              key="register"
            />
            <Route
              exact
              path="/forgot"
              name="Forgot"
              //   component={Forgot}
              element={<Forgot />}
              key="forgot"
            />
            <Route
              exact
              path="/transactions/invoice"
              name="Transactions-invoice"
              //   component={TransactionsInvoice}
              element={<TransactionsInvoice />}
              key="transactions_invoice"
            />
            <Route
              exact
              path="/qr/ordering"
              name="qr-ordering"
              //   component={QRordering}
              element={<QRordering />}
              key="qr_ordering"
            />
            <Route
              exact
              path="/qr/cart"
              name="qr-cart"
              element={<QRcart />}
              key="qr_cart"
            />
            <Route
              exact
              path="/qr/history"
              name="qr-history"
              element={<QRorderHistory />}
              key="qr_history"
            />
          </Routes>
        ) : (
          <Dashboard />
        )}
      </div>
    </ConfigProvider>
  );
};

export default App;
