import React, { useContext, useEffect, useState } from "react";
import { Row, Col, Typography, Button, Card, Spin, Tag } from "antd";
import { PageHeader } from "@ant-design/pro-layout";
import {
  HomeOutlined,
  DeploymentUnitOutlined,
  EyeOutlined,
} from "@ant-design/icons";
import {
  getMyProfile,
  showAddons,
  subscribeAddon,
} from "../../../network/network";
import { useTranslation } from "react-i18next";
import CurrencyFormatter from "../../../components/CurrencyFormatter";
import CustomBreadcrumb from "../../../components/CustomBreadcrumb";
import NotificationWithIcon from "../../../components/NotificationWithIcon";
import { AppContext } from "../../../context/AppContextProvider";
import { useParams } from "react-router-dom";
import AddonAction from "./AddonAction";

const { Text } = Typography;

const AddonDetail = () => {
  const { setAuthUser } = useContext(AppContext);
  const [reloadData, setReloadData] = useState(false);
  const [loading, setLoading] = useState(false);
  const [addonData, setAddonData] = useState([]);
  const { t } = useTranslation();
  let { addonId } = useParams();

  useEffect(() => {
    setLoading(true);
    showAddons()
      .then((response) => {
        setAddonData(
          response.data.data.addons.find(
            (addon) => addon.id === Number(addonId)
          )
        );
      })
      .finally(() => {
        setLoading(false);
      });
  }, [addonId, reloadData]);

  const handleActivate = (id) => {
    setLoading(true);
    subscribeAddon(id)
      .then((response) => {
        let data = response.data || response.response.data;
        if (data.success === true) {
          setReloadData(!reloadData);
          // Success

          NotificationWithIcon(
            "success",
            t("addon_subscribed"),
            t("addon_subscribed_successfully")
          );
          // recall authuser for set fresh data
          refreshAuthUser();
        } else {
          // Error
          NotificationWithIcon("error", t("something_went_wrong"), data.error);
        }
      })
      .catch((info) => {
        console.log("API Failed:", info.response);
      })
      .finally(() => {
        // Set Loading False
        setLoading(false);
      });
  };

  const breadcrumbRoutes = [
    {
      path: "/",
      breadcrumbName: t("menu_home"),
      icon: <HomeOutlined />,
    },
    {
      path: "/addons",
      breadcrumbName: t("menu_addons"),
      icon: <DeploymentUnitOutlined />,
    },
    {
      path: "view-addon",
      breadcrumbName: t("menu_addon_detail"),
      icon: <EyeOutlined />,
    },
  ];

  // refreshAuthUser
  const refreshAuthUser = () => {
    getMyProfile().then((response) => {
      setAuthUser(response.data.data.user);
    });
  };

  return (
    <>
      <Card style={{ marginBottom: "20px" }}>
        <CustomBreadcrumb menus={breadcrumbRoutes} />
        <PageHeader
          style={{ padding: "0" }}
          onBack={() => window.history.back()}
          title={t("menu_addon_detail")}
          ghost={false}
        />
      </Card>
      <Spin spinning={loading}>
        {addonData && (
          <>
            <Card>
              <Row gutter={20} style={{ alignItems: "center", rowGap: "10px" }}>
                <Col
                  className="gutter-row"
                  xs={24}
                  sm={24}
                  md={4}
                  lg={4}
                  xl={4}
                >
                  <img
                    alt={addonData.name}
                    src={
                      addonData.image
                        ? addonData.image
                        : "/images/image-not-found.png"
                    }
                    style={{
                      width: "100%",
                      height: "auto",
                      objectFit: "fill",
                    }}
                  />
                </Col>
                <Col
                  className="gutter-row"
                  xs={20}
                  sm={20}
                  md={16}
                  lg={16}
                  xl={16}
                >
                  <h3 style={{ fontWeight: "bold" }}>{addonData.name}</h3>
                  <div style={{ fontWeight: "bold" }}>
                    {addonData.discount > 0 ? (
                      <>
                        <small>
                          <Text delete type="danger">
                            <CurrencyFormatter value={addonData.price} />
                          </Text>
                        </small>
                        <span
                          style={{
                            color: "#8629ff",
                          }}
                        >
                          <CurrencyFormatter
                            value={addonData.price - addonData.discount}
                          />
                        </span>
                      </>
                    ) : addonData.trial_validity_days > 0 ? (
                      addonData.price !== "0.00" ? (
                        <>
                          <small>
                            <Text delete type="danger">
                              <CurrencyFormatter value={addonData.price} />
                            </Text>
                          </small>{" "}
                          <span
                            style={{
                              color: "#8629ff",
                            }}
                          >
                            <CurrencyFormatter value={0} />
                          </span>
                        </>
                      ) : (
                        <span
                          style={{
                            color: "#8629ff",
                          }}
                        >
                          <CurrencyFormatter value={0} />
                        </span>
                      )
                    ) : (
                      <span
                        style={{
                          color: "#8629ff",
                        }}
                      >
                        <CurrencyFormatter value={addonData.price} />
                      </span>
                    )}
                  </div>
                  <div
                    style={{
                      marginTop: "5px",
                      marginBottom: "10px",
                      fontWeight: "bold",
                    }}
                  >
                    {t("trial_days")} : {addonData.trial_validity_days}
                  </div>
                </Col>
                <Col
                  className="gutter-row"
                  style={{ textAlign: "right" }}
                  xs={4}
                  sm={4}
                  md={4}
                  lg={4}
                  xl={4}
                >
                  <AddonAction
                    addonData={addonData}
                    reloadCallback={() => setReloadData(!reloadData)}
                  />
                </Col>
              </Row>

              <div
                style={{
                  marginTop: "10px",
                }}
              >
                {addonData.description}
              </div>
            </Card>
            {addonData?.dependent_addons?.length !== 0 && (
              <Card style={{ marginTop: 20 }}>
                <h3 style={{ fontWeight: "bold", paddingBottom: "5px" }}>
                  Dependent Addons
                </h3>
                {addonData.dependent_addons?.map((addon) => (
                  <Row
                    style={{
                      margin: "10px 0 0 0",
                      backgroundColor: "#f0f2f5",
                      padding: "10px",
                      display: "flex",
                      alignItems: "center",
                    }}
                    key={addon.id}
                  >
                    <Col
                      className="gutter-row"
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                      xl={12}
                      key={addon.id}
                    >
                      {addon.name}
                    </Col>
                    <Col
                      className="gutter-row"
                      xs={4}
                      sm={4}
                      md={4}
                      lg={4}
                      xl={4}
                      style={{ textAlign: "right" }}
                    >
                      <div
                        style={{
                          fontWeight: "bold",
                        }}
                      >
                        {t("trial_days")} : {addon.trial_validity_days}
                      </div>
                    </Col>
                    <Col
                      className="gutter-row"
                      xs={4}
                      sm={4}
                      md={4}
                      lg={4}
                      xl={4}
                      style={{ textAlign: "center" }}
                    >
                      {addon.discount > 0 ? (
                        <>
                          <small>
                            <Text delete type="danger">
                              <CurrencyFormatter value={addon.price} />
                            </Text>
                          </small>
                          <br />
                          <span
                            style={{
                              color: "#8629ff",
                            }}
                          >
                            <CurrencyFormatter
                              value={addon.price - addon.discount}
                            />
                          </span>
                        </>
                      ) : addon.trial_validity_days > 0 ? (
                        addon.price !== "0.00" ? (
                          <>
                            <small>
                              <Text delete type="danger">
                                <CurrencyFormatter value={addon.price} />
                              </Text>
                            </small>
                            <br />
                            <span
                              style={{
                                color: "#8629ff",
                              }}
                            >
                              <CurrencyFormatter value={0} />
                            </span>
                          </>
                        ) : (
                          <span
                            style={{
                              color: "#8629ff",
                            }}
                          >
                            <CurrencyFormatter value={0} />
                          </span>
                        )
                      ) : (
                        <span
                          style={{
                            color: "#8629ff",
                          }}
                        >
                          <CurrencyFormatter value={addon.price} />
                        </span>
                      )}
                    </Col>
                    <Col
                      className="gutter-row"
                      xs={4}
                      sm={4}
                      md={4}
                      lg={4}
                      xl={4}
                      style={{ textAlign: "right" }}
                    >
                      {addon.is_subscribed ? (
                        <Tag color="green">{t("addon_subscribed")}</Tag>
                      ) : (
                        <Button
                          key="submit"
                          type="primary"
                          size="small"
                          onClick={() => {
                            handleActivate(addon.id);
                          }}
                        >
                          {t("subscribe")}
                        </Button>
                      )}
                    </Col>
                  </Row>
                ))}
              </Card>
            )}
          </>
        )}
      </Spin>
    </>
  );
};

export default AddonDetail;
