import React, { useEffect, createContext, useState } from "react";

export const AppContext = createContext();

const AppContextProvider = (props) => {
  const [authUser, setAuthUser] = useState(false);
  const [activeMenu, setActiveMenu] = useState("home");
  const [displayWebView, setDisplayWebView] = useState("");
  const [fullPageLoading, setFullPageLoading] = useState(true);
  const [appConfig, setAppConfig] = useState({});
  const [fullScreenPOS, setfullScreenPOS] = useState(false);
  const [isSiderCollapsed, setIsSiderCollapsed] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setFullPageLoading(false);
    }, 1500);
  }, []);

  const setAppConfigWrapper = (obj = {}) => {
    if (JSON.stringify(appConfig) !== JSON.stringify(obj)) {
      setAppConfig({ ...appConfig, ...obj });
    }
  };

  const roundOff = (number) => {
    let places = 8;
    if (authUser.round_off === true) {
      places = 2;
    }

    return +(Math.round(number + "e+" + places) + "e-" + places);
  };

  return (
    <AppContext.Provider
      value={{
        authUser,
        setAuthUser,
        activeMenu,
        setActiveMenu,
        fullPageLoading,
        setFullPageLoading,
        appConfig,
        setAppConfigWrapper,
        roundOff,
        fullScreenPOS,
        setfullScreenPOS,
        displayWebView,
        setDisplayWebView,
        isSiderCollapsed,
        setIsSiderCollapsed,
      }}
    >
      {props.children}
    </AppContext.Provider>
  );
};

export default AppContextProvider;
