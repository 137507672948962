import React from "react";
import { Table } from "antd";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { currencyFormatter } from "../../../utils";
import {
  BALANCE_DEDUCTION_TYPE_CUSTOMERSMANAGEMENT,
  BALANCE_DEDUCTION_TYPE_JOBMANAGEMENT,
  BALANCE_DEDUCTION_TYPE_ORDERMANAGEMENT,
  BALANCE_DEDUCTION_TYPE_QRORDER,
  BALANCE_DEDUCTION_TYPE_SALESINVOICE,
  BALANCE_DEDUCTION_TYPE_STOCKMANAGEMENT,
  BALANCE_DEDUCTION_TYPE_SUBSCRIPTION,
  BALANCE_DEDUCTION_TYPE_SUREPAY,
  BALANCE_DEDUCTION_TYPE_TABLEMANAGEMENT,
  BALANCE_DEDUCTION_TYPE_WAITERMANAGEMENT,
} from "../../../configs/constants";
// import { balanceDeductionType } from "../../../configs/constants";

const TransactionsTable = ({ balanceDeductionsHistory }) => {
  const { t } = useTranslation();

  const tableColumns = [
    {
      title: "#",
      key: "index",
      render: (text, record, index) => {
        return <>{index + 1}</>;
      },
    },
    {
      title: t("amount"),
      render: (subscription) => {
        return <>{currencyFormatter.format(subscription.amount)}</>;
      },
    },
    {
      title: t("col_date"),
      dataIndex: "created_at",
      render: (created_at) => {
        return created_at ? moment(created_at).format("MMM. D, YYYY") : "-";
      },
    },
    {
      title: t("name"),
      render: (subscription) => {
        return <>{subscription.user.name}</>;
      },
    },
    {
      title: t("col_type"),
      dataIndex: "deduction_type",
      render: (deduction_type) => {
        return (
          <>
            {deduction_type === BALANCE_DEDUCTION_TYPE_SUBSCRIPTION &&
              "subscription"}
            {deduction_type === BALANCE_DEDUCTION_TYPE_QRORDER && "Qr Order"}
            {deduction_type === BALANCE_DEDUCTION_TYPE_TABLEMANAGEMENT &&
              "Table Management"}
            {deduction_type === BALANCE_DEDUCTION_TYPE_ORDERMANAGEMENT &&
              "Order Management"}
            {deduction_type === BALANCE_DEDUCTION_TYPE_WAITERMANAGEMENT &&
              "Waiter Management"}
            {deduction_type === BALANCE_DEDUCTION_TYPE_JOBMANAGEMENT &&
              "Job Management"}
            {deduction_type === BALANCE_DEDUCTION_TYPE_STOCKMANAGEMENT &&
              "Stock Management"}
            {deduction_type === BALANCE_DEDUCTION_TYPE_SUREPAY && "Surepay"}
            {deduction_type === BALANCE_DEDUCTION_TYPE_CUSTOMERSMANAGEMENT &&
              "Customer Management"}
            {deduction_type === BALANCE_DEDUCTION_TYPE_SALESINVOICE &&
              "Sales Invoice"}
          </>
        );
      },
    },
  ];

  return (
    <>
      <Table
        columns={tableColumns}
        dataSource={balanceDeductionsHistory}
        rowKey="id"
        pagination={false}
      />
    </>
  );
};

export default TransactionsTable;
