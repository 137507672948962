import React, { useEffect, useState } from "react";
import { Card, Table, Row, Col, Tabs, Spin, Tag } from "antd";
import { PageHeader } from "@ant-design/pro-layout";
import { HomeOutlined, WalletOutlined } from "@ant-design/icons";
import CustomBreadcrumb from "../../../components/CustomBreadcrumb";
import { useTranslation } from "react-i18next";
import { currencyFormatter } from "../../../utils";
import SubscriptionsTable from "./SubscriptionsTable";
import TransactionsTable from "./TransactionsTable";
import WalletDetails from "./WalletDetails";
import { getSubscriptions } from "../../../network/network";
import Text from "antd/lib/typography/Text";

const DailySubscriptions = () => {
  const [subscriptionData, setSubscriptionData] = useState([]);
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [totalBillingAmount, setTotalBillingAmount] = useState(false);

  const { t } = useTranslation();

  useEffect(() => {
    setIsDataLoading(true);
    getSubscriptions()
      .then((response) => {
        setSubscriptionData(response.data.data);
      })
      .finally(() => {
        setIsDataLoading(false);
      });
  }, []);

  const breadcrumbRoutes = [
    {
      path: "/",
      breadcrumbName: t("menu_home"),
      icon: <HomeOutlined />,
    },
    {
      path: "subscriptions",
      breadcrumbName: t("menu_subscriptions"),
      icon: <WalletOutlined />,
    },
  ];

  const billingDetailsTableColumns = [
    {
      title: "#",
      key: "index",
      render: (text, record, index) => {
        return <>{index + 1}</>;
      },
    },
    {
      title: t("service_name"),

      render: (subscription) => {
        return (
          <>
            {subscription.name}
            <br />
            {subscription.is_trial && <Tag color="blue">{t("trial")}</Tag>}
          </>
        );
      },
    },
    {
      title: t("col_price"),
      render: (subscription) => {
        return (
          <>
            {currencyFormatter.format(subscription.unit_price)} /{" "}
            {subscription.quantity ? t("per_user") : t("unlimited")}
          </>
        );
      },
    },
    {
      title: t("col_type"),
      dataIndex: "subscription_addon_type",
    },
    {
      title: t("col_quantity"),
      dataIndex: "quantity",
      align: "center",
      render: (quantity) => {
        return quantity ? quantity : "-";
      },
    },
    {
      title: t("total"),
      render: (subscription) => {
        return (
          <>
            {subscription.is_trial ? (
              <>
                <small>
                  <Text delete type="danger">
                    {currencyFormatter.format(
                      subscription.quantity
                        ? subscription.quantity * subscription.unit_price
                        : subscription.unit_price
                    )}
                  </Text>
                </small>
                <br />
                {currencyFormatter.format(0)}
              </>
            ) : (
              currencyFormatter.format(
                subscription.quantity
                  ? subscription.quantity * subscription.unit_price
                  : subscription.unit_price
              )
            )}
          </>
        );
      },
    },
  ];

  return (
    <>
      <Card style={{ marginBottom: "20px" }}>
        <CustomBreadcrumb menus={breadcrumbRoutes} />
        <PageHeader
          style={{ padding: "0" }}
          title={t("menu_subscriptions")}
          ghost={false}
        />
      </Card>
      <Spin spinning={isDataLoading}>
        <Row gutter={{ xs: 20, sm: 20, md: 20, lg: 20 }}>
          <Col xs={24} sm={24} md={24} lg={15} xl={15} xxl={15}>
            <div
              style={{
                textAlign: "center",
                color: "#ffffff",
                backgroundColor: "#6301f2",
                padding: "10px",
              }}
            >
              {t("billing_details")}
            </div>
            <Table
              columns={billingDetailsTableColumns}
              dataSource={subscriptionData.active_subscriptions_addons}
              rowKey="id"
              pagination={false}
              summary={(pageData) => {
                let totalSales = 0;

                pageData.forEach(({ quantity, unit_price, is_trial }) => {
                  totalSales =
                    totalSales +
                    (is_trial
                      ? 0
                      : quantity
                      ? quantity * unit_price
                      : unit_price);
                });

                return (
                  <>
                    <Table.Summary.Row
                      style={{ backgroundColor: "#fafafa", fontWeight: "bold" }}
                    >
                      <Table.Summary.Cell colSpan={5}>
                        {t("total")}
                      </Table.Summary.Cell>
                      <Table.Summary.Cell>
                        {currencyFormatter.format(totalSales)}
                        {setTotalBillingAmount(totalSales)}
                      </Table.Summary.Cell>
                    </Table.Summary.Row>
                  </>
                );
              }}
            />
          </Col>
          <Col xs={24} sm={24} md={24} lg={9} xl={9} xxl={9}>
            <WalletDetails
              balance={subscriptionData.balance}
              totalBillingAmount={totalBillingAmount}
            />
          </Col>
        </Row>

        <Card style={{ marginTop: "20px" }}>
          <Tabs defaultActiveKey="subscriptions-table" type="card" centered>
            <Tabs.TabPane
              tab={t("subscriptions_history")}
              key="subscriptions-table"
            >
              <SubscriptionsTable
                subscriptionsAddons={subscriptionData.subscriptions_addons}
              />
            </Tabs.TabPane>
            <Tabs.TabPane tab={t("spending_history")} key="transactions-table">
              <TransactionsTable
                balanceDeductionsHistory={
                  subscriptionData.balance_deductions_history
                }
              />
            </Tabs.TabPane>
          </Tabs>
        </Card>
      </Spin>
    </>
  );
};

export default DailySubscriptions;
