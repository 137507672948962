import React from "react";
import { Table, Tag } from "antd";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { currencyFormatter } from "../../../utils";

const SubscriptionsTable = ({ subscriptionsAddons }) => {
  const { t } = useTranslation();

  const tableColumns = [
    {
      title: "#",
      key: "index",
      render: (text, record, index) => {
        return <>{index + 1}</>;
      },
    },
    {
      title: t("name"),
      dataIndex: "name",
    },
    {
      title: t("col_price"),
      render: (subscription) => {
        return (
          <>
            {currencyFormatter.format(subscription.unit_price)} /{" "}
            {subscription.quantity ? t("per_user") : t("unlimited")}
          </>
        );
      },
    },
    {
      title: t("col_type"),
      dataIndex: "subscription_addon_type",
    },
    {
      title: t("col_start_date"),
      dataIndex: "start_date",
      render: (start_date) => {
        return start_date ? moment(start_date).format("MMM. D, YYYY") : "-";
      },
    },
    {
      title: t("col_end_date"),
      dataIndex: "end_date",
      render: (end_date) => {
        return end_date ? moment(end_date).format("MMM. D, YYYY") : "-";
      },
    },
    {
      title: t("col_status"),
      dataIndex: "end_date",
      render: (end_date) => {
        return (
          <>
            {moment().isAfter(moment(end_date)) ? (
              <Tag color="blue">{t("inactive")}</Tag>
            ) : (
              <Tag color="green">{t("active")}</Tag>
            )}
          </>
        );
      },
    },
  ];

  return (
    <>
      <Table
        columns={tableColumns}
        dataSource={subscriptionsAddons}
        rowKey="id"
        pagination={false}
      />
    </>
  );
};

export default SubscriptionsTable;
