import React, { useContext, useEffect, useState } from "react";
import { DatePicker, Form, Input, Card, Descriptions, Button } from "antd";
import { PageHeader } from "@ant-design/pro-layout";
import { HomeOutlined, BarChartOutlined } from "@ant-design/icons";
import { getSalesSummary } from "../../../network/network";
import CustomBreadcrumb from "../../../components/CustomBreadcrumb";
import { currencyFormatter } from "../../../utils";
import { transactionTypes } from "../../../configs/constants";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { CSVLink } from "react-csv";
import { AppContext } from "../../../context/AppContextProvider";

const SalesSummary = () => {
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [reportData, setReportData] = useState([]);
  const [multipaymentTransactions, SetMultipaymentTransactions] = useState([]);
  const [paymentTypesData, setPaymentTypesData] = useState([]);
  const [searchParams, setSearchParams] = useState([]);
  const { t } = useTranslation();
  const [searchFormRef] = Form.useForm();
  const { RangePicker } = DatePicker;
  const { authUser } = useContext(AppContext);

  useEffect(() => {
    setIsDataLoading(true);
    getSalesSummary(searchParams).then((response) => {
      setReportData(response.data.data);

      let summary = {};
      summary.gross_sales = response.data.data.transactions_total.total_sales;
      summary.refunds = response.data.data.refunded_transactions_total
        ? response.data.data.refunded_transactions_total
        : 0;
      summary.taxes = response.data.data.transactions_total.taxes;
      summary.net_sales =
        summary.gross_sales -
        Math.abs(summary.refunds) -
        Math.abs(summary.taxes);
      setReportData(summary);

      let data = [];
      transactionTypes.forEach((type) => {
        const transactionType = response.data.data.transactions_by_type.find(
          (transaction) => transaction.type === type.id
        );

        data.push({
          id: type.id,
          text: type.text,
          amount: transactionType ? transactionType.total_charged_amount : 0,
        });
      });

      data.forEach((type, i) => {
        const refundType = response.data.data.refunded_by_type.find(
          (transaction) => transaction.type === type.id
        );

        if (refundType) {
          data[i].amount =
            data[i].amount - Math.abs(refundType.total_refunded_amount);
        }
      });

      SetMultipaymentTransactions(
        response.data.data.transactions_by_multipayment
      );

      setPaymentTypesData(data);
      setIsDataLoading(false);
    });
  }, [searchParams]);

  const breadcrumbRoutes = [
    {
      path: "/",
      breadcrumbName: t("menu_home"),
      icon: <HomeOutlined />,
    },
    {
      path: null,
      breadcrumbName: t("menu_reports") + " / " + t("menu_sales_summary"),
      icon: <BarChartOutlined />,
    },
  ];

  const boldContentStyle = {
    fontWeight: "bold",
    backgroundColor: "#fafafa",
    textAlign: "right",
  };
  const contentStyle = {
    textAlign: "right",
  };

  const handleSearch = (values) => {
    let search_params = false;
    if (values.dates) {
      let startDateUtc = moment.utc(values.dates[0]).set({
        date: values.dates[0].date() - 1,
        month: values.dates[0].month(),
        year: values.dates[0].year(),
        hour: 19,
        minute: 0,
        second: 0,
        millisecond: 0,
      });
      let endDateUtc = moment.utc(values.dates[1]).set({
        date: values.dates[1].date(),
        month: values.dates[1].month(),
        year: values.dates[1].year(),
        hour: 18,
        minute: 59,
        second: 59,
        millisecond: 0,
      });

      search_params = {
        search_start_date: startDateUtc.format(),
        search_end_date: endDateUtc.format(),
      };
    }
    if (search_params) {
      setSearchParams(search_params);
    } else {
      setSearchParams([]);
    }
  };
  const handleReset = () => {
    // Reset Form Fields
    searchFormRef.resetFields();
    // Reset Transactions List
    setSearchParams([]);
  };

  const searchForm = (
    <>
      <Form
        name="search-report-form"
        layout="inline"
        onFinish={handleSearch}
        form={searchFormRef}
      >
        <Form.Item name="dates" label={t("date_range")}>
          <RangePicker allowClear format="DD/MM/YYYY" />
        </Form.Item>
        <Form.Item>
          <Input
            type="submit"
            value={t("search")}
            style={{
              backgroundColor: "#6301f2",
              color: "#ffffff",
              width: "80px",
            }}
          />
        </Form.Item>
        <Form.Item>
          <Input type="button" value={t("reset")} onClick={handleReset} />
        </Form.Item>
      </Form>
    </>
  );

  const salesCSVData = [
    [
      "Company Name",
      "CR number",
      authUser.is_vat_exempt ? "VAT Exempt" : "VAT number",
      "Gross Sales",
      "Refunds",
      "VAT on Sales",
      "Net Sales",
    ],
    [
      authUser.business_name,
      authUser.cr,
      authUser.is_vat_exempt ? authUser.is_vat_exempt : authUser.vat,
      currencyFormatter.format(reportData.gross_sales),
      currencyFormatter.format(reportData.refunds),
      currencyFormatter.format(reportData.taxes * -1),
      currencyFormatter.format(reportData.net_sales),
    ],
  ];

  const paymentsCSVData = [
    [
      "Cash",
      "Credit Card",
      "Multi Payment",
      "Multi Payment Cash",
      "Multi Payment Card",
      "VAT on Sales",
      "Net Total",
    ],
    [
      paymentTypesData && currencyFormatter.format(paymentTypesData[0]?.amount),
      paymentTypesData && currencyFormatter.format(paymentTypesData[1]?.amount),
      paymentTypesData && currencyFormatter.format(paymentTypesData[2]?.amount),
      multipaymentTransactions &&
        currencyFormatter.format(
          multipaymentTransactions[0]?.total_charged_amount
        ),
      multipaymentTransactions &&
        currencyFormatter.format(
          multipaymentTransactions[1]?.total_charged_amount
        ),
      currencyFormatter.format(reportData.taxes),
      paymentTypesData.length &&
        currencyFormatter.format(
          paymentTypesData
            .map((item) => item.amount)
            .reduce((prev, next) => prev + next) - Math.abs(reportData.taxes)
        ),
    ],
  ];

  return (
    <>
      <Card style={{ marginBottom: "20px" }}>
        <CustomBreadcrumb menus={breadcrumbRoutes} />
        <PageHeader
          style={{ padding: "0" }}
          title={t("menu_sales_summary")}
          breadcrumb={CustomBreadcrumb}
          ghost={false}
          footer={searchForm}
          extra={[
            <Button type="primary" key="download-sales-report">
              <CSVLink
                data={salesCSVData}
                filename={
                  "Sales summry report" +
                  " " +
                  moment().format("MM-DD-YYYY HH-mm") +
                  ".csv"
                }
                target="_blank"
              >
                {t("download_sales_report")}
              </CSVLink>
            </Button>,
            <Button type="primary" key="download-payment-report">
              <CSVLink
                data={paymentsCSVData}
                filename={
                  "Payments summry report" +
                  " " +
                  moment().format("MM-DD-YYYY HH-mm") +
                  ".csv"
                }
                target="_blank"
              >
                {t("download_payments_report")}
              </CSVLink>
            </Button>,
          ]}
        />
      </Card>
      <Card loading={isDataLoading} style={{ marginBottom: "20px" }}>
        <Descriptions
          title={t("sales")}
          bordered={true}
          size="middle"
          span={24}
        >
          <Descriptions.Item
            label={t("gross_sales")}
            span={24}
            labelStyle={{ fontWeight: "bold" }}
            contentStyle={boldContentStyle}
          >
            {currencyFormatter.format(reportData.gross_sales)}
          </Descriptions.Item>

          <Descriptions.Item
            label={t("refunds")}
            span={24}
            labelStyle={{ background: "none" }}
            contentStyle={contentStyle}
          >
            {currencyFormatter.format(reportData.refunds)}
          </Descriptions.Item>

          <Descriptions.Item
            label={t("taxes")}
            span={24}
            labelStyle={{ background: "none" }}
            contentStyle={contentStyle}
          >
            {currencyFormatter.format(reportData.taxes * -1)}
          </Descriptions.Item>

          <Descriptions.Item
            label={t("net_sales")}
            span={24}
            labelStyle={{ fontWeight: "bold" }}
            contentStyle={boldContentStyle}
          >
            {currencyFormatter.format(reportData.net_sales)}
          </Descriptions.Item>

          {/* <Descriptions.Item
            label={t("total_sales")}
            span={24}
            labelStyle={{ fontWeight: "bold" }}
            contentStyle={boldContentStyle}
          >
            {currencyFormatter.format(reportData.total_sales)}
          </Descriptions.Item> */}
        </Descriptions>
      </Card>
      <Card loading={isDataLoading}>
        <Descriptions
          title={t("payments")}
          bordered={true}
          size="middle"
          span={24}
        >
          {paymentTypesData.map((type) => (
            <Descriptions.Item
              key={type.id}
              label={type.text}
              span={24}
              labelStyle={{ background: "none" }}
              contentStyle={contentStyle}
            >
              {currencyFormatter.format(type.amount)}
            </Descriptions.Item>
          ))}
          <Descriptions.Item
            label="Multi Payment Cash"
            span={24}
            labelStyle={{
              fontWeight: "bold",
              width: "60%",
              background: "none",
            }}
            contentStyle={contentStyle}
          >
            {multipaymentTransactions &&
              currencyFormatter.format(
                multipaymentTransactions[0]?.total_charged_amount
              )}
          </Descriptions.Item>

          <Descriptions.Item
            label="Multi Payment Card"
            span={24}
            labelStyle={{
              fontWeight: "bold",
              width: "60%",
              background: "none",
            }}
            contentStyle={contentStyle}
          >
            {multipaymentTransactions &&
              currencyFormatter.format(
                multipaymentTransactions[1]?.total_charged_amount
              )}
          </Descriptions.Item>

          <Descriptions.Item
            label={t("taxes")}
            span={24}
            labelStyle={{ fontWeight: "bold", width: "60%" }}
            contentStyle={boldContentStyle}
          >
            {currencyFormatter.format(reportData.taxes)}
          </Descriptions.Item>

          <Descriptions.Item
            label={t("net_total")}
            span={24}
            labelStyle={{ fontWeight: "bold", width: "60%" }}
            contentStyle={boldContentStyle}
          >
            {paymentTypesData.length &&
              currencyFormatter.format(
                paymentTypesData
                  .map((item) => item.amount)
                  .reduce((prev, next) => prev + next) -
                  Math.abs(reportData.taxes)
              )}
          </Descriptions.Item>
        </Descriptions>
      </Card>
    </>
  );
};

export default SalesSummary;
