import "./Orders.css";
import React, { useEffect, useState } from "react";
import { Card, Col, Row, Spin } from "antd";
import { PageHeader } from "@ant-design/pro-layout";
import { HomeOutlined, ReconciliationOutlined } from "@ant-design/icons";
import CustomBreadcrumb from "../../../components/CustomBreadcrumb";
import { useTranslation } from "react-i18next";
import PendingOrders from "./PendingOrders";
import {
  TRANSACTION_STATUS_CANCELLED,
  TRANSACTION_STATUS_COMPLETED,
  TRANSACTION_STATUS_INPROGRESS,
  TRANSACTION_STATUS_PENDING,
} from "../../../configs/constants";
import InprogressOrders from "./InprogressOrders";
import CompletedOrders from "./CompletedOrders";
import CancelledOrders from "./CancelledOrders";
import { getBranches, getOrdersCount } from "../../../network/network";

const Orders = () => {
  const [reload, setReload] = useState(false);
  const [tickets, setTickets] = useState([]);
  const { t } = useTranslation();
  const [ordersCount, setOrdersCount] = useState([]);
  const [branchesData, setBranchesData] = useState([]);
  const [isDataLoading, setIsDataLoading] = useState(false);

  useEffect(() => {
    setIsDataLoading(true);
    getBranches()
      .then((response) => {
        setBranchesData(response.data.data.branches);
      })
      .finally(() => {
        setIsDataLoading(false);
      });
  }, []);

  useEffect(() => {
    getOrdersCount().then((response) => {
      setOrdersCount(response.data.data);
    });
  }, [reload]);

  const onChangeTab = (key) => {
    setTickets(key);
  };

  const breadcrumbRoutes = [
    {
      path: "/",
      breadcrumbName: t("menu_home"),
      icon: <HomeOutlined />,
    },
    {
      path: "orders",
      breadcrumbName: t("menu_orders"),
      icon: <ReconciliationOutlined />,
    },
  ];

  return (
    <>
      <Card style={{ marginBottom: "20px" }}>
        <CustomBreadcrumb menus={breadcrumbRoutes} />
        <PageHeader
          style={{ padding: "0" }}
          title={t("menu_orders")}
          ghost={false}
        />
      </Card>
      <Spin spinning={isDataLoading}>
        <Row
          gutter={[20, 20]}
          style={{ marginBottom: "20px", textAlign: "center" }}
        >
          <Col span={6}>
            <Card
              className={
                "tab_title" +
                (tickets === TRANSACTION_STATUS_PENDING ? " active" : "")
              }
              onClick={() => onChangeTab(TRANSACTION_STATUS_PENDING)}
            >
              <div>
                {t("col_pending_orders")}
                <span className="tab_count">
                  {ordersCount.pending_orders_count}
                </span>
              </div>
            </Card>
          </Col>
          <Col span={6}>
            <Card
              className={
                "tab_title" +
                (tickets === TRANSACTION_STATUS_INPROGRESS ? " active" : "")
              }
              onClick={() => onChangeTab(TRANSACTION_STATUS_INPROGRESS)}
            >
              <div>
                {t("col_inprogress_orders")}
                <span className="tab_count">
                  {ordersCount.in_progress_orders_count}
                </span>
              </div>
            </Card>
          </Col>
          <Col span={6}>
            <Card
              className={
                "tab_title" +
                (tickets === TRANSACTION_STATUS_COMPLETED ? " active" : "")
              }
              onClick={() => onChangeTab(TRANSACTION_STATUS_COMPLETED)}
            >
              <div>
                {t("col_completed_orders")}
                <span className="tab_count">
                  {ordersCount.completed_orders_count}
                </span>
              </div>
            </Card>
          </Col>
          <Col span={6}>
            <Card
              className={
                "tab_title" +
                (tickets === TRANSACTION_STATUS_CANCELLED ? " active" : "")
              }
              onClick={() => onChangeTab(TRANSACTION_STATUS_CANCELLED)}
            >
              <div>
                {t("col_cancelled_orders")}
                <span className="tab_count">
                  {ordersCount.cancelled_orders_count}
                </span>
              </div>
            </Card>
          </Col>
        </Row>
      </Spin>
      {tickets === TRANSACTION_STATUS_PENDING && (
        <PendingOrders
          branchesData={branchesData}
          reloadCallback={() => setReload(!reload)}
        />
      )}
      {tickets === TRANSACTION_STATUS_INPROGRESS && (
        <InprogressOrders
          branchesData={branchesData}
          reloadCallback={() => setReload(!reload)}
        />
      )}
      {tickets === TRANSACTION_STATUS_COMPLETED && (
        <CompletedOrders
          branchesData={branchesData}
          reloadCallback={() => setReload(!reload)}
        />
      )}
      {tickets === TRANSACTION_STATUS_CANCELLED && (
        <CancelledOrders
          branchesData={branchesData}
          reloadCallback={() => setReload(!reload)}
        />
      )}
    </>
  );
};

export default Orders;
