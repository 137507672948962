import React, { useEffect, useState, useContext } from "react";
import {
  Card,
  Table,
  Tag,
  Button,
  Modal,
  Descriptions,
  InputNumber,
  Alert,
} from "antd";
import { PageHeader } from "@ant-design/pro-layout";
import { EyeOutlined, HomeOutlined, WalletOutlined } from "@ant-design/icons";
import {
  getSubscriptions,
  createSubscription,
  getSubscriptionPlansAsObject,
} from "../../../network/network";
import CustomBreadcrumb from "../../../components/CustomBreadcrumb";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { currencyFormatter, findPercentage } from "../../../utils";
import {
  PLAN_TYPE_PRO,
  PERIOD_MONTHLY,
  PERIOD_YEARLY,
  COMPANY_STATUS_SUBSCRIPTION_ENDED,
  COMPANY_STATUS_SUBSCRIPTION_INVOICE_GENERATED,
  PERIOD_DAILY,
} from "../../../configs/constants";
import NotificationWithIcon from "../../../components/NotificationWithIcon";
import { AppContext } from "../../../context/AppContextProvider";

const YearlySubscriptions = () => {
  const { authUser } = useContext(AppContext);
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [subscriptionPlans, setSubscriptionPlans] = useState([]);
  const [subscriptionsTableData, setSubscriptionsTableData] = useState([]);
  const [activeSubscription, setActiveSubscription] = useState(false);
  const [requestedSubscription, setRequestedSubscription] = useState(false);
  const [upgradePlan, setUpgradePlan] = useState(false);
  const [usersCount, setUsersCount] = useState(1);
  const [loading, setLoading] = useState(false);
  const [reloadData, setReloadData] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    setIsDataLoading(true);
    getSubscriptions()
      .then((response) => {
        setSubscriptionsTableData(response.data.data.subscriptions);
        setActiveSubscription(response.data.data.active_subscription);
        if (response.data.data.active_subscription) {
          setUsersCount(
            response.data.data.active_subscription.user_licenses_count
          );
        }
        setRequestedSubscription(response.data.data.requested_subscription);
      })
      .finally(() => {
        setIsDataLoading(false);
      });
    getSubscriptionPlansAsObject().then((response) => {
      setSubscriptionPlans(response.data.data.subscription_plans);
    });
  }, [reloadData]);

  const breadcrumbRoutes = [
    {
      path: "/",
      breadcrumbName: t("menu_home"),
      icon: <HomeOutlined />,
    },
    {
      path: "subscriptions",
      breadcrumbName: t("menu_subscriptions"),
      icon: <WalletOutlined />,
    },
  ];

  const subscriptionsTableColumns = [
    {
      title: "#",
      key: "index",
      render: (text, record, index) => {
        return <p>{index + 1}</p>;
      },
    },
    {
      title: t("col_subscription_plan"),
      render: (subscription) => {
        return subscription.type === PLAN_TYPE_PRO ? (
          <Tag color="green">{subscription.name}</Tag>
        ) : (
          <Tag color="blue">{subscription.name}</Tag>
        );
      },
    },
    {
      title: t("col_price"),
      render: (subscription) => {
        return (
          <>
            {currencyFormatter.format(
              subscription.amount +
                (subscription.license_amount - subscription.license_discount) *
                  subscription.user_licenses_count_all
            )}{" "}
            /{" "}
            {subscription.period === PERIOD_DAILY
              ? t("day")
              : subscription.period === PERIOD_YEARLY
              ? t("year")
              : t("month")}
            {subscription.is_trial === 0 && (
              <>
                <br />
                <small>
                  {t("users") + ": " + subscription.user_licenses_count_all}
                </small>
              </>
            )}
            {subscription.is_trial === 1 && subscription.end_date && (
              <>
                <br />
                <Tag color="red">
                  <small>
                    * {t("pay_on")}{" "}
                    {moment(subscription.end_date).format("MMM. D, YYYY")}{" "}
                  </small>
                </Tag>
              </>
            )}
          </>
        );
      },
    },
    {
      title: t("col_start_date"),
      dataIndex: "start_date",
      render: (start_date) => {
        return start_date ? moment(start_date).format("MMM. D, YYYY") : "-";
      },
    },
    {
      title: t("col_end_date"),
      dataIndex: "end_date",
      render: (end_date) => {
        return end_date ? moment(end_date).format("MMM. D, YYYY") : "-";
      },
    },
    {
      title: t("action"),
      render: (subscription) => {
        return (
          <>
            {subscription.is_trial === 1 && <Tag>{t("trial")}</Tag>}
            {subscription.invoice && (
              <Button
                type="default"
                size="small"
                target="_blank"
                href={
                  process.env.REACT_APP_API_ROOT +
                  "/companies/" +
                  subscription.invoice.company_id +
                  "/invoices/" +
                  subscription.invoice.id
                }
              >
                <EyeOutlined /> {t("view_invoice")}
              </Button>
            )}
          </>
        );
      },
    },
  ];

  const subscriptionPlanCard = (subscriptionPlan) => (
    <div
      style={{
        width: "300px",
        margin: "10px 10px 0 10px",
        padding: "20px",
        border: "1px solid #f0f0f0",
        backgroundColor: "#74dbe1",
      }}
    >
      <h3>{subscriptionPlan.name}</h3>
      <p>
        {subscriptionPlan.period === PERIOD_DAILY
          ? currencyFormatter.format(
              subscriptionPlan.price -
                findPercentage(
                  subscriptionPlan.discount,
                  subscriptionPlan.price
                )
            )
          : currencyFormatter.format(
              subscriptionPlan.price - subscriptionPlan.discount
            )}{" "}
        /{" "}
        {subscriptionPlan.period === PERIOD_DAILY
          ? t("daily")
          : subscriptionPlan.period === PERIOD_YEARLY
          ? t("yearly")
          : t("monthly")}
        <>
          <br />
          <small>
            + {t("additional")}{" "}
            {subscriptionPlan.period === PERIOD_DAILY
              ? currencyFormatter.format(
                  subscriptionPlan.user_price -
                    findPercentage(
                      subscriptionPlan.user_discount,
                      subscriptionPlan.user_price
                    )
                )
              : currencyFormatter.format(
                  subscriptionPlan.user_price - subscriptionPlan.user_discount
                )}{" "}
            /{" "}
            {subscriptionPlan.period === PERIOD_DAILY
              ? t("daily")
              : subscriptionPlan.period === PERIOD_YEARLY
              ? t("yearly")
              : t("monthly")}{" "}
            {t("for_1_user")}
          </small>
        </>
      </p>
      <div>
        <Tag color="green">{t("pro_plan")}</Tag>
        {subscriptionPlan.period === PERIOD_DAILY ? (
          <Tag color="orange">{t("daily")}</Tag>
        ) : subscriptionPlan.period === PERIOD_YEARLY ? (
          <Tag color="orange">{t("yearly")}</Tag>
        ) : (
          <Tag color="orange">{t("monthly")}</Tag>
        )}
      </div>
    </div>
  );

  const handleSendRequest = () => {
    setLoading(true);
    createSubscription({
      type: upgradePlan.type,
      period: upgradePlan.period,
      users_count: usersCount,
    }).then((response) => {
      if (response.data.success === true) {
        // Success
        NotificationWithIcon(
          "success",
          t("subscription_upgrade_requested"),
          t("subscription_upgrade_requested_successfully")
        );
        setReloadData(!reloadData);
      }
      setUpgradePlan(false);
    });
  };

  return (
    <>
      <Card style={{ marginBottom: "20px" }}>
        <CustomBreadcrumb menus={breadcrumbRoutes} />
        <PageHeader title="Yearly" style={{ padding: "0" }} ghost={false} />
      </Card>
      {authUser.company_status === COMPANY_STATUS_SUBSCRIPTION_ENDED && (
        <Alert
          message={t("account_freezed")}
          description={t("upgrade_subscription_unfreeze")}
          type="info"
          showIcon
        />
      )}
      <div style={{ display: "flex" }}>
        {activeSubscription ? (
          <Card
            title={t("active_subscription") + " - " + activeSubscription.name}
            styles={{
              textAlign: "center",
              color: "#ffffff",
              backgroundColor: "#6301f2",
            }}
            style={{ marginRight: "20px", textAlign: "center" }}
          >
            <p>
              {moment(activeSubscription.start_date).format("MMM. D, YYYY")} -{" "}
              {moment(activeSubscription.end_date).format("MMM. D, YYYY")}
            </p>
            <p>
              {currencyFormatter.format(
                activeSubscription.amount +
                  (activeSubscription.license_amount -
                    activeSubscription.license_discount) *
                    activeSubscription.user_licenses_count_all
              )}{" "}
              /{" "}
              {activeSubscription.period === PERIOD_DAILY
                ? t("day")
                : activeSubscription.period === PERIOD_YEARLY
                ? t("year")
                : t("month")}
              {activeSubscription.is_trial === 1 &&
                activeSubscription.end_date && (
                  <>
                    <br />
                    <Tag color="red">
                      <small>
                        * {t("pay_on")}{" "}
                        {moment(activeSubscription.end_date).format(
                          "MMM. D, YYYY"
                        )}{" "}
                      </small>
                    </Tag>
                  </>
                )}
            </p>
            <div>
              <Tag color="green">{t("pro_plan")}</Tag>
              {activeSubscription.period === PERIOD_DAILY ? (
                <Tag color="orange">{t("daily")}</Tag>
              ) : activeSubscription.period === PERIOD_YEARLY ? (
                <Tag color="orange">{t("yearly")}</Tag>
              ) : (
                <Tag color="orange">{t("monthly")}</Tag>
              )}
              {activeSubscription.is_trial === 1 && <Tag>{t("trial")}</Tag>}
            </div>
          </Card>
        ) : (
          <Card
            title={t("available_upgrades")}
            styles={{
              textAlign: "center",
              backgroundColor: "#fafafa",
              padding: "5px",
            }}
          >
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              {authUser.company_status ===
              COMPANY_STATUS_SUBSCRIPTION_INVOICE_GENERATED ? (
                <Tag color="blue">{t("pay_generated_invoice_first")}</Tag>
              ) : (
                subscriptionPlans.map((plan) => {
                  if (
                    !activeSubscription ||
                    (activeSubscription.type === PLAN_TYPE_PRO &&
                      activeSubscription.period === PERIOD_MONTHLY &&
                      plan.type === PLAN_TYPE_PRO &&
                      plan.period === PERIOD_YEARLY)
                  ) {
                    return (
                      <div key={plan.id}>
                        {subscriptionPlanCard(plan)}
                        <Button
                          disabled={requestedSubscription}
                          type="primary"
                          style={{
                            width: "300px",
                            margin: "0 10px",
                            // width: "100%",
                          }}
                          onClick={() => {
                            setLoading(false);
                            if (
                              activeSubscription &&
                              activeSubscription.user_licenses_count
                            ) {
                              setUsersCount(
                                activeSubscription.user_licenses_count
                              );
                            } else {
                              setUsersCount(1);
                            }
                            setUpgradePlan(plan);
                          }}
                        >
                          {requestedSubscription &&
                          requestedSubscription.name === plan.name
                            ? t("already_requested")
                            : t("request_upgrade")}
                        </Button>
                      </div>
                    );
                  } else {
                    return false;
                  }
                })
              )}
            </div>
          </Card>
        )}
      </div>
      <Card style={{ marginTop: "40px" }}>
        <Table
          columns={subscriptionsTableColumns}
          dataSource={subscriptionsTableData}
          loading={isDataLoading}
          rowKey="id"
          pagination={false}
        />
      </Card>
      <Modal
        title={t("request_subscription_upgrade")}
        onOk={handleSendRequest}
        onCancel={() => setUpgradePlan(false)}
        okText={t("request_upgrade")}
        open={upgradePlan}
        confirmLoading={loading}
      >
        <p>{t("you_selected_following_package")}:</p>
        {subscriptionPlanCard(upgradePlan)}
        <h4>{t("calculations")}:</h4>

        <Descriptions
          bordered
          size="middle"
          column={{ xs: 8, sm: 16, md: 24 }}
          layout="horizontal"
        >
          {/* {upgradePlan.type === PLAN_TYPE_PRO && ( */}
          <Descriptions.Item
            span={24}
            label={
              <>
                <InputNumber
                  style={{ width: "60px" }}
                  defaultValue={
                    activeSubscription
                      ? activeSubscription.user_licenses_count
                      : 1
                  }
                  min={
                    activeSubscription
                      ? activeSubscription.user_licenses_count
                      : 1
                  }
                  onChange={(value) => setUsersCount(value)}
                />{" "}
                {t("users")}
              </>
            }
            contentStyle={{
              textAlign: "right",
              fontWeight: "bold",
              padding: "8px 12px",
            }}
            labelStyle={{ padding: "8px 12px" }}
          >
            {currencyFormatter.format(upgradePlan.user_price * usersCount)}
          </Descriptions.Item>
          {/* )} */}

          <Descriptions.Item
            span={24}
            label={
              upgradePlan.type === PLAN_TYPE_PRO
                ? t("pro_plan")
                : t("basic_plan")
            }
            contentStyle={{
              textAlign: "right",
              fontWeight: "bold",
              padding: "8px 12px",
            }}
            labelStyle={{ padding: "8px 12px" }}
          >
            {currencyFormatter.format(upgradePlan.price)}
          </Descriptions.Item>

          {(upgradePlan.discount || upgradePlan.user_discount) && (
            <Descriptions.Item
              span={24}
              label={t("discount")}
              contentStyle={{
                textAlign: "right",
                fontWeight: "bold",
                padding: "8px 12px",
              }}
              labelStyle={{ padding: "8px 12px" }}
            >
              {currencyFormatter.format(
                upgradePlan.discount * -1 +
                  upgradePlan.user_discount * usersCount * -1
              )}
            </Descriptions.Item>
          )}

          <Descriptions.Item
            span={24}
            label={t("total_amount_payable")}
            contentStyle={{
              textAlign: "right",
              fontWeight: "bold",
              padding: "8px 12px",
            }}
            labelStyle={{ padding: "8px 12px" }}
          >
            {currencyFormatter.format(
              (upgradePlan.user_price - upgradePlan.user_discount) *
                usersCount +
                upgradePlan.price -
                upgradePlan.discount
            )}
          </Descriptions.Item>
        </Descriptions>
      </Modal>
    </>
  );
};

export default YearlySubscriptions;
