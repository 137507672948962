import React, { useContext, useEffect, useState } from "react";
import {
  Row,
  Col,
  Button,
  Modal,
  Form,
  Input,
  Tag,
  Spin,
  Popconfirm,
  Select,
  Card,
} from "antd";
import { PageHeader } from "@ant-design/pro-layout";
import { ApiOutlined, DeleteTwoTone, HomeOutlined } from "@ant-design/icons";
import CustomBreadcrumb from "../../../components/CustomBreadcrumb";
import { useTranslation } from "react-i18next";
import {
  getAllExternalIntegrationTypes,
  creatExternalIntegration,
  testExternalIntegration,
  deleteExternalIntegration,
  getAllExternalIntegration,
  getMyProfile,
} from "../../../network/network";
import NotificationWithIcon from "../../../components/NotificationWithIcon";
import { AppContext } from "../../../context/AppContextProvider";
import { ODOO } from "../../../configs/constants";

const ExternalIntegration = () => {
  const { setAuthUser } = useContext(AppContext);
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [loadingBTN, setLoadingBTN] = useState(false);
  const [externalIntegrationTypes, setExternalIntegrationTypes] = useState([]);
  const [externalIntegrations, setExternalIntegrations] = useState([]);
  const [reloadData, setReloadData] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const { t } = useTranslation();
  const [addEIForm] = Form.useForm();

  // get all  external integration types
  useEffect(() => {
    // Set Loading True
    setIsDataLoading(true);
    getAllExternalIntegrationTypes()
      .then((response) => {
        setExternalIntegrationTypes(
          response.data.data.externalIntegrationsTypes
        );
      })
      .finally(() => {
        setIsDataLoading(false);
      });
  }, []);
  // get all  external integration
  useEffect(() => {
    // Set Loading True
    setIsDataLoading(true);
    getAllExternalIntegration()
      .then((response) => {
        setExternalIntegrations(response.data.data.externalIntegrations);
      })
      .finally(() => {
        setIsDataLoading(false);
      });
  }, [reloadData]);

  const breadcrumbRoutes = [
    {
      path: "/",
      breadcrumbName: t("menu_home"),
      icon: <HomeOutlined />,
    },
    {
      path: "external-integration",
      breadcrumbName: t("menu_external_integration"),
      icon: <ApiOutlined />,
    },
  ];

  const showModal = (data) => {
    // Show Modal
    setModalVisible(true);
    // Set Modal Title
    setModalTitle(t("connection_add"));
    // Reset Form Fields
    addEIForm.resetFields();
  };

  const handleCancel = () => {
    // hide Modal
    setModalVisible(false);
    // Set Modal Title
    setModalTitle("");
    // Reset Form Fields
    addEIForm.resetFields();
  };

  // test external intergration connection
  const handleTestConnection = () => {
    addEIForm.validateFields().then((values) => {
      setLoadingBTN(true);
      testExternalIntegration(values)
        .then((response) => {
          let data = response.data || response.response.data;
          if (data.success === true) {
            NotificationWithIcon(
              "success",
              t("connection_tested_successfully")
            );
          } else {
            // Error
            NotificationWithIcon("error", t("connection_tested_failed"));
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          // Set Loading False
          setLoadingBTN(false);
        });
    });
  };
  // save external intergration connection
  const handleSaveConnection = () => {
    addEIForm.validateFields().then((values) => {
      setLoadingBTN(true);
      creatExternalIntegration(values)
        .then((response) => {
          let data = response.data || response.response.data;
          if (data.success === true) {
            NotificationWithIcon("success", t("connection_saved_successfully"));
            // reload data
            setReloadData(!reloadData);
            // Hide Modal
            setModalVisible(false);
            // recall authuser for set fresh data
            refreshAuthUser();
          } else {
            // Error
            NotificationWithIcon("error", t("connection_saved_failed"));
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          // Set Loading False
          setLoadingBTN(false);
        });
    });
  };

  // delete connection
  const handleDelete = (id) => {
    deleteExternalIntegration(id)
      .then((response) => {
        if (response.data.success === true) {
          setReloadData(!reloadData);
          // Success
          NotificationWithIcon(
            "success",
            t("connection_deleted"),
            t("connection_deleted_successfully")
          );
          // recall authuser for set fresh data
          refreshAuthUser();
        } else {
          // Error
          NotificationWithIcon(
            "error",
            t("something_went_wrong"),
            response.data.message
          );
        }
      })
      .catch((info) => {
        // Error
        console.log("Delete error: ", info.response.data.message);
        NotificationWithIcon(
          "error",
          t("something_went_wrong"),
          info.response.data.message
        );
      });
  };

  // refreshAuthUser
  const refreshAuthUser = () => {
    getMyProfile().then((response) => {
      setAuthUser(response.data.data.user);
    });
  };

  return (
    <div>
      <>
        <Card style={{ marginBottom: "20px" }}>
          <CustomBreadcrumb menus={breadcrumbRoutes} />
          <PageHeader
            style={{ padding: "0" }}
            title={t("menu_external_integration")}
            ghost={false}
            extra={[
              externalIntegrationTypes.length > 0 && (
                <Button
                  key="add-external-integration"
                  type="primary"
                  onClick={showModal}
                >
                  + {t("connection_add")}
                </Button>
              ),
            ]}
          />
        </Card>
        <Spin spinning={isDataLoading}>
          <Row>
            {externalIntegrations.map((item) => (
              <Col xs={24} sm={12} md={8} lg={6} xl={6} xxl={6} key={item.id}>
                <div
                  style={{
                    backgroundColor: "#ffffff",
                    padding: "20px",
                    textAlign: "center",
                    boxShadow: "5px 5px 10px -5px hwb(0deg 66% 34%)",
                    margin: "10px",
                  }}
                >
                  <img
                    alt={item.external_integration_type.name}
                    src={
                      item.external_integration_type.name === ODOO
                        ? "/images/odoo-logo.png"
                        : ""
                    }
                  />
                  <div
                    style={{
                      paddingTop: "10px",
                      paddingBottom: "10px",
                    }}
                  >
                    {item.secret_key ? (
                      <Tag color="green"> {t("connected")} </Tag>
                    ) : (
                      <Tag color="red"> {t("disconnected")} </Tag>
                    )}
                  </div>

                  <Popconfirm
                    title={t("warning_delete")}
                    onConfirm={() => handleDelete(item.id)}
                    okText={t("yes")}
                    cancelText={t("no")}
                  >
                    <Button danger>
                      <DeleteTwoTone
                        style={{ fontSize: "18px", display: "flex" }}
                        twoToneColor="#ffffff"
                        title={t("delete_entity")}
                      />
                    </Button>
                  </Popconfirm>
                </div>
              </Col>
            ))}
          </Row>
        </Spin>
      </>
      <Modal
        open={modalVisible}
        title={modalTitle}
        onCancel={handleCancel}
        footer={[
          <Button key="back" onClick={handleCancel} loading={loadingBTN}>
            {t("cancel")}
          </Button>,
          <Button
            key="test_connection"
            type="primary"
            loading={loadingBTN}
            onClick={handleTestConnection}
          >
            {t("connection_test")}
          </Button>,
          <Button
            key="save_connection"
            type="primary"
            loading={loadingBTN}
            onClick={handleSaveConnection}
          >
            {t("connection_save")}
          </Button>,
        ]}
      >
        <Form
          name="add-connection-form"
          layout="vertical"
          style={{ width: "100%", margin: "0" }}
          form={addEIForm}
        >
          <Form.Item
            name="url"
            label={t("url")}
            rules={[
              {
                required: true,
                message: t("validate_url_required"),
              },
              {
                type: "url",
                message: t("validate_url_invalid"),
              },
            ]}
            hasFeedback
          >
            <Input autoComplete="false" />
          </Form.Item>
          <Form.Item
            name="secret_key"
            label={t("secret_key")}
            rules={[
              {
                required: true,
                message: t("validate_secret_key_required"),
              },
            ]}
            hasFeedback
          >
            <Input autoComplete="false" />
          </Form.Item>
          <Form.Item
            name="external_integration_type_id"
            label={t("integration_type")}
            rules={[
              {
                required: true,
                message: t("validate_integration_type_required"),
              },
            ]}
            hasFeedback
          >
            <Select>
              {externalIntegrationTypes.map((type) => {
                return (
                  <Select.Option key={type.id} value={type.id}>
                    {type.name}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
          <Form.Item name="id" style={{ display: "none" }}>
            <input type="hidden" />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default ExternalIntegration;
