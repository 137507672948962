import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import trans_en_GB from "./translations/en";
import trans_ar_EG from "./translations/ar";

i18n.use(initReactI18next).init({
  resources: {
    en: {
      translation: trans_en_GB,
    },
    ar: {
      translation: trans_ar_EG,
    },
  },
  lng: "en",
  fallbackLng: "en",
  debug: false,

  interpolation: {
    escapeValue: false, // not needed for react as it escapes by default
  },
});

export default i18n;
