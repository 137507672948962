import React, { useEffect, useState, useContext, useRef } from "react";
import {
  Card,
  Table,
  Tag,
  Modal,
  Form,
  DatePicker,
  Input,
  Select,
  Button,
} from "antd";
import { EyeTwoTone } from "@ant-design/icons";
import { getCustomerTransactions, getBranches } from "../../../network/network";
import { AppContext } from "../../../context/AppContextProvider";
import CurrencyFormatter from "../../../components/CurrencyFormatter";
import {
  TRANSACTION_TYPE_CASH,
  transactionTypes,
  USER_TYPE_BUSINESS_OWNER,
  TRANSACTION_TYPE_CREDIT,
  TRANSACTION_TYPE_MULTI,
} from "../../../configs/constants";
import moment from "moment";
import { useTranslation } from "react-i18next";
import TransactionSlip from "../../../components/TransactionSlip/TransactionSlip";
import ReactToPrint from "react-to-print";
import { useParams } from "react-router-dom";

const CustomerTransactions = () => {
  const { authUser } = useContext(AppContext);
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [transactionsTableData, setTransactionsTableData] = useState([]);
  const [transactionsTablePagination, setTransactionsTablePagination] =
    useState({
      current: 1,
      pageSize: 15,
      total: 0,
    });

  const [transaction, setTransaction] = useState({});
  const [searchParams, setSearchParams] = useState([]);
  const [slipModalVisible, setSlipModalVisible] = useState(false);
  const [cart, setCart] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [branchesData, setBranchesData] = useState([]);
  const [searchFormRef] = Form.useForm();
  const transactionSlipRef = useRef();
  const { t } = useTranslation();
  const { RangePicker } = DatePicker;
  let { customerId } = useParams();

  useEffect(() => {
    setIsDataLoading(true);
    getCustomerTransactions(customerId, {
      ...searchParams,
      page: currentPage,
    })
      .then((response) => {
        setTransactionsTableData(response.data.data.transactions);
        setTransactionsTablePagination(response.data.data.pagination);
      })
      .finally(() => {
        setIsDataLoading(false);
      });
  }, [searchParams, currentPage, customerId]);

  useEffect(() => {
    let total = 0;

    let subtotal = 0;
    let productPrice = 0;
    cart.forEach((product) => {
      transaction.discount
        ? (productPrice =
            product.price -
            product.price * (transaction.discount.discount_percentage / 100))
        : (productPrice = product.price);

      subtotal = productPrice * product.quantity;
      total = total + subtotal;
    });
  }, [cart, transaction.discount]);

  useEffect(() => {
    if (transaction && transaction.items && transaction.items.length > 0) {
      setCart(JSON.parse(JSON.stringify(transaction.items)));
    }
  }, [transaction]);

  useEffect(() => {
    setIsDataLoading(true);
    getBranches()
      .then((response) => {
        setBranchesData(response.data.data.branches);
      })
      .finally(() => {
        setIsDataLoading(false);
      });
  }, []);

  const showSlipModal = (transaction) => {
    setTransaction(transaction);
    setSlipModalVisible(true);
  };

  const hideSlipModal = () => {
    setSlipModalVisible(false);
    setTransaction({});
  };

  const getTransactionsType = (type) => {
    if (type === TRANSACTION_TYPE_CASH) {
      return <Tag color="green">{t("cash")}</Tag>;
    } else if (type === TRANSACTION_TYPE_CREDIT) {
      return <Tag color="blue">{t("credit_card")}</Tag>;
    } else if (type === TRANSACTION_TYPE_MULTI) {
      return <Tag color="gold">{t("multi_payment")}</Tag>;
    }
  };

  // firstPartCol Columns
  const firstPartCol = [
    {
      title: t("col_transaction_id"),
      dataIndex: "uid",
      render: (uid) => {
        return <p>{uid}</p>;
      },
    },
    {
      title: t("col_amount"),
      render: (record) => {
        return (
          <>
            {record.is_multipayment ? (
              record.multipayments.map((item, i) => {
                return (
                  <p key={i}>
                    {item.transaction_type}: {item.amount}
                  </p>
                );
              })
            ) : (
              <p>
                <CurrencyFormatter value={record.amount_charged} />
              </p>
            )}
          </>
        );
      },
    },
    {
      title: t("col_tax"),
      dataIndex: "tax",
      render: (tax) => {
        return (
          <p>
            <CurrencyFormatter value={tax} />
          </p>
        );
      },
    },
  ];

  // Branch Columns
  const branchCol = [
    {
      title: t("branch"),
      dataIndex: "branch",
      render: (branch) => {
        return <p>{branch}</p>;
      },
    },
  ];
  // secondPartCol Columns
  const secondPartCol = [
    {
      title: t("col_type"),
      dataIndex: "type",
      render: (type) => {
        return <p>{getTransactionsType(type)}</p>;
      },
    },
    {
      title: t("col_status"),
      render: (transaction) => {
        return (
          <p>
            {transaction.reference_transaction ? (
              <>
                <Tag color="error">{t("status_refunded")}</Tag>
                <br />
                Ref: <strong>{transaction.reference_transaction.uid}</strong>
              </>
            ) : (
              <Tag color="success">{t("status_paid")}</Tag>
            )}
          </p>
        );
      },
    },
    {
      title: `${t("col_created_at")} / ${t("col_created_by")}`,
      render: (record) => {
        return (
          <>
            <p>{moment(record.created_at).format("MMM. D, YYYY hh:mm a")}</p>
            {record.user && <Tag color="cyan">{record.user}</Tag>}
          </>
        );
      },
    },
    {
      title: t("col_action"),
      render: (record) => {
        return (
          <>
            <Button
              type="link"
              style={{ padding: 2 }}
              onClick={() => showSlipModal(record)}
            >
              <EyeTwoTone
                twoToneColor="#0000ff"
                title="View Details"
                style={{ fontSize: "20px" }}
              />
            </Button>
          </>
        );
      },
    },
  ];

  const handleSearch = (values) => {
    let search_params = false;
    if (values.dates) {
      values.dates[0].set({
        hour: 0,
        minute: 0,
        second: 1,
        millisecond: 0,
      });
      values.dates[1].set({
        hour: 23,
        minute: 59,
        second: 59,
        millisecond: 0,
      });

      let startDateUtc = new moment(values.dates[0]).utc();
      let endDateUtc = new moment(values.dates[1]).utc();

      search_params = {
        search_start_date: startDateUtc.format(),
        search_end_date: endDateUtc.format(),
      };
    }
    if (values.type) {
      search_params = {
        type: values.type,
        ...search_params,
      };
    }
    if (values.is_refunded === 0 || values.is_refunded) {
      search_params = {
        is_refunded: values.is_refunded,
        ...search_params,
      };
    }
    if (values.filter_by_branch) {
      search_params = {
        filter_by_branch: values.filter_by_branch,
        ...search_params,
      };
    }

    if (search_params) {
      setSearchParams(search_params);
    } else {
      setSearchParams([]);
    }
  };

  const handleReset = () => {
    // Reset Form Fields
    searchFormRef.resetFields();
    // Reset Transactions List
    setSearchParams([]);
  };

  const searchForm = (
    <>
      <Form
        name="search-form"
        layout="inline"
        onFinish={handleSearch}
        form={searchFormRef}
      >
        <Form.Item name="dates" label={t("date_range")}>
          <RangePicker allowClear format="DD/MM/YYYY" />
        </Form.Item>
        <Form.Item name="filter_by_branch" label={t("branch")}>
          <Select
            allowClear
            style={{ width: "100px" }}
            options={branchesData.map((branch) => {
              return {
                label: branch.name,
                value: branch.name,
              };
            })}
          ></Select>
        </Form.Item>
        <Form.Item name="type" label={t("col_type")}>
          <Select
            allowClear
            style={{ width: "100px" }}
            options={transactionTypes.map((type) => {
              return {
                label: type.text,
                value: type.id,
              };
            })}
          ></Select>
        </Form.Item>

        <Form.Item name="is_refunded" label={t("is_refunded")}>
          <Select
            allowClear
            style={{ width: "100px" }}
            options={[
              {
                label: t("yes"),
                value: 1,
              },
              {
                label: t("no"),
                value: 0,
              },
            ]}
          ></Select>
        </Form.Item>

        <Form.Item>
          <Input
            type="submit"
            value={t("search")}
            style={{
              backgroundColor: "#6301f2",
              color: "#ffffff",
              width: "80px",
            }}
          />
        </Form.Item>
        <Form.Item>
          <Input type="button" value={t("reset")} onClick={handleReset} />
        </Form.Item>
      </Form>
    </>
  );

  const handleTransactionsTableChange = (pagination, filters, sorter) => {
    setTransactionsTablePagination(pagination);
    setCurrentPage(pagination.current);
  };

  return (
    <>
      <Card>{searchForm}</Card>
      <Card>
        <Table
          columns={
            authUser.type === USER_TYPE_BUSINESS_OWNER
              ? [...firstPartCol, ...branchCol, ...secondPartCol]
              : [...firstPartCol, ...secondPartCol]
          }
          dataSource={transactionsTableData}
          loading={isDataLoading}
          rowKey="id"
          pagination={{
            current: transactionsTablePagination.current_page,
            pageSize: transactionsTablePagination.per_page,
            total: transactionsTablePagination.total,
            showSizeChanger: false,
          }}
          onChange={handleTransactionsTableChange}
        />
      </Card>
      <Modal
        open={slipModalVisible}
        title={t("col_transaction_id") + " # " + transaction.uid}
        onCancel={hideSlipModal}
        footer={[
          <ReactToPrint
            trigger={() => (
              <Button key="print" type="primary">
                {t("print")}
              </Button>
            )}
            content={() => transactionSlipRef.current}
            key="1"
          />,
          <Button key="back" onClick={hideSlipModal}>
            {t("back")}
          </Button>,
        ]}
      >
        <TransactionSlip
          ref={transactionSlipRef}
          transaction={transaction}
          shop={{
            name: authUser.business_name,
            vat: authUser.vat,
            logo: authUser.logo,
            is_vat_exempt: authUser.is_vat_exempt,
          }}
        />
      </Modal>
    </>
  );
};

export default CustomerTransactions;
