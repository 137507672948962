import React, { useState } from "react";
import { Button, message } from "antd";
import {
  getCategoryTemplateExport,
  getUnitsTemplateExport,
  getProductTemplateExport,
} from "../network/network";
import { useTranslation } from "react-i18next";

const DownloadImportTemplate = ({ type }) => {
  const [loadingBTN, setLoadingBTN] = useState(false);
  const PRODUCT = "product";
  const CATEGORY = "category";
  const UNIT = "unit";
  const { t } = useTranslation();

  const unitTemplateExport = () => {
    setLoadingBTN(true);
    getUnitsTemplateExport()
      .then((response) => {})
      .finally(() => {
        setLoadingBTN(false);
      });
  };
  const categoryTemplateExport = () => {
    setLoadingBTN(true);
    getCategoryTemplateExport()
      .then((response) => {})
      .finally(() => {
        setLoadingBTN(false);
      });
  };
  const productTemplateExport = () => {
    setLoadingBTN(true);
    getProductTemplateExport()
      .then((response) => {
        if (response && response.request.status === 400) {
          console.log("Handle errors here");
          message.error("First upload units and categories please.");
        } else {
          console.log("File downloaded.");
        }
      })
      .catch((info) => {
        console.log(info.response);
      })
      .finally(() => {
        setLoadingBTN(false);
      });
  };

  return (
    <>
      {type === PRODUCT && (
        <Button
          type="primary"
          onClick={() => {
            productTemplateExport();
          }}
          loading={loadingBTN}
        >
          {t("download_template")}
        </Button>
      )}
      {type === CATEGORY && (
        <Button
          type="primary"
          onClick={() => {
            categoryTemplateExport();
          }}
          loading={loadingBTN}
        >
          {t("download_template")}
        </Button>
      )}
      {type === UNIT && (
        <Button
          type="primary"
          onClick={() => {
            unitTemplateExport();
          }}
          loading={loadingBTN}
        >
          {t("download_template")}
        </Button>
      )}
    </>
  );
};

export default DownloadImportTemplate;
