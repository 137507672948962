import React, { useContext, useEffect, useState } from "react";
import { Button, Card, Col, Modal, Row, Table } from "antd";
import { PageHeader } from "@ant-design/pro-layout";
import { HomeOutlined, BarChartOutlined, EyeTwoTone } from "@ant-design/icons";
import CustomBreadcrumb from "../../../components/CustomBreadcrumb";
import { useTranslation } from "react-i18next";
import { getStock } from "../../../network/network";
import { AppContext } from "../../../context/AppContextProvider";
import CurrencyFormatter from "../../../components/CurrencyFormatter";

const Stock = () => {
  const { appConfig } = useContext(AppContext);

  const [isDataLoading, setIsDataLoading] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [modalData, setModalData] = useState([]);
  const [stockData, setStockData] = useState([]);
  const { t } = useTranslation();

  useEffect(() => {
    setIsDataLoading(false);
    getStock()
      .then((response) => {
        setStockData(response.data.data.Stock);
      })
      .finally(() => {
        setIsDataLoading(false);
      });
  }, []);

  const showStockOverview = (data) => {
    setModalData(data);
    setModalVisible(true);
  };

  const breadcrumbRoutes = [
    {
      path: "/",
      breadcrumbName: t("menu_home"),
      icon: <HomeOutlined />,
    },
    {
      path: "inventory",
      breadcrumbName: t("menu_inventory"),
      icon: <BarChartOutlined />,
    },
  ];

  const tableColumns = [
    {
      title: t("image"),
      width: "90px",
      render: (record) => {
        return (
          record.product && (
            <img
              alt={record.product.name_en}
              src={
                record.product.image
                  ? record.product.image
                  : "/images/image-not-found.png"
              }
              style={{
                width: "50px",
                height: "auto",
                borderRadius: "1rem",
                border: "1px solid #ebebeb",
              }}
            />
          )
        );
      },
    },
    {
      title: t("col_product"),
      render: (record) => {
        return (
          record.product &&
          (appConfig.direction === "ltr"
            ? record.product.name_en
            : record.product.name)
        );
      },
    },
    {
      title: t("available_stock"),
      align: "center",
      dataIndex: "quantity",
      render: (quantity) => {
        return (
          <>
            <div
              style={{
                color: Math.sign(quantity) === 1 ? "#46c35f" : "#fc2c10",
              }}
            >
              {quantity}
            </div>
          </>
        );
      },
    },
    {
      title: t("branch"),
      dataIndex: "branch",
      render: (branch) => {
        return branch.name;
      },
    },
    {
      title: t("col_price"),
      render: (record) => {
        return (
          record.product && <CurrencyFormatter value={record.product.price} />
        );
      },
    },
    {
      title: t("col_category"),
      render: (record) => {
        return (
          record.product &&
          (appConfig.direction === "ltr"
            ? record.product.category
            : record.product.category_ar)
        );
      },
    },
    {
      title: t("action"),
      align: "center",
      width: "100px",
      render: (record) => {
        return (
          <Button
            type="link"
            style={{ padding: 2 }}
            onClick={() => showStockOverview(record)}
          >
            <EyeTwoTone
              twoToneColor="#0000ff"
              title="View Details"
              style={{ fontSize: "20px" }}
            />
          </Button>
        );
      },
    },
  ];

  const tableStockOverview = [
    {
      title: t("branch"),
      dataIndex: "branch_name",
    },
    {
      title: t("available_stock"),
      align: "center",
      dataIndex: "quantity",
      render: (quantity) => {
        return (
          <>
            <div
              style={{
                color: Math.sign(quantity) === 1 ? "#46c35f" : "#fc2c10",
              }}
            >
              {quantity}
            </div>
          </>
        );
      },
    },
  ];

  return (
    <>
      <Card style={{ marginBottom: "20px" }}>
        <CustomBreadcrumb menus={breadcrumbRoutes} />
        <PageHeader
          style={{ padding: "0" }}
          title={t("menu_stock")}
          ghost={false}
        />
      </Card>
      <Card>
        <Table
          columns={tableColumns}
          dataSource={stockData}
          loading={isDataLoading}
          rowKey="id"
          pagination={false}
        />
      </Card>
      <Modal
        open={modalVisible}
        title="Stock Overview"
        onCancel={() => setModalVisible(false)}
        width={1000}
        footer={null}
      >
        <div style={{ backgroundColor: "#f5f5f5", padding: "20px" }}>
          {modalData.length !== 0 && (
            <Row gutter={[20, 20]}>
              <Col
                className="gutter-row"
                xs={24}
                sm={12}
                md={8}
                lg={8}
                xl={6}
                xxl={6}
              >
                <div
                  style={{
                    textAlign: "center",
                    padding: "20px",
                    borderRadius: "0.5rem",
                    backgroundColor: "#ffffff",
                  }}
                >
                  <img
                    alt={modalData.product.name}
                    src={
                      modalData.product.image
                        ? modalData.product.image
                        : "/images/image-not-found.png"
                    }
                    style={{
                      width: "100%",
                      maxWidth: "195px",
                      height: "auto",
                    }}
                  />
                  <h3>
                    {appConfig.direction === "rtl"
                      ? modalData.product.name
                      : modalData.product.name_en}
                  </h3>
                  <p
                    style={{
                      marginBottom: "0",
                    }}
                  >
                    {appConfig.direction === "ltr"
                      ? modalData.product.category
                      : modalData.product.category_ar}
                  </p>
                  <p
                    style={{
                      color: "#6301f2",
                      marginBottom: "0",
                    }}
                  >
                    <CurrencyFormatter value={modalData.product.price} />
                  </p>
                </div>
              </Col>
              <Col
                className="gutter-row"
                xs={24}
                sm={12}
                md={16}
                lg={16}
                xl={18}
                xxl={18}
              >
                <Card>
                  <Table
                    columns={tableStockOverview}
                    dataSource={modalData.branches_stock}
                    loading={isDataLoading}
                    rowKey="id"
                    pagination={false}
                  />
                </Card>
              </Col>
            </Row>
          )}
        </div>
      </Modal>
    </>
  );
};

export default Stock;
