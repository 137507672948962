import React, { useEffect, useState } from "react";
import { Modal, Form, Radio } from "antd";
import { prepareCheckout } from "../network/network";
import {
  PAYMENT_BRAND_VISA,
  PAYMENT_BRAND_MASTER,
  PAYMENT_BRAND_MADA,
} from "../configs/constants";
import { currencyFormatter } from "../utils";
import { useTranslation } from "react-i18next";

const HyperPayPaymentModal = ({
  invoiceId,
  amount,
  paymentModalVisible,
  setPaymentModalVisible,
}) => {
  const [paymentFormLoading, setPaymentFormLoading] = useState(false);
  const [paymentBrand, setPaymentBrand] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    if (paymentBrand && amount && amount > 0 && invoiceId) {
      setPaymentFormLoading(true);
      prepareCheckout({
        invoice_id: invoiceId ? invoiceId : false,
        amount: amount,
        brand: paymentBrand,
      }).then((response) => {
        console.log(response);
        if (response.data.result) {
          const script = document.createElement("script");
          if (process.env.REACT_APP_MODE === "LIVE") {
            script.src = `https://eu-prod.oppwa.com/v1/paymentWidgets.js?checkoutId=${response.data.id}`;
          } else {
            script.src = `https://eu-test.oppwa.com/v1/paymentWidgets.js?checkoutId=${response.data.id}`;
          }
          script.async = true;

          document.body.appendChild(script);

          let brand = "VISA";
          if (paymentBrand === PAYMENT_BRAND_MADA) {
            brand = "MADA";
          } else if (paymentBrand === PAYMENT_BRAND_MASTER) {
            brand = "MASTER";
          }

          const form = document.createElement("form");
          //   form.action = process.env.REACT_APP_ROOT + "/payments";
          form.setAttribute("class", "paymentWidgets");
          form.setAttribute("data-brands", brand);
          document.querySelector("#payment_form").innerHTML = "";
          document.querySelector("#payment_form").appendChild(form);
          setTimeout(function () {
            setPaymentFormLoading(false);
          }, 2000);
        }
      });
    }
  }, [paymentBrand, amount, invoiceId]);

  return (
    <Modal
      title={t("payment_modal")}
      open={paymentModalVisible}
      onCancel={() => setPaymentModalVisible(false)}
      footer={null}
    >
      <h3>
        {t("amount")}: {currencyFormatter.format(amount)}
      </h3>
      <h3>{t("select_payment_method")}:</h3>
      <Form.Item>
        <Radio.Group
          onChange={(e) => setPaymentBrand(e.target.value)}
          size="small"
        >
          <Radio.Button style={{ height: "auto" }} value={PAYMENT_BRAND_MADA}>
            <img src="/images/payment_type_mada.png" alt="Payment Type MADA" />
          </Radio.Button>

          <Radio.Button style={{ height: "auto" }} value={PAYMENT_BRAND_VISA}>
            <img src="/images/payment_brand_visa.png" alt="Payment Type Visa" />
          </Radio.Button>

          <Radio.Button style={{ height: "auto" }} value={PAYMENT_BRAND_MASTER}>
            <img
              src="/images/payment_brand_master.png"
              alt="Payment Type Mastercard"
            />
          </Radio.Button>
        </Radio.Group>
      </Form.Item>

      <div style={{ display: paymentFormLoading ? "flex" : "none" }}>
        <img
          src="/loader.gif"
          alt="Loading..."
          style={{ width: "64px", margin: "20px auto" }}
        />
      </div>
      <div
        id="payment_form"
        style={{ display: !paymentFormLoading ? "block" : "none" }}
      ></div>
    </Modal>
  );
};

export default HyperPayPaymentModal;
