const CurrencyFormatter = ({ value, currencyCode = true }) => {
  let config = {
    style: "currency",
    currency: "SAR",
    maximumFractionDigits: 8,
  };
  if (currencyCode === false) {
    config = { style: "decimal", maximumFractionDigits: 8 };
  }
  return new Intl.NumberFormat("en-US", config).format(value);
};

export default CurrencyFormatter;
