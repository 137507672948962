import { EyeOutlined } from "@ant-design/icons";
import { Button, Table } from "antd";
import { useTranslation } from "react-i18next";

const TicketsTable = ({ tickets, loading }) => {
  const { t } = useTranslation();

  const TicketsTableColumns = [
    {
      title: "#",
      align: "center",
      width: "50px",
      key: "index",
      render: (text, record, index) => {
        return <p>{index + 1}</p>;
      },
    },
    {
      title: t("col_description"),
      dataIndex: "description",
    },
    {
      title: t("col_attachment"),
      dataIndex: "attachment",
      width: "200px",
      render: (attachment) => {
        return (
          <>
            <div>
              {attachment ? (
                <Button
                  type="primary"
                  href={attachment}
                  size="small"
                  target="_blank"
                >
                  <EyeOutlined /> {t("preview") + " " + t("attachment")}
                </Button>
              ) : (
                <div>{t("no_attachment")}</div>
              )}
            </div>
          </>
        );
      },
    },
  ];

  return (
    <>
      <Table
        columns={TicketsTableColumns}
        dataSource={tickets}
        loading={loading}
        rowKey="id"
        pagination={false}
      />
    </>
  );
};

export default TicketsTable;
