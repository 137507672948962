import React, { useContext } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import Home from "../pages/Content/Home/Home";
import POS from "../pages/Content/POS/POS";
import Products from "../pages/Content/Products/Products";
import ProductCategories from "../pages/Content/ProductCategories/ProductCategories";
import ProductUnits from "../pages/Content/ProductUnits/ProductUnits";
import Transactions from "../pages/Content/Transactions/Transactions";
import StoreConfiguration from "../pages/Content/StoreConfiguration/StoreConfiguration";
import Branches from "../pages/Content/Branches/Branches";
import Employees from "../pages/Content/Employees/Employees";
import Helpdesk from "../pages/Content/Helpdesk/Helpdesk";
import SalesSummary from "../pages/Content/Reports/SalesSummary";
import SalesByItems from "../pages/Content/Reports/SalesByItems";
import SalesByCategories from "../pages/Content/Reports/SalesByCategories";
import RefundsByItems from "../pages/Content/Reports/RefundsByItems";
import RefundsByCategories from "../pages/Content/Reports/RefundsByCategories";
import ReportByBranches from "../pages/Content/Reports/ReportByBranches";
import Invoices from "../pages/Content/Invoices/Invoices";
import Devices from "../pages/Content/Devices/Devices";
import Payments from "../pages/Content/Payments/Payments";
import ExternalIntegration from "../pages/Content/ExternalIntegration/ExternalIntegration";
import {
  BarsOutlined,
  BarcodeOutlined,
  HomeOutlined,
  BgColorsOutlined,
  DollarOutlined,
  BarChartOutlined,
  SettingOutlined,
  ShopOutlined,
  WalletOutlined,
  EnvironmentOutlined,
  TeamOutlined,
  AccountBookOutlined,
  DesktopOutlined,
  CustomerServiceOutlined,
  PercentageOutlined,
  ApiOutlined,
  DeploymentUnitOutlined,
  AppstoreOutlined,
  ReconciliationOutlined,
  AlignLeftOutlined,
  ApartmentOutlined,
  AuditOutlined,
} from "@ant-design/icons";
import Subscriptions from "../pages/Content/Subscriptions/Subscriptions";
import { AppContext } from "../context/AppContextProvider";
import { filterPagesByAddons } from "../utils.js";
import Discounts from "../pages/Content/Discounts/Discounts";
import RegisterConfirmed from "../pages/Auth/Register/RegisterConfirmed";
import Addons from "../pages/Content/Addons/Addons";
import AddonDetail from "../pages/Content/Addons/AddonDetail.js";
import Tables from "../pages/Content/Tables/Tables";
import Orders from "../pages/Content/Orders/Orders.js";
import EditOrder from "../pages/Content/Orders/EditOrder.js";
import ViewOrder from "../pages/Content/Orders/ViewOrder.js";
import {
  ADDON_STOCK_MANAGEMENT,
  ADDON_ORDER_MANAGEMENT,
  ADDON_TABLE_MANAGEMENT,
  ADDON_CUSTOMERS_MANAGEMENT,
  ADDON_SALES_INVOICE,
} from "../configs/constants.js";
import Checkout from "../pages/Content/Checkout/Checkout.js";
import Stock from "../pages/Content/Inventory/Stock.js";
import StockAdjustment from "../pages/Content/Inventory/StockAdjustment.js";
import ViewStockAdjustment from "../pages/Content/Inventory/ViewStockAdjustment.js";
import StockTransfer from "../pages/Content/Inventory/StockTransfer.js";
import ViewTransfer from "../pages/Content/Inventory/ViewTransfer.js";
import EditTransfer from "../pages/Content/Inventory/EditTransfer.js";
import Customers from "../pages/Content/Customers/Customers.js";
import ViewCustomer from "../pages/Content/Customers/ViewCustomer.js";
import SalesInvoices from "../pages/Content/SalesInvoices/SalesInvoices.js";
import ViewSalesInvoice from "../pages/Content/SalesInvoices/ViewSalesInvoice.js";
// import ViewSalesInvoice from "../pages/Content/SalesInvoices/ViewSalesInvoice.js";

export const ContentPages = [
  {
    path: "/",
    name: "home",
    text: "menu_home",
    component: Home,
    icon: HomeOutlined,
    showInSider: true,
    addonName: null,
  },
  {
    path: "/pos",
    name: "pos",
    text: "menu_pos",
    component: POS,
    icon: ShopOutlined,
    showInSider: true,
    addonName: null,
  },
  {
    path: "/checkout",
    name: "checkout",
    text: "checkout",
    component: Checkout,
    icon: false,
    showInSider: false,
    addonName: null,
  },
  {
    path: "/customers",
    name: "customers",
    text: "menu_customers",
    component: Customers,
    icon: ApartmentOutlined,
    showInSider: true,
    addonName: ADDON_CUSTOMERS_MANAGEMENT,
  },
  {
    path: "/customers/:customerId",
    name: "view_customer",
    text: "menu_customer_details",
    icon: false,
    component: ViewCustomer,
    showInSider: false,
    addonName: ADDON_CUSTOMERS_MANAGEMENT,
  },
  {
    path: "/orders",
    name: "orders",
    text: "menu_orders",
    component: Orders,
    icon: ReconciliationOutlined,
    showInSider: true,
    addonName: ADDON_ORDER_MANAGEMENT,
  },
  {
    path: "/orders/:orderId",
    name: "view_order",
    text: "menu_view_order",
    component: ViewOrder,
    icon: false,
    showInSider: false,
    addonName: ADDON_ORDER_MANAGEMENT,
  },
  {
    path: "/orders/:orderId/edit",
    name: "edit_order",
    text: "menu_edit_order",
    component: EditOrder,
    icon: false,
    showInSider: false,
    addonName: ADDON_ORDER_MANAGEMENT,
  },
  {
    path: "/transactions",
    name: "transactions",
    text: "menu_transactions",
    component: Transactions,
    icon: DollarOutlined,
    showInSider: true,
    addonName: null,
  },
  {
    name: "products_list",
    text: "menu_products",
    icon: AlignLeftOutlined,
    showInSider: true,
    addonName: null,
    subMenus: [
      {
        path: "/products",
        name: "products",
        text: "menu_products_list",
        component: Products,
        icon: BarcodeOutlined,
        showInSider: true,
        addonName: null,
      },
      {
        path: "/categories",
        name: "categories",
        text: "menu_categories",
        component: ProductCategories,
        icon: BarsOutlined,
        showInSider: true,
        addonName: null,
      },
      {
        path: "/units",
        name: "units",
        text: "menu_units",
        component: ProductUnits,
        icon: BgColorsOutlined,
        showInSider: true,
        addonName: null,
      },
    ],
  },
  {
    name: "inventory",
    text: "menu_inventory",
    icon: BarChartOutlined,
    showInSider: true,
    addonName: ADDON_STOCK_MANAGEMENT,
    subMenus: [
      {
        path: "/inventory/stock",
        name: "stock",
        text: "menu_stock",
        component: Stock,
        icon: SettingOutlined,
        showInSider: true,
        addonName: ADDON_STOCK_MANAGEMENT,
      },
      {
        path: "/inventory/stock-adjustment",
        name: "stock-adjustment",
        text: "menu_stock_adjustment",
        component: StockAdjustment,
        icon: SettingOutlined,
        showInSider: true,
        addonName: ADDON_STOCK_MANAGEMENT,
      },
      {
        path: "/inventory/stock-transfer",
        name: "stock-transfer",
        text: "menu_stock_transfer",
        component: StockTransfer,
        icon: SettingOutlined,
        showInSider: true,
        addonName: ADDON_STOCK_MANAGEMENT,
      },
    ],
  },
  {
    path: "/inventory/stock-adjustment/:adjustmentId",
    name: "view_stock_adjustment",
    text: "menu_view_stock_adjustment",
    icon: false,
    component: ViewStockAdjustment,
    showInSider: false,
    addonName: ADDON_STOCK_MANAGEMENT,
  },
  {
    path: "/inventory/stock-transfer/:transferId",
    name: "view_transfer",
    text: "menu_view_transfer",
    icon: false,
    component: ViewTransfer,
    showInSider: false,
    addonName: ADDON_STOCK_MANAGEMENT,
  },
  {
    path: "/inventory/stock-transfer/:transferId/edit",
    name: "edit_transfer",
    text: "menu_view_transfer",
    icon: false,
    component: EditTransfer,
    showInSider: false,
    addonName: ADDON_STOCK_MANAGEMENT,
  },
  {
    path: "/branches",
    name: "branches",
    text: "menu_branches",
    component: Branches,
    icon: EnvironmentOutlined,
    showInSider: true,
    addonName: null,
  },
  {
    path: "/tables",
    name: "tables",
    text: "menu_tables",
    component: Tables,
    icon: AppstoreOutlined,
    showInSider: true,
    addonName: ADDON_TABLE_MANAGEMENT,
  },
  {
    path: "/sales-invoice",
    name: "sales_invoice",
    text: "menu_sales_invoice",
    component: SalesInvoices,
    icon: AuditOutlined,
    showInSider: true,
    addonName: ADDON_SALES_INVOICE,
  },
  {
    path: "/sales-invoice/:salesInvoiceId",
    name: "view_sales_invoice",
    text: "menu_view_sales_invoice",
    component: ViewSalesInvoice,
    icon: false,
    showInSider: false,
    addonName: ADDON_SALES_INVOICE,
  },
  {
    path: "/employees",
    name: "employees",
    text: "menu_employees",
    component: Employees,
    icon: TeamOutlined,
    showInSider: true,
    addonName: null,
  },
  {
    path: "/discounts",
    name: "discounts",
    text: "menu_discounts",
    component: Discounts,
    icon: PercentageOutlined,
    showInSider: true,
    addonName: null,
  },
  {
    name: "reports",
    text: "menu_reports",
    icon: BarChartOutlined,
    showInSider: true,
    addonName: null,
    subMenus: [
      {
        path: "/reports/sales-summary",
        name: "sales-summary",
        text: "menu_sales_summary",
        component: SalesSummary,
        icon: SettingOutlined,
        showInSider: true,
        addonName: null,
      },
      {
        path: "/reports/sales-by-items",
        name: "sales-by-items",
        text: "menu_sales_by_items",
        component: SalesByItems,
        icon: SettingOutlined,
        showInSider: true,
        addonName: null,
      },
      {
        path: "/reports/sales-by-categories",
        name: "sales-by-categories",
        text: "menu_sales_by_categories",
        component: SalesByCategories,
        icon: SettingOutlined,
        showInSider: true,
        addonName: null,
      },
      {
        path: "/reports/refunds-by-items",
        name: "refunds-by-items",
        text: "menu_refunds_by_items",
        component: RefundsByItems,
        icon: SettingOutlined,
        showInSider: true,
        addonName: null,
      },
      {
        path: "/reports/refunds-by-categories",
        name: "refunds-by-categories",
        text: "menu_refunds_by_categories",
        component: RefundsByCategories,
        icon: SettingOutlined,
        showInSider: true,
        addonName: null,
      },
      {
        path: "/reports/report-by-branch",
        name: "report-by-branch",
        text: "menu_report_by_branch",
        component: ReportByBranches,
        icon: SettingOutlined,
        showInSider: true,
        addonName: null,
      },
    ],
  },
  {
    path: "/helpdesk",
    name: "helpdesk",
    text: "menu_help_desk",
    component: Helpdesk,
    icon: CustomerServiceOutlined,
    showInSider: true,
    addonName: null,
  },
  {
    path: "/subscriptions",
    name: "subscriptions",
    text: "menu_subscriptions",
    component: Subscriptions,
    icon: WalletOutlined,
    showInSider: true,
    addonName: null,
  },
  {
    path: "/invoices",
    name: "invoices",
    text: "menu_invoices",
    component: Invoices,
    icon: AccountBookOutlined,
    showInSider: true,
    addonName: null,
  },
  {
    path: "/devices",
    name: "devices",
    text: "menu_devices",
    component: Devices,
    icon: DesktopOutlined,
    showInSider: true,
    addonName: null,
  },
  {
    path: "/addons",
    name: "addons",
    text: "menu_addons",
    component: Addons,
    icon: DeploymentUnitOutlined,
    showInSider: true,
    addonName: null,
  },
  {
    path: "/addons/:addonId",
    name: "addon_detail",
    text: "menu_addon_detail",
    component: AddonDetail,
    icon: false,
    showInSider: false,
    addonName: null,
  },
  {
    path: "/external-integration",
    name: "external-integration",
    text: "menu_external_integration",
    component: ExternalIntegration,
    icon: ApiOutlined,
    showInSider: true,
    addonName: null,
  },
  {
    path: "/store-configuration",
    name: "store-configuration",
    text: "menu_store_configuration",
    component: StoreConfiguration,
    icon: SettingOutlined,
    showInSider: true,
    addonName: null,
  },
  {
    path: "/payments",
    name: "payments",
    text: "",
    component: Payments,
    icon: DesktopOutlined,
    showInSider: false,
    addonName: null,
  },
  {
    path: "/register-confirmed",
    name: "register-confirmed",
    text: "register_confirmed",
    component: RegisterConfirmed,
    icon: false,
    showInSider: false,
    addonName: null,
  },
];

const AppRoutes = () => {
  const { authUser } = useContext(AppContext);

  return (
    <>
      <Routes>
        <Route exact path="/home" element={<Navigate to="/" replace />} />
        <Route path="/" element={<Home />} />

        {filterPagesByAddons(ContentPages, authUser).map(function (page, i) {
          if (page.component) {
            return (
              <Route
                exact
                path={page.path}
                name={page.name}
                element={<page.component />}
                key={page.name}
              />
            );
          } else {
            let subRoutes =
              page.subMenus &&
              filterPagesByAddons(page.subMenus, authUser).map(function (
                subPage,
                i
              ) {
                if (subPage.component) {
                  return (
                    <Route
                      exact
                      path={subPage.path}
                      name={subPage.name}
                      element={<subPage.component />}
                      key={subPage.name}
                    />
                  );
                }
                return false;
              });
            return subRoutes;
          }
        })}
      </Routes>
    </>
  );
};

export default AppRoutes;
