import React, { useEffect, useState } from "react";
import { DatePicker, Form, Input, Card, Table, Button } from "antd";
import { PageHeader } from "@ant-design/pro-layout";
import { HomeOutlined, BarChartOutlined } from "@ant-design/icons";
import { getRefundsByItems } from "../../../network/network";
import CustomBreadcrumb from "../../../components/CustomBreadcrumb";
import { currencyFormatter } from "../../../utils";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { CSVLink } from "react-csv";

const RefundsByItems = () => {
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [reportTableData, setReportTableData] = useState([]);
  const [searchParams, setSearchParams] = useState([]);
  const [totalAmont, setTotalAmont] = useState([]);
  const { t } = useTranslation();
  const [searchFormRef] = Form.useForm();
  const { RangePicker } = DatePicker;

  useEffect(() => {
    setIsDataLoading(true);
    getRefundsByItems(searchParams).then((response) => {
      setReportTableData(response.data.data.refunds_by_items);
      setIsDataLoading(false);
    });
  }, [searchParams]);

  useEffect(() => {
    let total = 0;
    reportTableData.forEach(({ net_amount }) => {
      total = total + net_amount;
    });
    setTotalAmont(currencyFormatter.format(total));
  }, [reportTableData]);

  const breadcrumbRoutes = [
    {
      path: "/",
      breadcrumbName: t("menu_home"),
      icon: <HomeOutlined />,
    },
    {
      path: null,
      breadcrumbName: t("menu_reports") + " / " + t("menu_refunds_by_items"),
      icon: <BarChartOutlined />,
    },
  ];

  const reportTableColumns = [
    {
      title: t("col_product"),
      dataIndex: "name",
    },
    {
      title: t("col_barcode"),
      dataIndex: "barcode",
    },
    {
      title: t("col_category"),
      dataIndex: "category",
    },
    {
      title: t("col_unit"),
      dataIndex: "unit",
    },
    {
      title: t("col_refund_quantities"),
      dataIndex: "quantity_sold",
    },
    {
      title: t("col_gross_refund"),
      render: (record) => {
        return currencyFormatter.format(record.net_amount);
      },
    },
  ];

  const handleSearch = (values) => {
    let search_params = false;
    if (values.dates) {
      values.dates[0].set({
        hour: 0,
        minute: 0,
        second: 1,
        millisecond: 0,
      });
      values.dates[1].set({
        hour: 23,
        minute: 59,
        second: 59,
        millisecond: 0,
      });

      let startDateUtc = new moment(values.dates[0]).utc();
      let endDateUtc = new moment(values.dates[1]).utc();

      search_params = {
        search_start_date: startDateUtc.format(),
        search_end_date: endDateUtc.format(),
      };
    }
    if (search_params) {
      setSearchParams(search_params);
    } else {
      setSearchParams([]);
    }
  };
  const handleReset = () => {
    // Reset Form Fields
    searchFormRef.resetFields();
    // Reset Transactions List
    setSearchParams([]);
  };

  const searchForm = (
    <>
      <Form
        name="search-report-form"
        layout="inline"
        onFinish={handleSearch}
        form={searchFormRef}
      >
        <Form.Item name="dates" label={t("date_range")}>
          <RangePicker allowClear format="DD/MM/YYYY" />
        </Form.Item>
        <Form.Item>
          <Input
            type="submit"
            value={t("search")}
            style={{
              backgroundColor: "#6301f2",
              color: "#ffffff",
              width: "80px",
            }}
          />
        </Form.Item>
        <Form.Item>
          <Input type="button" value={t("reset")} onClick={handleReset} />
        </Form.Item>
      </Form>
    </>
  );

  const csvData = [
    [
      "Product",
      "Barcode",
      "Category",
      "Unit",
      "Refund Quantities",
      "Gross Refunds",
    ],
    ...reportTableData.map(
      ({ name, barcode, category, unit, quantity_sold, net_amount }) => [
        name,
        barcode,
        category,
        unit,
        quantity_sold,
        currencyFormatter.format(net_amount),
      ]
    ),
    [],
    ["", "", "", "", "Total Refunds", totalAmont],
  ];

  return (
    <>
      <Card style={{ marginBottom: "20px" }}>
        <CustomBreadcrumb menus={breadcrumbRoutes} />
        <PageHeader
          style={{ padding: "0" }}
          title={t("menu_refunds_by_items")}
          breadcrumb={CustomBreadcrumb}
          ghost={false}
          footer={searchForm}
          extra={[
            <Button type="primary" key="download-report">
              <CSVLink
                data={csvData}
                filename={
                  "Refund by items report" +
                  " " +
                  moment().format("MM-DD-YYYY HH-mm") +
                  ".csv"
                }
                target="_blank"
              >
                {t("download_report")}
              </CSVLink>
            </Button>,
          ]}
        />
      </Card>
      <Card>
        <Table
          bordered={true}
          columns={reportTableColumns}
          dataSource={reportTableData}
          loading={isDataLoading}
          rowKey="name"
          pagination={false}
          summary={(pageData) => {
            let totalRefunds = 0;

            pageData.forEach(({ net_amount }) => {
              totalRefunds = totalRefunds + net_amount;
            });

            return (
              <>
                <Table.Summary.Row
                  style={{ backgroundColor: "#fafafa", fontWeight: "bold" }}
                >
                  <Table.Summary.Cell colSpan={5}>
                    {t("total_refunds")}
                  </Table.Summary.Cell>
                  <Table.Summary.Cell>
                    {currencyFormatter.format(totalRefunds)}
                  </Table.Summary.Cell>
                </Table.Summary.Row>
              </>
            );
          }}
        />
      </Card>
    </>
  );
};

export default RefundsByItems;
