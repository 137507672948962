import React, { useContext, useEffect, useState } from "react";
import { Button, Col, Drawer, Menu, message, Modal, Row, Tag } from "antd";
import { EyeOutlined, ShoppingCartOutlined } from "@ant-design/icons";
import CurrencyFormatter from "../../../components/CurrencyFormatter";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { AppContext } from "../../../context/AppContextProvider";
import { getQRproducts } from "../../../network/network";
import {
  TRANSACTION_STATUS_INPROGRESS,
  TRANSACTION_STATUS_PENDING,
} from "../../../configs/constants";

const QRorderHistory = () => {
  const { appConfig, setAppConfigWrapper } = useContext(AppContext);
  const [backLink, setBackLink] = useState([]);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [companyDetails, setCompanyDetails] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [modalData, setModalData] = useState([]);
  const { t } = useTranslation();
  let navigate = useNavigate();

  useEffect(() => {
    if (
      localStorage.getItem("src") &&
      localStorage.getItem("table") &&
      localStorage.getItem("is_drive_thru")
    ) {
      getQRproducts({
        src: localStorage.getItem("src"),
        table: localStorage.getItem("table"),
      })
        .then((response) => {
          let data = response.data || response.response.data;
          if (data.success === true) {
            setCompanyDetails(response.data.data);
          } else {
            // Error
            message.error(data.message);
          }
        })
        .finally(() => {});
    }
  }, []);

  useEffect(() => {
    if (
      localStorage.getItem("src") &&
      localStorage.getItem("table") &&
      localStorage.getItem("is_drive_thru")
    ) {
      setBackLink(
        "/qr/ordering?src=" +
          localStorage.getItem("src") +
          "&table=" +
          localStorage.getItem("table") +
          "&is_drive_thru=" +
          localStorage.getItem("is_drive_thru")
      );
    }
  }, []);

  useEffect(() => {
    if (companyDetails.orders?.length === 0) {
      navigate(backLink);
    }
  }, [companyDetails.orders, navigate, backLink]);

  return (
    <div>
      {companyDetails.orders?.length > 0 && (
        <div style={{ direction: appConfig.direction }}>
          {/* header start */}
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              columnGap: "10px",
              boxShadow: "rgba(119, 128, 161, 0.15) 5px 10px 80px 0px",
              padding: "10px",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                columnGap: "10px",
              }}
            >
              <img
                alt={companyDetails.company_name}
                src={
                  companyDetails.logo
                    ? companyDetails.logo
                    : "/images/image-not-found.png"
                }
                style={{
                  width: "100%",
                  maxWidth: "40px",
                  height: "auto",
                  overflow: "hidden",
                  borderRadius: "35rem",
                }}
              />
              <div>
                <div
                  style={{
                    fontSize: "12px",
                    color: "#9d9d9d",
                    lineHeight: "1",
                  }}
                >
                  {t("welcome_to")}
                </div>
                <div
                  style={{
                    fontSize: "14px",
                    fontWeight: "600",
                    textTransform: "capitalize",
                    maxWidth: "150px",
                    lineHeight: "1",
                    marginTop: "3px",
                  }}
                >
                  {companyDetails.company_name}
                </div>
              </div>
            </div>

            <div style={{ display: "flex", columnGap: "10px" }}>
              <Button
                type="primary"
                onClick={() => {
                  setDrawerVisible(true);
                }}
                style={{ padding: "0", width: "40px", height: "40px" }}
              >
                {appConfig.direction === "rtl" ? "عربي" : "En"}
              </Button>
            </div>
          </div>
          <div
            style={{
              padding: "10px",
            }}
          >
            <h3>{t("your_order")}</h3>
            <Row style={{ paddingBottom: "60px" }}>
              {companyDetails.orders.map((order) => (
                <Col
                  xs={24}
                  sm={12}
                  md={12}
                  lg={8}
                  xl={6}
                  xxl={4}
                  key={order.id}
                >
                  <div
                    style={{
                      padding: "10px",
                      borderBottom: "1px solid #ebebeb",
                      cursor: "pointer",
                      backgroundColor: "#ffffff",
                    }}
                  >
                    <div style={{ display: "flex", columnGap: "10px" }}>
                      <img
                        src="/icons/food-bag.svg"
                        alt="food bag"
                        style={{
                          width: "90px",
                          height: "auto",
                          cursor: "pointer",
                          borderRadius: "1rem",
                          border: "1px solid #ebebeb",
                        }}
                      />

                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "space-between",
                          width: "calc(100% - 90px)",
                        }}
                      >
                        <div
                          style={{
                            fontSize: "12px",
                            fontWeight: "600",
                          }}
                        >
                          {t("order_id") + " : " + order.order_id}
                        </div>
                        <div
                          style={{
                            fontSize: "12px",
                            fontWeight: "600",
                          }}
                        >
                          {t("amount") + " : "}
                          <CurrencyFormatter value={order.amount_charged} />
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            width: "100%",
                            fontSize: "12px",
                            lineHeight: "1",
                            fontWeight: "600",
                          }}
                        >
                          <div>
                            {t("col_status") + " : " + order.status.name}
                          </div>
                          <Button
                            type="primary"
                            style={{
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              setModalVisible(true);
                              setModalData(order);
                            }}
                          >
                            <EyeOutlined />
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
              ))}
            </Row>
            {/* bottom bar */}
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                columnGap: "10px",
                boxShadow: "1px -2px 4px 0 rgba(0,0,0,.4)",
                padding: "10px",
                position: "fixed",
                bottom: "0",
                left: "0",
                right: "0",
                backgroundColor: "#fff",
              }}
            >
              <Button
                type="primary"
                style={{
                  padding: "0 10px",
                  height: "40px",
                  lineHeight: "1",
                  display: " flex",
                  alignItems: "center",
                  columnGap: "7px",
                }}
                onClick={() => {
                  navigate(backLink);
                }}
              >
                <span>{t("place_new_order")}</span>
                <ShoppingCartOutlined
                  style={{
                    fontSize: "25px",
                    lineHeight: "0",
                    color: "#ffffff",
                  }}
                />
              </Button>
            </div>
          </div>
          {/* Drawer */}
          <Drawer
            title={t("select_language")}
            placement={appConfig.direction === "rtl" ? "left" : "right"}
            onClose={() => {
              setDrawerVisible(false);
            }}
            open={drawerVisible}
            styles={{
              body: { padding: "0" },
            }}
          >
            <Menu selectedKeys={[appConfig.direction]} defaultActiveFirst>
              <Menu.Item
                key="ltr"
                onClick={() => {
                  setAppConfigWrapper({ direction: "ltr" });
                  setDrawerVisible(false);
                }}
              >
                English
              </Menu.Item>
              <Menu.Item
                key="rtl"
                onClick={() => {
                  setAppConfigWrapper({ direction: "rtl" });
                  setDrawerVisible(false);
                }}
              >
                عربي
              </Menu.Item>
            </Menu>
          </Drawer>
        </div>
      )}
      <Modal
        open={modalVisible}
        title={null}
        onOk={() => setModalVisible(false)}
        onCancel={() => setModalVisible(false)}
        footer={null}
      >
        {modalData.items?.length > 0 && (
          <div style={{ fontSize: "12px" }}>
            <div
              style={{
                fontWeight: "bold",
                padding: "5px 0",
              }}
            >
              {t("order_id")}
            </div>
            <div style={{ border: "1px solid #d9d9d9", padding: "5px 10px" }}>
              {modalData.order_id ? modalData.order_id : "-"}
            </div>
            <div
              style={{
                fontWeight: "bold",
                padding: "5px 0",
              }}
            >
              {t("col_status")}
            </div>
            <div style={{ border: "1px solid #d9d9d9", padding: "5px 10px" }}>
              {modalData.status.value === TRANSACTION_STATUS_PENDING && (
                <Tag color="warning">{modalData.status.name}</Tag>
              )}
              {modalData.status.value === TRANSACTION_STATUS_INPROGRESS && (
                <Tag color="blue">{modalData.status.name}</Tag>
              )}
            </div>

            {modalData.items && (
              <>
                <div
                  style={{
                    fontWeight: "bold",
                    padding: "5px 0",
                  }}
                >
                  {t("col_items")}
                </div>
                <div
                  style={{ border: "1px solid #d9d9d9", padding: "5px 10px" }}
                >
                  {modalData.items?.map((item) => (
                    <div key={"product_items_" + item.id}>
                      {appConfig.direction === "rtl" ? (
                        <>
                          {item.quantity} x <b>{item.name}</b>
                        </>
                      ) : (
                        <>
                          {item.quantity} x <b>{item.name_en}</b>
                        </>
                      )}
                    </div>
                  ))}
                </div>

                <div
                  style={{
                    fontWeight: "bold",
                    padding: "5px 0",
                  }}
                >
                  {t("total")}
                </div>
                <div
                  style={{ border: "1px solid #d9d9d9", padding: "5px 10px" }}
                >
                  <CurrencyFormatter value={modalData.amount_charged} />
                </div>
              </>
            )}
          </div>
        )}
      </Modal>
    </div>
  );
};

export default QRorderHistory;
