import React, { useContext } from "react";

import { PLAN_TYPE_PRO, PLAN_TYPE_DAILY } from "../../../configs/constants";
import { AppContext } from "../../../context/AppContextProvider";
import DailySubscriptions from "./DailySubscriptions";
import YearlySubscriptions from "./YearlySubscriptions";

const Subscriptions = () => {
  const { authUser } = useContext(AppContext);

  return (
    <>
      {authUser.subscriptions[0].type === PLAN_TYPE_DAILY && (
        <DailySubscriptions />
      )}
      {authUser.subscriptions[0].type === PLAN_TYPE_PRO && (
        <YearlySubscriptions />
      )}
    </>
  );
};

export default Subscriptions;
