import React, { useContext, useEffect, useState } from "react";
import { Card, Row, Col, Spin } from "antd";
import { PageHeader } from "@ant-design/pro-layout";
import {
  HomeOutlined,
  EyeOutlined,
  ApartmentOutlined,
} from "@ant-design/icons";
import CustomBreadcrumb from "../../../components/CustomBreadcrumb";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { getSingleCustomer } from "../../../network/network";
import { AppContext } from "../../../context/AppContextProvider";
import CustomerTransactions from "./CustomerTransactions";

const ViewCustomer = () => {
  const { appConfig } = useContext(AppContext);
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [tableData, setTableData] = useState([]);
  const { t } = useTranslation();
  let { customerId } = useParams();

  useEffect(() => {
    setIsDataLoading(true);
    getSingleCustomer(customerId)
      .then((response) => {
        setTableData(response.data.data.customer);
      })
      .finally(() => {
        setIsDataLoading(false);
      });
  }, [customerId]);

  const breadcrumbRoutes = [
    {
      path: "/",
      breadcrumbName: t("menu_home"),
      icon: <HomeOutlined />,
    },
    {
      path: "customers",
      breadcrumbName: t("menu_customers"),
      icon: <ApartmentOutlined />,
    },
    {
      path: "",
      breadcrumbName: t("menu_customer_details"),
      icon: <EyeOutlined />,
    },
  ];

  return (
    <>
      <Card style={{ marginBottom: "20px" }}>
        <CustomBreadcrumb menus={breadcrumbRoutes} />
        <PageHeader
          style={{ padding: "0" }}
          title={t("menu_customer_details")}
          onBack={() => window.history.back()}
          ghost={false}
          extra={false}
        />
      </Card>
      <Spin spinning={isDataLoading}>
        <Card style={{ marginBottom: "20px" }}>
          <Row gutter={[20, 20]}>
            <Col
              className="gutter-row"
              xs={24}
              sm={24}
              md={12}
              lg={12}
              xl={8}
              xxl={8}
            >
              <div
                style={{
                  fontWeight: "bold",
                  paddingBottom: "10px",
                }}
              >
                {t("name_ar")}
              </div>
              <div style={{ border: "1px solid #d9d9d9", padding: "10px" }}>
                {tableData.name_ar ? tableData.name_ar : "-"}
              </div>
            </Col>
            <Col
              className="gutter-row"
              xs={24}
              sm={24}
              md={12}
              lg={12}
              xl={8}
              xxl={8}
            >
              <div
                style={{
                  fontWeight: "bold",
                  paddingBottom: "10px",
                }}
              >
                {t("name_en")}
              </div>
              <div style={{ border: "1px solid #d9d9d9", padding: "10px" }}>
                {tableData.name_en ? tableData.name_en : "-"}
              </div>
            </Col>
            <Col
              className="gutter-row"
              xs={24}
              sm={24}
              md={12}
              lg={12}
              xl={8}
              xxl={8}
            >
              <div
                style={{
                  fontWeight: "bold",
                  paddingBottom: "10px",
                }}
              >
                {t("phone_number")}
              </div>
              <div style={{ border: "1px solid #d9d9d9", padding: "10px" }}>
                {tableData.phone ? tableData.phone : "-"}
              </div>
            </Col>
            <Col
              className="gutter-row"
              xs={24}
              sm={24}
              md={12}
              lg={12}
              xl={8}
              xxl={8}
            >
              <div
                style={{
                  fontWeight: "bold",
                  paddingBottom: "10px",
                }}
              >
                {t("type")}
              </div>
              <div style={{ border: "1px solid #d9d9d9", padding: "10px" }}>
                {tableData.user_type ? tableData.user_type : "-"}
              </div>
            </Col>
            {tableData.user_type === "company" && (
              <>
                <Col
                  className="gutter-row"
                  xs={24}
                  sm={24}
                  md={12}
                  lg={12}
                  xl={8}
                  xxl={8}
                >
                  <div
                    style={{
                      fontWeight: "bold",
                      paddingBottom: "10px",
                    }}
                  >
                    {t("vat_number")}
                  </div>
                  <div style={{ border: "1px solid #d9d9d9", padding: "10px" }}>
                    {tableData.vat ? tableData.vat : "-"}
                  </div>
                </Col>
                <Col
                  className="gutter-row"
                  xs={24}
                  sm={24}
                  md={12}
                  lg={12}
                  xl={8}
                  xxl={8}
                >
                  <div
                    style={{
                      fontWeight: "bold",
                      paddingBottom: "10px",
                    }}
                  >
                    {t("cr_number")}
                  </div>
                  <div style={{ border: "1px solid #d9d9d9", padding: "10px" }}>
                    {tableData.cr ? tableData.cr : "-"}
                  </div>
                </Col>
                <Col
                  className="gutter-row"
                  xs={24}
                  sm={24}
                  md={12}
                  lg={12}
                  xl={6}
                  xxl={6}
                >
                  <div
                    style={{
                      fontWeight: "bold",
                      paddingBottom: "10px",
                    }}
                  >
                    {t("country")}
                  </div>
                  <div style={{ border: "1px solid #d9d9d9", padding: "10px" }}>
                    {tableData.country ? tableData.country : "-"}
                  </div>
                </Col>
                <Col
                  className="gutter-row"
                  xs={24}
                  sm={24}
                  md={12}
                  lg={12}
                  xl={6}
                  xxl={6}
                >
                  <div
                    style={{
                      fontWeight: "bold",
                      paddingBottom: "10px",
                    }}
                  >
                    {t("state")}
                  </div>
                  <div style={{ border: "1px solid #d9d9d9", padding: "10px" }}>
                    {tableData.state
                      ? appConfig.direction === "rtl"
                        ? tableData.state?.name_ar
                        : tableData.state?.name_en
                      : "-"}
                  </div>
                </Col>
                <Col
                  className="gutter-row"
                  xs={24}
                  sm={24}
                  md={12}
                  lg={12}
                  xl={6}
                  xxl={6}
                >
                  <div
                    style={{
                      fontWeight: "bold",
                      paddingBottom: "10px",
                    }}
                  >
                    {t("city")}
                  </div>
                  <div style={{ border: "1px solid #d9d9d9", padding: "10px" }}>
                    {tableData.city
                      ? appConfig.direction === "rtl"
                        ? tableData.city?.name_ar
                        : tableData.city?.name_en
                      : "-"}
                  </div>
                </Col>
                <Col
                  className="gutter-row"
                  xs={24}
                  sm={24}
                  md={12}
                  lg={12}
                  xl={6}
                  xxl={6}
                >
                  <div
                    style={{
                      fontWeight: "bold",
                      paddingBottom: "10px",
                    }}
                  >
                    {t("postal_code")}
                  </div>
                  <div style={{ border: "1px solid #d9d9d9", padding: "10px" }}>
                    {tableData.postal_code ? tableData.postal_code : "-"}
                  </div>
                </Col>
                <Col
                  className="gutter-row"
                  xs={24}
                  sm={24}
                  md={12}
                  lg={12}
                  xl={8}
                  xxl={8}
                >
                  <div
                    style={{
                      fontWeight: "bold",
                      paddingBottom: "10px",
                    }}
                  >
                    {t("street")}
                  </div>
                  <div style={{ border: "1px solid #d9d9d9", padding: "10px" }}>
                    {tableData.street ? tableData.street : "-"}
                  </div>
                </Col>
                <Col
                  className="gutter-row"
                  xs={24}
                  sm={24}
                  md={12}
                  lg={12}
                  xl={8}
                  xxl={8}
                >
                  <div
                    style={{
                      fontWeight: "bold",
                      paddingBottom: "10px",
                    }}
                  >
                    {t("building_number")}
                  </div>
                  <div style={{ border: "1px solid #d9d9d9", padding: "10px" }}>
                    {tableData.building_number
                      ? tableData.building_number
                      : "-"}
                  </div>
                </Col>
                <Col
                  className="gutter-row"
                  xs={24}
                  sm={24}
                  md={12}
                  lg={12}
                  xl={8}
                  xxl={8}
                >
                  <div
                    style={{
                      fontWeight: "bold",
                      paddingBottom: "10px",
                    }}
                  >
                    {t("plot_id_number")}
                  </div>
                  <div style={{ border: "1px solid #d9d9d9", padding: "10px" }}>
                    {tableData.plot_id_number ? tableData.plot_id_number : "-"}
                  </div>
                </Col>
              </>
            )}
          </Row>
        </Card>
      </Spin>
      <CustomerTransactions />
    </>
  );
};

export default ViewCustomer;
