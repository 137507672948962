import React, { useState } from "react";
import { Button, Alert } from "antd";
import { WalletOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import TopUpPaymentModal from "../../../components/TopUpPaymentModal";

import moment from "moment";
import { currencyFormatter } from "../../../utils";

const WalletDetails = ({ balance, totalBillingAmount }) => {
  const [paymentModalVisible, setPaymentModalVisible] = useState(false);
  const { t } = useTranslation();

  return (
    <>
      {balance && (
        <>
          <div
            style={{
              textAlign: "center",
              color: "#ffffff",
              backgroundColor: "#6301f2",
              padding: "10px",
            }}
          >
            {t("wallet_details")}
          </div>
          {balance.amount < totalBillingAmount * 3 && (
            <Alert
              message={
                t("your_balance_is_low_please_recharge_minimum") +
                " " +
                totalBillingAmount * 15
              }
              type="warning"
              showIcon
            />
          )}
          <div style={{ backgroundColor: "#fff", padding: "20px" }}>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div>
                {t("available_credit")} <br />{" "}
                <h2>{currencyFormatter.format(balance.amount)}</h2>
              </div>
              <Button
                type="primary"
                onClick={() => {
                  setPaymentModalVisible(true);
                }}
              >
                <WalletOutlined /> {t("topup")}
              </Button>
            </div>
            <div>
              {balance.expiry_date &&
                t("balance_validity") +
                  " : " +
                  moment(balance.expiry_date).format("MMM. D, YYYY")}
            </div>
          </div>
          {paymentModalVisible && (
            <TopUpPaymentModal
              paymentModalVisible={paymentModalVisible}
              setPaymentModalVisible={setPaymentModalVisible}
              requiredBalance={
                balance.amount < totalBillingAmount * 15
                  ? totalBillingAmount * 15 - balance.amount
                  : 0
              }
            />
          )}
        </>
      )}
    </>
  );
};

export default WalletDetails;
