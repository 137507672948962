import React, { useContext, useState } from "react";
import { Row, Col, Button, Modal, Popconfirm } from "antd";

import {
  getMyProfile,
  subscribeAddon,
  unSubscribeAddon,
} from "../../../network/network";
import { useTranslation } from "react-i18next";
import NotificationWithIcon from "../../../components/NotificationWithIcon";
import { AppContext } from "../../../context/AppContextProvider";

const AddonAction = ({ addonData, reloadCallback, buttonSize = "default" }) => {
  const { setAuthUser, authUser } = useContext(AppContext);

  const [loading, setLoading] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [modalData, setModalData] = useState([]);
  const { t } = useTranslation();

  const handleActivate = (id) => {
    setLoading(true);
    subscribeAddon(id)
      .then((response) => {
        let data = response.data || response.response.data;
        if (data.success === true) {
          reloadCallback();
          // Success

          NotificationWithIcon(
            "success",
            t("addon_subscribed"),
            t("addon_subscribed_successfully")
          );
          // recall authuser for set fresh data
          refreshAuthUser();
        } else {
          // Error
          NotificationWithIcon("error", t("something_went_wrong"), data.error);
        }
      })
      .catch((info) => {
        console.log("API Failed:", info.response);
      })
      .finally(() => {
        // Set Loading False
        setLoading(false);
      });
  };

  const handleDeActivate = (id) => {
    setLoading(true);
    unSubscribeAddon(id)
      .then((response) => {
        let data = response.data || response.response.data;
        if (data.success === true) {
          reloadCallback();
          // Success

          NotificationWithIcon(
            "success",
            t("addon_unsubscribed"),
            t("addon_unsubscribed_successfully")
          );
          // recall authuser for set fresh data
          setModalVisible(false);
          refreshAuthUser();
        } else {
          // Error
          NotificationWithIcon("error", t("something_went_wrong"), data.error);
        }
      })
      .catch((info) => {
        console.log("API Failed:", info.response);
      })
      .finally(() => {
        // Set Loading False
        setLoading(false);
      });
  };

  const handleClickUnsubscribe = (data) => {
    if (data.required_by_addons?.length !== 0) {
      let required_active_addon = data.required_by_addons.filter(function (o1) {
        return authUser.active_addons.some(function (o2) {
          return o1.name === o2.name; // return the ones with equal id
        });
      });

      if (required_active_addon.length !== 0) {
        setModalData(data);
        setModalVisible(true);
      } else {
        handleDeActivate(data.id);
      }
    } else {
      handleDeActivate(data.id);
    }
  };

  // refreshAuthUser
  const refreshAuthUser = () => {
    getMyProfile().then((response) => {
      setAuthUser(response.data.data.user);
    });
  };

  return (
    <>
      {addonData && (
        <>
          {addonData.is_subscribed ? (
            <>
              {addonData.required_by_addons?.length !== 0 ? (
                <Button
                  danger
                  type="primary"
                  onClick={() => handleClickUnsubscribe(addonData)}
                  size={buttonSize}
                >
                  {t("unsubscribe")}
                </Button>
              ) : (
                <Popconfirm
                  title={t("warning_unsubscribe_addon")}
                  onConfirm={() => handleClickUnsubscribe(addonData)}
                  okText={t("yes")}
                  cancelText={t("no")}
                >
                  <Button danger type="primary" size={buttonSize}>
                    {t("unsubscribe")}
                  </Button>
                </Popconfirm>
              )}
            </>
          ) : (
            <Button
              type="primary"
              onClick={() => handleActivate(addonData.id)}
              size={buttonSize}
            >
              {t("subscribe")}
            </Button>
          )}
        </>
      )}

      <Modal
        open={modalVisible}
        title={t("unsubscribe")}
        onOk={() => handleDeActivate(modalData.id)}
        onCancel={() => setModalVisible(false)}
        footer={[
          <Button
            key="back"
            loading={loading}
            onClick={() => setModalVisible(false)}
          >
            {t("cancel")}
          </Button>,

          <Popconfirm
            title={t("warning_unsubscribe_addon")}
            onConfirm={() => handleDeActivate(modalData.id)}
            okText={t("yes")}
            cancelText={t("no")}
          >
            <Button key="submit" type="primary" danger loading={loading}>
              {t("unsubscribe")}
            </Button>
          </Popconfirm>,
        ]}
      >
        {modalData?.required_by_addons?.length !== 0 && (
          <>
            If you unsubscribe the {modalData.name} addon These addons are also
            unsubscribe automatically from your account.
            {modalData.required_by_addons?.map((addon) => (
              <Row
                style={{
                  margin: "10px 0 0 0",
                  backgroundColor: "#f0f2f5",
                  padding: "10px",
                }}
                key={addon.id}
              >
                <Col
                  className="gutter-row"
                  xs={24}
                  sm={24}
                  md={24}
                  lg={24}
                  xl={24}
                  key={addon.id}
                >
                  {addon.name}
                </Col>
              </Row>
            ))}
          </>
        )}
      </Modal>
    </>
  );
};

export default AddonAction;
