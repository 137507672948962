import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  Col,
  Form,
  InputNumber,
  Modal,
  Popconfirm,
  Row,
  Select,
  Table,
  message,
} from "antd";
import NotificationWithIcon from "../../../components/NotificationWithIcon";
import { useTranslation } from "react-i18next";
import {
  stockTransfersRequest,
  getBranches,
  getProductStockByBranch,
} from "../../../network/network";
import { AppContext } from "../../../context/AppContextProvider";
import {
  DeleteTwoTone,
  MinusCircleFilled,
  PlusCircleFilled,
} from "@ant-design/icons";

const RequestTransfer = ({ reloadCallback }) => {
  const { appConfig, authUser } = useContext(AppContext);
  const ADD_TRANSFER = "Add Transfer";
  const [modalTitle, setModalTitle] = useState(ADD_TRANSFER);
  const [modalVisible, setModalVisible] = useState(false);
  const [productsList, setProductsList] = useState([]);
  const [adjustmentData, setAdjustmentData] = useState([]);
  const [branchesData, setBranchesData] = useState([]);
  const [loadingBTN, setLoadingBTN] = useState(false);
  const [branchId, setBranchId] = useState(0);
  const { t } = useTranslation();
  const [transferForm] = Form.useForm();

  useEffect(() => {
    branchId &&
      getProductStockByBranch(branchId).then((response) => {
        setProductsList(response.data.data.products);
      });
  }, [branchId]);

  useEffect(() => {
    getBranches().then((response) => {
      setBranchesData(response.data.data.branches);
    });
  }, []);

  const adjustmentReducer = (product, action, quantity) => {
    let updatedAdjustment = JSON.parse(JSON.stringify(adjustmentData));
    let productId = product.product.product_id;
    let indexFound = updatedAdjustment.findIndex(
      (product) => product.product.product_id === productId
    );
    if (action === "add") {
      if (indexFound === -1) {
        product.qty = 1;
        updatedAdjustment.push(product);
      } else {
        message.error(t("product_already_selected"));
      }
    } else if (action === "inc") {
      updatedAdjustment[indexFound].qty++;
    } else if (action === "dec") {
      updatedAdjustment[indexFound].qty--;
    } else if (action === "remove") {
      updatedAdjustment.splice(indexFound, 1);
    } else if (action === "directInput") {
      updatedAdjustment[indexFound].qty = quantity;
    }
    setAdjustmentData(updatedAdjustment);
  };

  const showAddModal = () => {
    // Set Modal Title
    setModalTitle(t("request_transfer"));
    // Show Modal
    setModalVisible(true);
    // set data
    transferForm.setFieldsValue({
      branch_to_id: authUser.branch_id,
    });
  };

  const handleCancel = () => {
    // close modal
    setModalVisible(false);
    // Reset Form Fields
    transferForm.resetFields();
    setAdjustmentData([]);
    setBranchId(0);
  };

  const handleAddTransfer = () => {
    transferForm
      .validateFields()
      .then((values) => {
        // Set Loading True
        setLoadingBTN(true);
        let transactionObj = {
          branch_from_id: values.branch_from_id,
          branch_to_id: values.branch_to_id,
        };
        transactionObj.stocks = adjustmentData.map((item) => ({
          product_id: item.product.product_id
            ? item.product.product_id
            : item.product.id,
          requested_quantity: item.qty,
        }));
        // Sending Request to API
        stockTransfersRequest(transactionObj)
          .then((response) => {
            let data = response.data || response.response.data;
            if (data.success === true) {
              // Success
              NotificationWithIcon(
                "success",
                t("transfer_added"),
                t("transfer_added_successfully")
              );
              reloadCallback();
              // close modal
              setModalVisible(false);
              // Reset Form Fields
              transferForm.resetFields();
              setAdjustmentData([]);
            } else {
              // Error
              let errors = response.response.data.data.errors;
              if (errors && Object.keys(errors).length) {
                NotificationWithIcon(
                  "error",
                  t("something_went_wrong"),
                  errors[Object.keys(errors)[0]]
                );
              } else {
                NotificationWithIcon(
                  "error",
                  t("something_went_wrong"),
                  data.message
                );
              }
            }
          })
          .catch((info) => {
            console.log("API Failed:", info.response);
            // Error
            NotificationWithIcon(
              "error",
              t("something_went_wrong"),
              info.response.data.data.errors.name
            );
          })
          .finally(() => {
            setLoadingBTN(false);
          });
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
  };

  const handleProductSelect = (value) => {
    const product = productsList.find(
      (product) => product.product.id === value
    );
    product.product.product_id = product.product.id;
    adjustmentReducer(product, "add");
    transferForm.setFieldsValue({
      product: "",
    });
  };

  const productTableColumns = [
    {
      title: "#",
      key: "index",
      width: "50px",
      render: (text, record, index) => {
        return <>{index + 1}</>;
      },
    },
    {
      title: t("image"),
      width: "90px",
      render: (record) => {
        return (
          <>
            <img
              alt={record.product.name_en}
              src={
                record.product.image
                  ? record.product.image
                  : "/images/image-not-found.png"
              }
              style={{
                width: "50px",
                height: "auto",
                borderRadius: "1rem",
                border: "1px solid #ebebeb",
              }}
            />
          </>
        );
      },
    },
    {
      title: t("col_product"),
      render: (record) => {
        return (
          <>
            {appConfig.direction === "ltr"
              ? record.product.name_en
              : record.product.name}
          </>
        );
      },
    },
    {
      title: t("available_stock"),
      align: "center",
      dataIndex: "quantity",
    },
    {
      title: t("col_quantity"),
      width: "150px",
      render: (record) => {
        return (
          <>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                columnGap: "10px",
                justifyContent: "space-between",
                backgroundColor: "#e8f0fa",
                borderRadius: "35rem",
                width: "150px",
              }}
            >
              <MinusCircleFilled
                style={{
                  color: record.qty > 1 ? "#6301f2" : "#d9d9d9",
                  fontSize: "32px",
                  cursor: record.qty > 1 ? "pointer" : "not-allowed",
                }}
                onClick={() =>
                  record.qty > 1 && adjustmentReducer(record, "dec")
                }
              />
              <InputNumber
                min={0}
                onChange={(quantity) =>
                  record.quantity <= quantity
                    ? adjustmentReducer(record, "directInput", record.quantity)
                    : adjustmentReducer(record, "directInput", quantity)
                }
                value={record.qty}
              />
              <PlusCircleFilled
                style={{
                  color: record.quantity > record.qty ? "#6301f2" : "#d9d9d9",
                  fontSize: "32px",
                  cursor:
                    record.quantity > record.qty ? "pointer" : "not-allowed",
                }}
                onClick={() =>
                  record.quantity > record.qty &&
                  adjustmentReducer(record, "inc")
                }
              />
            </div>
          </>
        );
      },
    },
    {
      title: t("action"),
      align: "center",
      width: "100px",
      render: (record) => {
        return (
          <>
            <Popconfirm
              title={t("warning_remove")}
              onConfirm={() => adjustmentReducer(record, "remove")}
              okText={t("yes")}
              cancelText={t("no")}
            >
              <DeleteTwoTone
                twoToneColor="#ff0000"
                title={t("delete_entity")}
                style={{
                  fontSize: "22px",
                  cursor: "pointer",
                }}
              />
            </Popconfirm>
          </>
        );
      },
    },
  ];

  return (
    <>
      <Button type="primary" onClick={showAddModal}>
        {t("request_transfer")}
      </Button>
      {/* add transfer modal */}
      <Modal
        open={modalVisible}
        title={modalTitle}
        onOk={handleAddTransfer}
        onCancel={handleCancel}
        width={1000}
        footer={[
          <Button key="back" loading={loadingBTN} onClick={handleCancel}>
            {t("cancel")}
          </Button>,
          <Button
            key="submit"
            loading={loadingBTN}
            type="primary"
            onClick={handleAddTransfer}
          >
            {t("add")}
          </Button>,
        ]}
      >
        <Form
          name="StockTranser-form"
          layout="vertical"
          onFinish={handleAddTransfer}
          style={{ width: "100%", margin: "0" }}
          form={transferForm}
        >
          <Row gutter={[20, 20]}>
            <Col
              className="gutter-row"
              xs={24}
              sm={12}
              md={8}
              lg={8}
              xl={8}
              xxl={8}
            >
              <Form.Item
                name="branch_from_id"
                label={t("col_branch_from")}
                rules={[
                  {
                    required: true,
                    message: t("validate_branch_required"),
                  },
                ]}
                hasFeedback
              >
                <Select
                  showSearch
                  allowClear
                  onChange={(e) => {
                    setBranchId(e);
                    setAdjustmentData([]);
                  }}
                  options={branchesData
                    .filter((branch) => {
                      if (
                        branch.id !== transferForm.getFieldValue("branch_to_id")
                      ) {
                        return branch;
                      } else return null;
                    })
                    .map((branch) => {
                      return {
                        label: branch.name,
                        value: branch.id,
                      };
                    })}
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                />
              </Form.Item>
            </Col>
            <Col
              className="gutter-row"
              xs={24}
              sm={12}
              md={8}
              lg={8}
              xl={8}
              xxl={8}
            >
              <Form.Item
                name="branch_to_id"
                label={t("col_branch_to") + " (" + t("your_branch") + ")"}
                rules={[
                  {
                    required: true,
                    message: t("validate_branch_required"),
                  },
                ]}
                hasFeedback
              >
                <Select
                  disabled
                  showSearch
                  allowClear
                  options={branchesData.map((branch) => {
                    return {
                      label: branch.name,
                      value: branch.id,
                    };
                  })}
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                />
              </Form.Item>
            </Col>
            {branchId ? (
              <Col
                className="gutter-row"
                xs={24}
                sm={12}
                md={8}
                lg={8}
                xl={8}
                xxl={8}
              >
                <Form.Item
                  name="product"
                  label={t("product_add")}
                  rules={[
                    adjustmentData.length < 1 && {
                      required: true,
                      message: t("validate_product_name_required"),
                    },
                  ]}
                >
                  <Select
                    showSearch
                    options={productsList
                      .filter((product) => {
                        if (product.quantity > 0) {
                          return product;
                        } else return null;
                      })
                      .map((product) => {
                        return {
                          label:
                            appConfig.direction === "ltr"
                              ? `${product.product.name_en} (${product.quantity})`
                              : `${product.product.name} (${product.quantity})`,
                          value: product.product.id,
                        };
                      })}
                    filterOption={(input, option) =>
                      (option?.label ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    onChange={handleProductSelect}
                  />
                </Form.Item>
              </Col>
            ) : (
              ""
            )}
            {adjustmentData.length > 0 && (
              <Col
                className="gutter-row"
                xs={24}
                sm={24}
                md={24}
                lg={24}
                xl={24}
                xxl={24}
              >
                <Table
                  columns={productTableColumns}
                  dataSource={adjustmentData}
                  rowKey="id"
                  pagination={false}
                />
              </Col>
            )}
          </Row>
        </Form>
      </Modal>
    </>
  );
};

export default RequestTransfer;
