import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { getTransactionInvoice } from "../../../network/network";
import CurrencyFormatter from "../../../components/CurrencyFormatter";
import moment from "moment";

const TransactionsInvoice = () => {
  const [transactionData, setTransactionData] = useState("");
  const { search } = useLocation();

  useEffect(() => {
    const query = new URLSearchParams(search);
    const urlId = query.get("id");
    if (urlId) {
      // Get Selected transaction
      getTransactionInvoice(urlId).then(function (response) {
        if (response.data.success) {
          setTransactionData(response.data.data);
        }
      });
    }
  }, [search]);

  const transaction = transactionData.transaction;

  return (
    <>
      {transaction && (
        <div style={{ backgroundColor: "#fff", padding: "20px" }}>
          <div
            id="slip"
            style={{
              maxWidth: "550px",
              margin: "0 auto",
              width: "100%",
              padding: "10px",
              border: "1px solid #000",
            }}
          >
            <div
              style={{
                textAlign: "center",
                marginTop: "10px",
                paddingLeft: "10px",
              }}
            >
              <img
                id="store-logo"
                style={{
                  maxHeight: "120px",
                  maxWidth: "200px",
                  margin: "10px auto 20px",
                }}
                src={transactionData.logo}
                alt={transactionData.business_name + "'s Logo"}
              />
              <br />
            </div>
            <h3
              style={{
                fontSize: "16px",
                textAlign: "center",
                borderBottom: "1px solid #4e4e4e",
              }}
            >
              {transaction.reference_transaction ? (
                <>
                  <span style={{ fontSize: "16px" }}>فاتورة الاسترجاع</span>
                  <br />
                  Refund Invoice
                </>
              ) : (
                <>
                  <span style={{ fontSize: "16px" }}>
                    فاتورة ضریبیة المبسطة
                  </span>
                  <br />
                  Simplified Tax Invoice
                </>
              )}
            </h3>
            <div style={{ width: "100%", marginTop: "5px" }}>
              <table
                cellSpacing="0"
                cellPadding="0"
                style={{ width: "100%", fontSize: "12px" }}
              >
                <tbody>
                  <tr key="col-width-1">
                    <td style={{ width: "120px" }}></td>
                    <td></td>
                  </tr>
                  <tr key="invoice_no_row">
                    <td style={{ fontSize: "12px" }}>
                      {transaction.reference_transaction ? (
                        <>
                          <span style={{ fontSize: "12px" }}>
                            رقم فاتورة الاسترداد :
                          </span>
                          <br />
                          Refund Invoice No
                        </>
                      ) : (
                        <>
                          <span style={{ fontSize: "12px" }}>
                            رقم الفاتورة :
                          </span>
                          <br />
                          Invoice No
                        </>
                      )}
                    </td>
                    <td style={{ fontSize: "12px" }} className="valign-top">
                      {transaction.uid}
                    </td>
                  </tr>
                  {/* {transaction.reference_transaction && (
                <tr key="invoice_no_row">
                  <td style={{ fontSize: "12px" }}>
                    <span style={{ fontSize: "12px" }}>
                      مرجع معاملة الاسترداد :
                    </span>
                    <br />
                    Refunded Transaction Ref
                  </td>
                  <td style={{ fontSize: "12px" }} className="valign-top">
                    {transaction.reference_transaction.uid}
                  </td>
                </tr>
              )} */}
                  <tr key="issue_date_row">
                    <td style={{ fontSize: "12px", width: "200px" }}>
                      <span style={{ fontSize: "12px" }}>تاريخ الاصدار :</span>
                      <br />
                      Issue Date
                    </td>
                    <td
                      style={{ fontSize: "12px" }}
                      className="valign-top"
                      id="transaction-datetime"
                    >
                      {moment(transaction.created_at).format(
                        "MMM. D, YYYY hh:mm a"
                      )}
                    </td>
                  </tr>
                  {transactionData.is_vat_exempt ? (
                    <tr key="exempt_vat_row">
                      <td style={{ fontSize: "12px" }}>
                        <span style={{ fontSize: "12px" }}>
                          ضريبة القيمة المضافة المعفاة :
                        </span>
                        <br />
                        Exempt vat
                      </td>
                      <td style={{ fontSize: "12px" }} className="valign-top">
                        {transactionData.is_vat_exempt ? "Yes" : "No"}
                      </td>
                    </tr>
                  ) : (
                    <tr key="vat_no_row">
                      <td style={{ fontSize: "12px" }}>
                        <span style={{ fontSize: "12px" }}>
                          الرقم الضريبي :
                        </span>
                        <br />
                        VAT No
                      </td>
                      <td style={{ fontSize: "12px" }} className="valign-top">
                        {transactionData.vat}
                      </td>
                    </tr>
                  )}
                  {transaction.buyer_company_name && (
                    <tr key="vat_no_row">
                      <td style={{ fontSize: "12px" }}>
                        <span style={{ fontSize: "12px" }}>
                          اسم شركة المشتري
                        </span>
                        <br />
                        Buyer Company Name
                      </td>
                      <td style={{ fontSize: "12px" }} className="valign-top">
                        {transaction.buyer_company_name}
                      </td>
                    </tr>
                  )}
                  {transaction.buyer_company_vat && (
                    <tr key="vat_no_row">
                      <td style={{ fontSize: "12px" }}>
                        <span style={{ fontSize: "12px" }}>
                          رقم ضريبة القيمة المضافة لشركة المشتري
                        </span>
                        <br />
                        Buyer Company VAT No.
                      </td>
                      <td style={{ fontSize: "12px" }} className="valign-top">
                        {transaction.buyer_company_vat}
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
              <table
                cellSpacing="0"
                cellPadding="0"
                style={{ width: "100%", fontSize: "12px" }}
              >
                <tbody>
                  <tr key="col-width-2">
                    <td style={{ width: "75px" }}></td>
                    <td></td>
                    <td style={{ width: "60px" }}></td>
                    <td style={{ width: "100px" }}></td>
                  </tr>
                  <tr key="spacing_1_row">
                    <td
                      colSpan="4"
                      style={{
                        borderBottom: "1px solid #4e4e4e",
                        height: "10px",
                      }}
                    ></td>
                  </tr>
                  <tr key="products_header" className="products-header">
                    <td
                      style={{
                        fontSize: "12px",
                        textAlign: "left",
                        borderBottom: "1px solid #4e4e4e",
                      }}
                      colSpan="2"
                    >
                      <span style={{ fontSize: "12px" }}>الصنف</span>
                      <br />
                      Item
                    </td>
                    <td
                      style={{
                        fontSize: "12px",
                        textAlign: "right",
                        borderBottom: "1px solid #4e4e4e",
                      }}
                    >
                      <span style={{ fontSize: "12px" }}>سعر الوحدة</span>
                      <br />
                      U.P
                    </td>
                    <td
                      style={{
                        fontSize: "12px",
                        textAlign: "right",
                        borderBottom: "1px solid #4e4e4e",
                      }}
                    >
                      <span style={{ fontSize: "12px" }}>السعر مع الضريبة</span>
                      <br />
                      P.VAT
                    </td>
                  </tr>
                  {transaction.items.map((item) => (
                    <tr
                      key={"product_items_" + item.id}
                      className="product-items"
                    >
                      <td
                        style={{ fontSize: "12px", textAlign: "left" }}
                        colSpan="2"
                        className="valign-top"
                      >
                        <span style={{ fontSize: "12px" }}>{item.name}</span> x{" "}
                        {item.quantity}
                        <br />
                        {item.name_en}
                      </td>
                      <td
                        style={{ fontSize: "12px", textAlign: "right" }}
                        className="valign-top"
                      >
                        <CurrencyFormatter
                          value={item.price}
                          currencyCode={false}
                        />
                      </td>
                      <td
                        style={{ fontSize: "12px", textAlign: "right" }}
                        className="valign-top"
                      >
                        <CurrencyFormatter
                          value={item.subtotal}
                          currencyCode={false}
                        />
                      </td>
                    </tr>
                  ))}
                  <tr key="spacing_2_row">
                    <td
                      colSpan="4"
                      style={{ height: "10px", borderTop: "1px solid #4e4e4e" }}
                    ></td>
                  </tr>
                  {transaction.discount &&
                    !transaction.reference_transaction && (
                      <>
                        <tr key="subtotal_row">
                          <td colSpan="3" style={{ fontSize: "12px" }}>
                            <span style={{ fontSize: "12px" }}>
                              المجموع الفرعي
                            </span>
                            <br />
                            Subtotal (Excluding VAT)
                          </td>
                          <td
                            style={{ fontSize: "12px", textAlign: "right" }}
                            id="subtotal-amount-without-tax"
                          >
                            <CurrencyFormatter
                              value={
                                transaction.amount_charged -
                                transaction.tax +
                                Number(transaction.discount_amount)
                              }
                            />
                          </td>
                        </tr>
                        <tr key="discount_row">
                          <td colSpan="3" style={{ fontSize: "12px" }}>
                            <span style={{ fontSize: "12px" }}>تخفيض</span>
                            <br />
                            Discount {transaction.discount.discount_percentage}%
                          </td>
                          <td
                            style={{ fontSize: "12px", textAlign: "right" }}
                            id="discount"
                          >
                            -{" "}
                            <CurrencyFormatter
                              value={transaction.discount_amount}
                            />
                          </td>
                        </tr>
                      </>
                    )}
                  <tr key="total_taxable_row">
                    <td colSpan="3" style={{ fontSize: "12px" }}>
                      <span style={{ fontSize: "12px" }}>
                        الإجمالي الخاضع للضریبة
                      </span>
                      <br />
                      Total Taxable (Excluding VAT)
                    </td>
                    <td
                      style={{ fontSize: "12px", textAlign: "right" }}
                      id="total-amount-without-tax"
                    >
                      <CurrencyFormatter
                        value={transaction.amount_charged - transaction.tax}
                      />
                    </td>
                  </tr>
                  <tr key="total_vat_row">
                    <td colSpan="3" style={{ fontSize: "12px" }}>
                      <span style={{ fontSize: "12px" }}>
                        مجموع ضریبة القیمة المضافة
                      </span>
                      <br />
                      Total VAT
                    </td>
                    <td
                      style={{ fontSize: "12px", textAlign: "right" }}
                      id="total-tax"
                    >
                      <CurrencyFormatter value={transaction.tax} />
                    </td>
                  </tr>
                  <tr key="spacing_3_row">
                    <td
                      colSpan="4"
                      style={{ height: "10px", borderTop: "1px solid #4e4e4e" }}
                    ></td>
                  </tr>
                  <tr key="total_amount_row">
                    <td colSpan="2" style={{ fontSize: "16px" }}>
                      <span style={{ fontSize: "16px" }}>
                        إجمالي المبلغ المستحق
                      </span>
                      <br />
                      Total Amount
                    </td>
                    <td
                      style={{ textAlign: "right", fontSize: "16px" }}
                      colSpan="2"
                      id="total-amount"
                    >
                      <CurrencyFormatter value={transaction.amount_charged} />
                    </td>
                  </tr>
                  <tr key="spacing_4_row">
                    <td colSpan="4" style={{ height: "20px" }}></td>
                  </tr>
                </tbody>
              </table>
              <div style={{ textAlign: "center" }}>
                <img
                  src={transactionData.qr_code}
                  alt="QR Code"
                  style={{ width: "140px", margin: "10px auto" }}
                />
                <br />
                <small>Printed by https://anypos.app</small>
                <br />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default TransactionsInvoice;
