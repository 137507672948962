import { useEffect, useState, forwardRef, useContext } from "react";
import moment from "moment";
import { getTransactionQRCode } from "../../network/network";
import CurrencyFormatter from "../CurrencyFormatter";
import { AppContext } from "../../context/AppContextProvider";
import {
  TRANSACTION_TYPE_CASH,
  TRANSACTION_TYPE_CREDIT,
  TRANSACTION_TYPE_MULTI,
} from "../../configs/constants";

const TransactionSlip = forwardRef((props, ref) => {
  const transaction = props.transaction;
  const [transactionQRCode, setTransactionQRCode] = useState("");
  const { authUser } = useContext(AppContext);

  let paymentMethod =
    (transaction.type === TRANSACTION_TYPE_CASH && "Cash") ||
    (transaction.type === TRANSACTION_TYPE_CREDIT && "Card") ||
    (transaction.type === TRANSACTION_TYPE_MULTI && "Multi");

  useEffect(() => {
    getTransactionQRCode(transaction.id).then((response) => {
      setTransactionQRCode(response.data.data.qr_code);
    });
  }, [transaction.id]);

  return (
    <>
      <div id="slip" ref={ref}>
        <center>
          {/* logo area */}
          <img
            id="store-logo"
            style={{
              maxHeight: "120px",
              maxWidth: "200px",
              margin: "10px auto 20px",
            }}
            src={authUser.logo}
            alt={authUser.business_name + "'s Logo"}
          />
          <br />
          <div>{authUser.business_name}</div>
          <div>{authUser.address}</div>
          <div>{authUser.branch_name}</div>
          <div>{authUser.vat_exempt === 1 ? "" : authUser.vat}</div>
          <div
            style={{ borderBottom: "1px solid #000000", paddingTop: "10px" }}
          />
          {/* Receipt head */}
          <div
            style={{
              borderBottom: "1px solid #000000",
              paddingTop: "10px",
              paddingBottom: "10px",
              fontWeight: "bold",
            }}
          >
            {transaction.reference_transaction ? (
              <span>فاتورة الاسترجاع</span>
            ) : authUser.is_vat_exempt ? (
              <span>فاتورة مبسطة</span>
            ) : (
              <span>فاتورة ضريبية مبسطة</span>
            )}
            <div style={{ fontWeight: "normal" }}>{transaction.uid}</div>
          </div>
        </center>
        {/* Receipt description */}
        {transaction.reference_transaction ? (
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              fontSize: "12px",
              paddingTop: "10px",
            }}
          >
            <span>{transaction.reference_transaction?.uid}</span>
            <span style={{ fontSize: "12px" }}>مرجع المعاملة</span>
          </div>
        ) : (
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              fontSize: "12px",
              paddingTop: "10px",
            }}
          >
            <span>{paymentMethod}</span>
            <span>طريقة الدفع او السداد</span>
          </div>
        )}
        {paymentMethod !== "Cash" && (
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              fontSize: "12px",
              paddingTop: "10px",
            }}
          >
            <span>{transaction.reference}</span>
            <span>رمز العملية #</span>
          </div>
        )}
        {transaction.buyer_company_name && (
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              fontSize: "12px",
              paddingTop: "10px",
            }}
          >
            <span>{transaction.buyer_company_name}</span>
            <span>اسم شركة المشتري</span>
          </div>
        )}
        {transaction.buyer_company_vat && (
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              fontSize: "12px",
              paddingTop: "10px",
            }}
          >
            <span>{transaction.buyer_company_vat}</span>
            <span>ضريبة القيمة المضافة للمشتري</span>
          </div>
        )}
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            fontSize: "12px",
            paddingTop: "10px",
          }}
        >
          <span>
            {moment(transaction.created_at).format("MMM. D, YYYY hh:mm a")}
          </span>
          <span>تاريخ الاصدار</span>
        </div>

        {/* list area */}
        <div
          style={{ borderBottom: "1px solid #000000", paddingTop: "10px" }}
        />
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            fontSize: "12px",
            paddingTop: "10px",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <span style={{ width: "110px" }}>السعر مع الضريبة</span>
            <span style={{ width: "70px" }}>سعر الوحدة</span>
          </div>
          <span>الصنف</span>
        </div>
        <div
          style={{ borderBottom: "1px dashed #000000", paddingTop: "10px" }}
        />
        {transaction.items.map((item) => (
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              fontSize: "12px",
              paddingTop: "10px",
            }}
            key={"product_items_" + item.id}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <span style={{ width: "110px" }}>
                <CurrencyFormatter value={item.subtotal} currencyCode={false} />
              </span>
              <span style={{ width: "70px" }}>
                <CurrencyFormatter value={item.price} currencyCode={false} />
              </span>
            </div>
            <span>
              {item.quantity} x {item.name}
              <br />
              {item.name_en}
            </span>
          </div>
        ))}
        <div
          style={{ borderBottom: "1px solid #000000", paddingTop: "10px" }}
        />
        {/* Receipt discount */}
        {transaction.discount && !transaction.reference_transaction && (
          <>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                fontSize: "12px",
                paddingTop: "10px",
              }}
            >
              <span>
                <CurrencyFormatter
                  value={
                    transaction.amount_charged -
                    transaction.tax +
                    Number(transaction.discount_amount)
                  }
                />
              </span>
              <span>المجموع الفرعي</span>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                fontSize: "12px",
                paddingTop: "10px",
              }}
            >
              <span>
                - <CurrencyFormatter value={transaction.discount_amount} />
              </span>
              <span> تخفيض % {transaction.discount.discount_percentage}</span>
            </div>
            <div
              style={{ borderBottom: "1px solid #000000", paddingTop: "10px" }}
            />
          </>
        )}
        {/* Receipt subtotal */}
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            fontSize: "12px",
            paddingTop: "10px",
          }}
        >
          <span>
            <CurrencyFormatter
              value={transaction.amount_charged - transaction.tax}
            />
          </span>
          <span>الإجمالي بدون الضريبة</span>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            fontSize: "12px",
            paddingTop: "10px",
          }}
        >
          <span>
            <CurrencyFormatter value={transaction.tax} />
          </span>
          <span>مجموع ضریبة القیمة المضافة</span>
        </div>
        <div
          style={{ borderBottom: "1px solid #000000", paddingTop: "10px" }}
        />
        {/* Receipt total */}
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            fontSize: "12px",
            paddingTop: "10px",
          }}
        >
          <span>
            <CurrencyFormatter value={transaction.amount_charged} />
          </span>
          <span>
            {authUser.is_vat_exempt ? "الإجمالي" : "الإجمالي مع الضريبة"}
          </span>
        </div>
        <div
          style={{ borderBottom: "1px solid #000000", paddingTop: "10px" }}
        />
        {/* Receipt remain amount */}
        {paymentMethod === "Cash" && !transaction.reference_transaction && (
          <>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                fontSize: "12px",
                paddingTop: "10px",
              }}
            >
              <span>
                <CurrencyFormatter value={transaction.cash_collected} />
              </span>
              <span>المبلغ المستلم</span>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                fontSize: "12px",
                paddingTop: "10px",
              }}
            >
              <span>
                <CurrencyFormatter
                  value={
                    transaction.cash_collected - transaction.amount_charged
                  }
                />
              </span>
              <span>المتبقي</span>
            </div>
            <div
              style={{ borderBottom: "1px solid #000000", paddingTop: "10px" }}
            />
          </>
        )}
        {/* qr code */}
        <div style={{ textAlign: "center" }}>
          <img
            src={transactionQRCode}
            alt="QR Code"
            style={{ width: "140px", margin: "10px auto" }}
          />
          <br />
          <small>Printed by https://anypos.app</small>
          <br />
        </div>
      </div>
    </>
  );
});

export default TransactionSlip;
