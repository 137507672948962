import React, { useState } from "react";
import { Button, Form, Modal, Upload, message } from "antd";
import { useTranslation } from "react-i18next";
import {
  importProduct,
  importProductCategory,
  importProductUnit,
} from "../network/network";
import NotificationWithIcon from "./NotificationWithIcon";
import { PlusOutlined, UploadOutlined } from "@ant-design/icons";

const ImportItems = ({ type, callbackLoading }) => {
  const PRODUCT = "product";
  const CATEGORY = "category";
  const UNIT = "unit";
  const [modalVisible, setModalVisible] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [loadingBTN, setLoadingBTN] = useState(false);
  const [fileList, setFileList] = useState("");
  const [importForm] = Form.useForm();
  const { t } = useTranslation();

  const showModal = (type) => {
    // Set Modal Title
    if (type === PRODUCT) {
      setModalTitle(t("products_import"));
    } else if (type === CATEGORY) {
      setModalTitle(t("categories_import"));
    } else if (type === UNIT) {
      setModalTitle(t("units_import"));
    }
    // show import modal
    setModalVisible(true);
    // Reset import Field
    setFileList("");
  };

  const handleCancel = () => {
    // Reset import Field
    setFileList("");
    // hide import modal
    setModalVisible(false);
    // remove modal title
    setModalTitle("");
  };

  const handleAPIResponse = (response, type) => {
    let data = response.data || response.response.data;
    if (data.success === true) {
      // data reload request
      callbackLoading(true);
      // Success
      if (type === PRODUCT) {
        NotificationWithIcon("success", t("products_import_successfully"));
      } else if (type === CATEGORY) {
        NotificationWithIcon("success", t("categories_import_successfully"));
      } else if (type === UNIT) {
        NotificationWithIcon("success", t("units_import_successfully"));
      }
    } else {
      // Error
      let errorMessages = Object.values(data.data.errors);
      errorMessages.forEach(function (key, value) {
        if (key.message) {
          message.error(`Error in Row ${key.row}: ${key.message}`);
        } else {
          let temp = Object.values(key);
          message.error(temp[0]);
        }
      });
    }
    // Reset Form Fields
    importForm.resetFields();
    setFileList("");
    // Hide Modal
    setModalVisible(false);
    // remove modal title
    setModalTitle("");
  };

  const handleImport = () => {
    setLoadingBTN(true);
    // Set Form values as FormData
    const formData = new window.FormData();
    if (typeof fileList == "object" && fileList) {
      formData.append("attachment", fileList);
    }
    if (modalTitle === t("products_import")) {
      importProduct(formData)
        .then((response) => {
          handleAPIResponse(response, PRODUCT);
        })
        .catch((info) => {
          console.log("API Failed:", info.response);
        })
        .finally(() => {
          // Set Loading False
          setLoadingBTN(false);
        });
    } else if (modalTitle === t("categories_import")) {
      importProductCategory(formData)
        .then((response) => {
          handleAPIResponse(response, CATEGORY);
        })
        .catch((info) => {
          console.log("API Failed:", info.response);
        })
        .finally(() => {
          // Set Loading False
          setLoadingBTN(false);
        });
    } else if (modalTitle === t("units_import")) {
      importProductUnit(formData)
        .then((response) => {
          handleAPIResponse(response, UNIT);
        })
        .catch((info) => {
          console.log("API Failed:", info.response);
        })
        .finally(() => {
          // Set Loading False
          setLoadingBTN(false);
        });
    }
  };

  function beforeUpload(file) {
    const isExcelFile =
      file.type ===
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
    if (!isExcelFile) {
      message.error("validate_excel_file_type");
    }
    const is1MB = file.size / 1024 / 1024 < 1;
    if (!is1MB) {
      message.error(t("validate_file_size_1"));
    }
    return isExcelFile && is1MB;
  }

  return (
    <>
      {type === PRODUCT && (
        <Button
          type="primary"
          onClick={() => {
            showModal(type);
          }}
        >
          {t("products_import")}
        </Button>
      )}
      {type === CATEGORY && (
        <Button
          type="primary"
          onClick={() => {
            showModal(type);
          }}
        >
          {t("categories_import")}
        </Button>
      )}
      {type === UNIT && (
        <Button
          type="primary"
          onClick={() => {
            showModal(type);
          }}
        >
          {t("units_import")}
        </Button>
      )}

      <Modal
        open={modalVisible}
        title={modalTitle}
        onOk={handleImport}
        onCancel={handleCancel}
        footer={[
          <Button key="back" loading={loadingBTN} onClick={handleCancel}>
            {t("cancel")}
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={loadingBTN}
            onClick={handleImport}
          >
            {t("upload")}
          </Button>,
        ]}
      >
        <Form
          name="import-form"
          layout="vertical"
          onFinish={handleImport}
          style={{ width: "100%", margin: "0" }}
          form={importForm}
        >
          <Form.Item
            name="attachment"
            valuePropName="file"
            getValueFromEvent={(event) => {
              return event?.file;
            }}
            label={t("attachment") + " (" + t("validate_file_size_1") + ")"}
            rules={[
              {
                required: true,
                message: t("validate_attachment_required"),
              },
            ]}
          >
            <Upload.Dragger
              maxCount={1}
              showUploadList={false}
              accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
              beforeUpload={beforeUpload}
              customRequest={(info) => {
                setFileList(info.file);
              }}
            >
              {fileList ? (
                <div>
                  <UploadOutlined />
                  <div style={{ marginTop: 8 }}>{t("change")}</div>
                </div>
              ) : (
                <div>
                  <PlusOutlined />
                  <div style={{ marginTop: 8 }}>{t("upload")}</div>
                </div>
              )}
              {fileList?.name}
            </Upload.Dragger>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default ImportItems;
