import React, { useContext, useEffect, useState } from "react";
import {
  Card,
  Form,
  Input,
  Button,
  Table,
  Modal,
  Popconfirm,
  message,
} from "antd";
import { PageHeader } from "@ant-design/pro-layout";
import {
  HomeOutlined,
  DeleteTwoTone,
  BgColorsOutlined,
  BarcodeOutlined,
  EditTwoTone,
  CloseOutlined,
  CheckOutlined,
} from "@ant-design/icons";
import {
  getProductUnits,
  createProductUnit,
  updateProductUnit,
  deleteProductUnit,
  sendUnitToOdoo,
} from "../../../network/network";
import CustomBreadcrumb from "../../../components/CustomBreadcrumb";
import NotificationWithIcon from "../../../components/NotificationWithIcon";
import { useTranslation } from "react-i18next";
import { AppContext } from "../../../context/AppContextProvider";
import DownloadImportTemplate from "../../../components/DownloadImportTemplate";
import ImportItems from "../../../components/ImportItems";

const ProductUnits = () => {
  const { appConfig, authUser } = useContext(AppContext);
  const ADD_UNIT = "Add Unit";
  const [loadingBTN, setLoadingBTN] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [reloadTableData, setReloadTableData] = useState(false);
  const [productUnitsTableData, setProductUnitsTableData] = useState([]);
  const [modalTitle, setModalTitle] = useState(ADD_UNIT);
  const [addUnitForm] = Form.useForm();
  const { t } = useTranslation();

  useEffect(() => {
    setIsDataLoading(true);
    getProductUnits()
      .then((response) => {
        setProductUnitsTableData(response.data.data.product_units);
      })
      .finally(() => {
        setIsDataLoading(false);
      });
  }, [reloadTableData]);

  const showModal = () => {
    setModalVisible(true);
    // Set Modal Title
    setModalTitle(t("unit_add"));
  };

  const handleAPIResponse = (response, type) => {
    let data = response.data || response.response.data;
    if (data.success === true) {
      setReloadTableData(!reloadTableData);
      // Success
      if (type === ADD_UNIT) {
        NotificationWithIcon(
          "success",
          t("unit_added"),
          t("unit_added_successfully")
        );
      } else {
        NotificationWithIcon(
          "success",
          t("unit_updated"),
          t("unit_updated_successfully")
        );
      }
      // Reset Form Fields
      addUnitForm.resetFields();
      // Hide Modal
      setModalVisible(false);
    } else {
      // Error
      let errorMessages = Object.values(data.errors);
      errorMessages.forEach(function (key, value) {
        let temp = Object.values(key);
        message.error(temp[0]);
      });
    }
  };

  const handleAddEdit = () => {
    addUnitForm
      .validateFields()
      .then((values) => {
        // Set Loading True
        setLoadingBTN(true);
        // Sending Request to API
        if (modalTitle === ADD_UNIT || modalTitle === t("unit_add")) {
          createProductUnit(values)
            .then((response) => handleAPIResponse(response, ADD_UNIT))
            .catch((info) => {
              console.log("API Failed:", info.response);
            })
            .finally(() => {
              // Set Loading False
              setLoadingBTN(false);
            });
        } else {
          updateProductUnit(values)
            .then((response) => handleAPIResponse(response, null))
            .catch((info) => {
              console.log("API Failed:", info.response);
            })
            .finally(() => {
              // Set Loading False
              setLoadingBTN(false);
            });
        }
      })
      .catch((info) => {
        console.log("API Failed:", info);
      });
  };

  const handleCancel = () => {
    // Reset Form Fields
    addUnitForm.resetFields();
    setModalVisible(false);
  };

  const deleteUnit = (id) => {
    deleteProductUnit(id)
      .then((response) => {
        let data = response.data || response.response.data;
        if (data.success === true) {
          setReloadTableData(!reloadTableData);
          // Success
          NotificationWithIcon(
            "success",
            t("unit_deleted"),
            t("unit_deleted_successfully")
          );
        } else {
          // Error
          NotificationWithIcon(
            "error",
            t("something_went_wrong"),
            data.message
          );
        }
      })
      .catch((info) => {
        console.log("API Failed:", info.response);
      });
  };

  const handleEditModal = (record_id) => {
    // Find Unit from DataArray
    const unit = productUnitsTableData.find((unit) => unit.id === record_id);

    let unit_name;

    appConfig.direction === "ltr"
      ? (unit_name = unit.name)
      : (unit_name = unit.name_ar);

    // Set Modal Title
    setModalTitle(t("Unit_edit") + " : " + unit_name);

    // Show Modal
    setModalVisible(true);

    // Explicitly reset Form Fields before setting them for edit
    // This ensures the form is in a clean state before being repopulated
    addUnitForm.resetFields();

    // Set Form Fields
    addUnitForm.setFieldsValue({
      id: unit.id,
      name: unit.name,
      name_ar: unit.name_ar,
    });
  };

  const handleSendToOdoo = (id) => {
    setLoadingBTN(true);
    sendUnitToOdoo(id)
      .then((response) => {
        let data = response.data || response.response.data;
        if (data.success === true) {
          setReloadTableData(!reloadTableData);
          // Success
          NotificationWithIcon(
            "success",
            t("send_to_odoo"),
            t("item_send_to_odoo_successfully")
          );
        } else {
          // Error
          NotificationWithIcon(
            "error",
            t("something_went_wrong"),
            data.message
          );
        }
      })
      .catch((info) => {
        console.log("API Failed:", info.response);
      })
      .finally(() => {
        // Set Loading False
        setLoadingBTN(false);
      });
  };

  const breadcrumbRoutes = [
    {
      path: "/",
      breadcrumbName: t("menu_home"),
      icon: <HomeOutlined />,
    },
    {
      path: "/products",
      breadcrumbName: t("menu_products"),
      icon: <BarcodeOutlined />,
    },
    {
      path: "units",
      breadcrumbName: t("menu_units"),
      icon: <BgColorsOutlined />,
    },
  ];

  const detailsPartColumns = [
    {
      title: "#",
      key: "index",
      render: (text, record, index) => {
        return <p>{index + 1}</p>;
      },
    },
    {
      title: t("col_unit"),
      width: "70%",
      render: (record) => {
        return (
          <>
            {record.name_ar} <br /> {record.name}
          </>
        );
      },
    },
  ];
  const odooStatusColumn = [
    {
      title: t("col_odoo_status"),
      align: "center",
      render: (record) => {
        return (
          <>
            {record.odoo_reference_id ? (
              <CheckOutlined style={{ fontSize: "22px", color: "#52c41a" }} />
            ) : (
              <>
                <CloseOutlined
                  style={{
                    fontSize: "22px",
                    color: "#ff0000",
                    display: "block",
                    paddingBottom: "5px",
                  }}
                />
                <Button
                  type="primary"
                  size="small"
                  style={{ marginLeft: "10px" }}
                  onClick={() => handleSendToOdoo(record.id)}
                  loading={loadingBTN}
                >
                  {t("send_to_odoo")}
                </Button>
              </>
            )}
          </>
        );
      },
    },
  ];
  const actionPartColumn = [
    {
      title: t("col_action"),
      align: "center",
      render: (record) => {
        return (
          <>
            <Button
              type="link"
              onClick={() => handleEditModal(record.id)}
              title={t("Unit_edit")}
            >
              <EditTwoTone twoToneColor="#1890ff" />
            </Button>
            <Popconfirm
              title={t("warning_delete")}
              onConfirm={() => deleteUnit(record.id)}
              okText={t("yes")}
              cancelText={t("no")}
            >
              <DeleteTwoTone
                twoToneColor="#ff0000"
                title={t("delete_entity")}
              />
            </Popconfirm>
          </>
        );
      },
    },
  ];

  return (
    <>
      <Card style={{ marginBottom: "20px" }}>
        <CustomBreadcrumb menus={breadcrumbRoutes} />
        <PageHeader
          style={{ padding: "0" }}
          title={t("menu_units")}
          ghost={false}
          extra={[
            <Button
              key="open-add-unit-modal"
              type="primary"
              onClick={showModal}
            >
              + {t("unit_add")}
            </Button>,
            <DownloadImportTemplate type="unit" key="import-template" />,
            <ImportItems
              type="unit"
              callbackLoading={setReloadTableData}
              key="import-items"
            />,
          ]}
        />
      </Card>
      <Card>
        <Table
          columns={
            authUser.external_integrations.length
              ? [
                  ...detailsPartColumns,
                  ...odooStatusColumn,
                  ...actionPartColumn,
                ]
              : [...detailsPartColumns, ...actionPartColumn]
          }
          dataSource={productUnitsTableData}
          loading={isDataLoading}
          rowKey="id"
          pagination={false}
        />
      </Card>
      <Modal
        open={modalVisible}
        title={modalTitle}
        onOk={handleAddEdit}
        onCancel={handleCancel}
        footer={[
          <Button key="back" loading={loadingBTN} onClick={handleCancel}>
            {t("cancel")}
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={loadingBTN}
            onClick={handleAddEdit}
          >
            {modalTitle === ADD_UNIT || modalTitle === t("unit_add")
              ? t("unit_add")
              : t("update")}
          </Button>,
        ]}
      >
        <Form
          name="add-unit-form"
          layout="vertical"
          onFinish={handleAddEdit}
          style={{ width: "100%", margin: "0" }}
          form={addUnitForm}
        >
          <Form.Item
            name="name"
            label={t("name_en")}
            rules={[
              {
                required: true,
                message: t("unit_name_required"),
                whitespace: true,
              },
            ]}
            hasFeedback
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="name_ar"
            label={t("name_ar")}
            rules={[
              {
                required: true,
                message: t("unit_name_required"),
                whitespace: true,
              },
            ]}
            hasFeedback
          >
            <Input />
          </Form.Item>
          <Form.Item name="id" style={{ display: "none" }}>
            <input type="hidden" />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default ProductUnits;
