import React, { useEffect, useState } from "react";
import {
  Card,
  Table,
  Form,
  DatePicker,
  Input,
  Select,
  Button,
  Tag,
} from "antd";
import { getSaleInvoices } from "../../../network/network";
import CurrencyFormatter from "../../../components/CurrencyFormatter";
import { SALES_INVOICE_STATUS_COMPLETED } from "../../../configs/constants";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const PaidInvoices = ({ branchesData }) => {
  const navigate = useNavigate();
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [paginationData, setPaginationData] = useState({
    current: 1,
    pageSize: 15,
    total: 0,
  });
  const [searchParams, setSearchParams] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchFormRef] = Form.useForm();
  const { t } = useTranslation();
  const { RangePicker } = DatePicker;

  useEffect(() => {
    setIsDataLoading(true);
    getSaleInvoices({
      ...searchParams,
      page: currentPage,
      sale_invoice_status: SALES_INVOICE_STATUS_COMPLETED,
    })
      .then((response) => {
        setTableData(response.data.data.saleInvoices);
        setPaginationData(response.data.data.pagination);
      })
      .finally(() => {
        setIsDataLoading(false);
      });
  }, [searchParams, currentPage]);

  // TableColumns Columns
  const TableColumns = [
    {
      title: "#",
      key: "index",
      render: (text, record, index) => {
        return <>{index + 1}</>;
      },
    },
    {
      title: t("col_created_at"),
      dataIndex: "create_invoice_date",
      render: (create_invoice_date) => {
        return (
          <p>
            {create_invoice_date
              ? moment(create_invoice_date).format("MMM. D, YYYY hh:mm a")
              : "-"}
          </p>
        );
      },
    },
    {
      title: t("col_paid_at"),
      render: (record) => {
        return (
          <p>
            {moment(record.created_at).format("MMM. D, YYYY hh:mm a")}
            <br />
            {moment(record.invoice_due_date).diff(
              moment(record.created_at),
              "seconds"
            ) < 0 && <Tag color="error">{t("late_payment")}</Tag>}
          </p>
        );
      },
    },
    {
      title: t("invoice_ID"),
      render: (record) => {
        return (
          <p>
            {record.invoice_number ? record.invoice_number + " " : "-"}
            {record.reference_transaction ? (
              <>
                <Tag color="error">{t("status_refunded")}</Tag>
                <br />
                Ref: <strong>{record.reference_transaction.uid}</strong>
              </>
            ) : (
              ""
            )}
          </p>
        );
      },
    },
    {
      title: t("col_transaction_id"),
      dataIndex: "uid",
    },
    {
      title: t("customer_name"),
      render: (record) => {
        return <>{record.customer?.name_ar && record.customer?.name_ar}</>;
      },
    },
    {
      title: t("col_amount"),
      render: (record) => {
        return (
          <>
            <p>
              <CurrencyFormatter value={record.amount_charged} />
            </p>
          </>
        );
      },
    },
    {
      title: t("col_action"),
      render: (record) => {
        return (
          <Button
            type="primary"
            onClick={() => {
              navigate("/sales-invoice/" + record.id);
            }}
          >
            {t("preview")}
          </Button>
        );
      },
    },
  ];

  const handleSearch = (values) => {
    let search_params = false;
    if (values.dates) {
      values.dates[0].set({
        hour: 0,
        minute: 0,
        second: 1,
        millisecond: 0,
      });
      values.dates[1].set({
        hour: 23,
        minute: 59,
        second: 59,
        millisecond: 0,
      });

      let startDateUtc = new moment(values.dates[0]).utc();
      let endDateUtc = new moment(values.dates[1]).utc();

      search_params = {
        search_start_date: startDateUtc.format(),
        search_end_date: endDateUtc.format(),
      };
    }
    if (values.filter_by_branch) {
      search_params = {
        filter_by_branch: values.filter_by_branch,
        ...search_params,
      };
    }
    if (values.is_payment_late === 0 || values.is_payment_late) {
      search_params = {
        is_payment_late: values.is_payment_late,
        ...search_params,
      };
    }

    if (search_params) {
      setSearchParams(search_params);
    } else {
      setSearchParams([]);
    }
  };

  const handleReset = () => {
    // Reset Form Fields
    searchFormRef.resetFields();
    // Reset Transactions List
    setSearchParams([]);
  };

  const searchForm = (
    <>
      <Form
        name="search-form"
        layout="inline"
        onFinish={handleSearch}
        form={searchFormRef}
      >
        <Form.Item name="dates" label={t("date_range")}>
          <RangePicker allowClear format="DD/MM/YYYY" />
        </Form.Item>
        <Form.Item name="filter_by_branch" label={t("branch")}>
          <Select
            allowClear
            style={{ width: "100px" }}
            options={branchesData.map((branch) => {
              return {
                label: branch.name,
                value: branch.name,
              };
            })}
          />
        </Form.Item>
        <Form.Item name="is_payment_late" label={t("is_late_payment")}>
          <Select
            allowClear
            style={{ width: "100px" }}
            options={[
              {
                label: t("yes"),
                value: 1,
              },
              {
                label: t("no"),
                value: 0,
              },
            ]}
          />
        </Form.Item>
        <Form.Item>
          <Input
            type="submit"
            value={t("search")}
            style={{
              backgroundColor: "#6301f2",
              color: "#ffffff",
              width: "80px",
            }}
          />
        </Form.Item>
        <Form.Item>
          <Input type="button" value={t("reset")} onClick={handleReset} />
        </Form.Item>
      </Form>
    </>
  );

  const handleTableChange = (pagination, filters, sorter) => {
    setPaginationData(pagination);
    setCurrentPage(pagination.current);
  };

  return (
    <>
      <Card>{searchForm}</Card>
      <Card>
        <Table
          columns={TableColumns}
          dataSource={tableData}
          loading={isDataLoading}
          rowKey="id"
          pagination={{
            current: paginationData.current_page,
            pageSize: paginationData.per_page,
            total: paginationData.total,
            showSizeChanger: false,
          }}
          onChange={handleTableChange}
        />
      </Card>
    </>
  );
};

export default PaidInvoices;
