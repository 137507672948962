import { useEffect, useState, useContext, useRef } from "react";
import {
  Form,
  Input,
  Button,
  Radio,
  Alert,
  Space,
  Card,
  Dropdown,
  Row,
  Col,
  Select,
} from "antd";
import { useTranslation } from "react-i18next";
import ReactToPrint from "react-to-print";
import CurrencyFormatter from "../../../components/CurrencyFormatter";
import {
  ADDON_CUSTOMERS_MANAGEMENT,
  TAX_PERCENTAGE,
  TRANSACTION_STATUS_COMPLETED,
  TRANSACTION_TYPE_CASH,
  TRANSACTION_TYPE_CREDIT,
  TRANSACTION_TYPE_MULTI,
} from "../../../configs/constants";
import { AppContext } from "../../../context/AppContextProvider";
import {
  createTransactions,
  editTransaction,
  getBranchDiscounts,
  getCustomers,
} from "../../../network/network";
import NotificationWithIcon from "../../../components/NotificationWithIcon";
import TransactionSlip from "../../../components/TransactionSlip/TransactionSlip";
import { useNavigate, useLocation } from "react-router-dom";
import { CloseOutlined, DownOutlined } from "@ant-design/icons";
import AddCustomer from "../Customers/AddCustomer";

const Checkout = () => {
  const { authUser, roundOff, appConfig } = useContext(AppContext);
  const [cartData, SetCartData] = useState([]);
  const [discountValue, setDiscountValue] = useState(0);
  const [discountId, setDiscountId] = useState("");
  const [grandTotalAmount, setGrandTotalAmount] = useState(0);
  const [grandtotalVAT, setGrandTotalVAT] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);
  const [totalVAT, setTotalVAT] = useState(0);
  const [selectedPaymentType, setSelectedPaymentType] = useState(
    TRANSACTION_TYPE_CASH
  );
  const [transaction, setTransaction] = useState(false);
  const [returnAmountMsg, setReturnAmountMsg] = useState("");
  const [cashCollected, setCashCollected] = useState("");
  const [branchDiscounts, setBranchDiscounts] = useState("");
  const [onScreenInput, setOnScreenInput] = useState("");
  const [selectedField, setSelectedField] = useState("");
  const [selectedCustomer, setSelectedCustomer] = useState("");
  const [customerData, setCustomerData] = useState([]);
  const [reloadCustomerData, setReloadCustomerData] = useState(false);
  const [checkoutForm] = Form.useForm();
  const transactionSlipRef = useRef();
  const { t } = useTranslation();
  let navigate = useNavigate();
  const { state } = useLocation();

  const lastVisitedPage = JSON.parse(localStorage.getItem("last_visited_page"));

  const is_customer_management_active = authUser.active_addons.some(
    (addon) => addon.id && addon.name === ADDON_CUSTOMERS_MANAGEMENT
  );

  useEffect(() => {
    state.items ? SetCartData(state) : navigate(lastVisitedPage.path);
  }, [navigate, state, lastVisitedPage]);

  useEffect(() => {
    setTransaction(false);
    setReturnAmountMsg("");
    setSelectedPaymentType(TRANSACTION_TYPE_CASH);
    checkoutForm.resetFields();
  }, [checkoutForm]);

  useEffect(() => {
    let taxTotal = 0;
    let total = 0;
    let tax = 0;
    let subTotal = 0;
    let cartitems = cartData?.items;
    cartitems &&
      cartitems.forEach((product) => {
        subTotal = product.price * product.quantity;
        if (product.is_taxable === true || product.tax) {
          tax = subTotal * TAX_PERCENTAGE;
          taxTotal = taxTotal + tax;
          subTotal = subTotal + tax;
        }
        total = total + subTotal;
      });
    setTotalVAT(roundOff(taxTotal));
    setTotalAmount(roundOff(total));
  }, [cartData, setTotalAmount, setTotalVAT, roundOff]);

  useEffect(() => {
    getBranchDiscounts().then((response) => {
      setBranchDiscounts(response.data.data.discounts);
    });
  }, []);

  useEffect(() => {
    is_customer_management_active &&
      authUser.can_view_customer &&
      getCustomers({
        per_page: -1,
      }).then((response) => {
        setCustomerData(response.data.data.customers);
      });
  }, [reloadCustomerData, is_customer_management_active, authUser]);

  const checkoutFormInitialValues = {
    payment_type: TRANSACTION_TYPE_CASH,
    collected_by_cash_multi: "",
    collected_by_card_multi: "",
    dynamic_field: "",
  };

  const handleCheckout = () => {
    let billTotalAmount = discountValue === 0 ? totalAmount : grandTotalAmount;
    checkoutForm.validateFields().then((values) => {
      let transactionObj = {
        total: roundOff(billTotalAmount),
      };
      if (selectedPaymentType === TRANSACTION_TYPE_CASH) {
        let amountDiff =
          parseFloat(values.dynamic_field || 0) - roundOff(billTotalAmount);
        if (amountDiff < 0) {
          alert(t("ask_more_cash"));
          return false;
        }
        if (amountDiff > 0) {
          let msg = (
            <>
              {t("return_back_amount") + " "}
              <CurrencyFormatter value={roundOff(amountDiff)} />
            </>
          );
          setReturnAmountMsg(msg);
        }
        transactionObj.cash_collected = Number(values.dynamic_field);
      } else {
        if (selectedPaymentType === TRANSACTION_TYPE_MULTI) {
          let cashCollect =
            Number(values.collected_by_cash_multi) +
            Number(values.collected_by_card_multi);
          let amountDiff =
            parseFloat(cashCollect || 0) - roundOff(billTotalAmount);
          if (amountDiff < 0) {
            alert(t("ask_more_cash"));
            return false;
          }
          transactionObj.multipayments = JSON.stringify([
            {
              transaction_type: TRANSACTION_TYPE_CASH,
              amount: Number(values.collected_by_cash_multi),
            },
            {
              transaction_type: TRANSACTION_TYPE_CREDIT,
              amount: Number(values.collected_by_card_multi),
            },
          ]);
        }
        transactionObj.reference = values.dynamic_field;
      }
      transactionObj.type = values.payment_type;
      transactionObj.items = JSON.stringify(
        cartData.items.map((item) => ({
          id: item.product_id ? item.product_id : item.id,
          qty: item.quantity,
          price: item.price,
        }))
      );
      // if customer management is enable
      if (values.customer_id && is_customer_management_active) {
        transactionObj.customer_id = values.customer_id;
      }
      // Add Company Name and VAT if added
      if (values.buyer_company_name) {
        transactionObj.buyer_company_name = values.buyer_company_name;
      }
      if (values.buyer_company_vat) {
        transactionObj.buyer_company_vat = values.buyer_company_vat;
      }
      if (discountId) {
        transactionObj.discount_id = discountId;
      }
      if (cartData.diningTableId) {
        transactionObj.dining_table_id = cartData.diningTableId;
      }
      if (cartData.waiterId) {
        transactionObj.waiter_id = cartData.waiterId;
      }
      if (cartData.orderStatus === TRANSACTION_STATUS_COMPLETED) {
        transactionObj.transaction_status = cartData.orderStatus;
      }
      if (cartData.id) {
        transactionObj.id = cartData.id;
      }
      if (transactionObj.transaction_status) {
        editTransaction(transactionObj).then((response) => {
          let data = response.data || response.response.data;
          if (data.success === true) {
            setTransaction(response.data.data.transaction);
            // Reset Payment Type
            setSelectedPaymentType(TRANSACTION_TYPE_CASH);
            // Reset data
            checkoutForm.resetFields();
          } else {
            // Error
            NotificationWithIcon(
              "error",
              t("something_went_wrong"),
              data.message
            );
          }
        });
      } else {
        createTransactions(transactionObj).then((response) => {
          let data = response.data || response.response.data;
          if (data.success === true) {
            setTransaction(response.data.data.transaction);
            // Reset Payment Type
            setSelectedPaymentType(TRANSACTION_TYPE_CASH);
            // Reset data
            checkoutForm.resetFields();
          } else {
            // Error
            NotificationWithIcon(
              "error",
              t("something_went_wrong"),
              data.message
            );
          }
        });
      }
    });
  };

  const handleCashMultiPayment = (e) => {
    let amount = e.target.value;
    checkoutForm.setFieldsValue({
      collected_by_cash_multi: amount,
      collected_by_card_multi: (
        (discountValue === 0 ? totalAmount : grandTotalAmount) - amount
      ).toFixed(2),
    });
  };

  const handleCardMultiPayment = (e) => {
    // console.log(e);
    let amount = e.target.value ? e.target.value : e;
    checkoutForm.setFieldsValue({
      collected_by_card_multi: amount,
      collected_by_cash_multi: (
        (discountValue === 0 ? totalAmount : grandTotalAmount) - amount
      ).toFixed(2),
    });
  };

  const handleDiscount = (id) => {
    if (authUser.allow_discount === true) {
      const discount_id = id;
      const discount = branchDiscounts.find(
        (discount) => discount.id === discount_id
      );
      setDiscountId(discount_id);
      setDiscountValue(discount.discount_percentage);
      if (discount) {
        const discount_percentage = discount.discount_percentage / 100;
        const amountValue = totalAmount * discount_percentage;
        const vatValue = totalVAT * discount_percentage;
        setGrandTotalVAT(totalVAT - vatValue);
        setGrandTotalAmount(totalAmount - amountValue);
      }
    }
  };

  const discountReset = () => {
    setGrandTotalVAT(0);
    setGrandTotalAmount(0);
    setDiscountValue(0);
    setDiscountId("");
  };

  useEffect(() => {
    if (onScreenInput > 0) {
      if (selectedField === "collected_by_cash_multi") {
        checkoutForm.setFieldsValue({
          collected_by_cash_multi: onScreenInput,
          collected_by_card_multi: (
            (discountValue === 0 ? totalAmount : grandTotalAmount) -
            onScreenInput
          ).toFixed(2),
        });
      }
      if (selectedField === "collected_by_card_multi") {
        checkoutForm.setFieldsValue({
          collected_by_card_multi: onScreenInput,
          collected_by_cash_multi: (
            (discountValue === 0 ? totalAmount : grandTotalAmount) -
            onScreenInput
          ).toFixed(2),
        });
      }
      if (selectedField === "dynamic_field") {
        setCashCollected(onScreenInput);
        checkoutForm.setFieldsValue({
          dynamic_field: onScreenInput,
        });
      }
    }
  }, [
    onScreenInput,
    checkoutForm,
    selectedField,
    discountValue,
    totalAmount,
    grandTotalAmount,
  ]);

  const handleButtonClick = (value) => {
    if (value !== "Clear") {
      setOnScreenInput((prevValue) => prevValue + value);
    } else {
      setOnScreenInput("");
      if (selectedField === "collected_by_cash_multi") {
        checkoutForm.setFieldsValue({
          collected_by_cash_multi: 0,
          collected_by_card_multi: (
            (discountValue === 0 ? totalAmount : grandTotalAmount) - 0
          ).toFixed(2),
        });
      }
      if (selectedField === "collected_by_card_multi") {
        checkoutForm.setFieldsValue({
          collected_by_card_multi: 0,
          collected_by_cash_multi: (
            (discountValue === 0 ? totalAmount : grandTotalAmount) - 0
          ).toFixed(2),
        });
      }
      if (selectedField === "dynamic_field") {
        setCashCollected("0");
        checkoutForm.setFieldsValue({
          dynamic_field: 0,
        });
      }
    }
  };

  const handleSelectField = (data) => {
    setSelectedField(data);
    setOnScreenInput("");
  };

  const handleCustomerSelect = (id) => {
    const customer = customerData.find((customer) => customer.id === id);
    checkoutForm.setFieldsValue({
      buyer_company_name: customer.name_ar,
      buyer_company_vat: customer.vat ? customer.vat : "",
    });
    setSelectedCustomer(customer);
  };

  const resetSelectedCustomer = () => {
    checkoutForm.setFieldsValue({
      customer_id: "",
      buyer_company_name: "",
      buyer_company_vat: "",
    });
    setSelectedCustomer("");
  };

  return (
    <>
      <Card>
        {transaction === false ? (
          <Row gutter={[20, 20]}>
            <Col span={16}>
              <h3>
                {t("charged_amount")} :{" "}
                <span style={{ color: "#6301f2" }}>
                  <CurrencyFormatter
                    value={discountValue === 0 ? totalAmount : grandTotalAmount}
                  />
                </span>
                <br />
                <small>
                  {t("including_vat")}:{" "}
                  <span style={{ color: "#6301f2" }}>
                    <CurrencyFormatter
                      value={discountValue === 0 ? totalVAT : grandtotalVAT}
                    />
                  </span>
                </small>
              </h3>
              <h4>
                {t("payment_type")} :{" "}
                {selectedPaymentType === TRANSACTION_TYPE_CASH && t("cash")}
                {selectedPaymentType === TRANSACTION_TYPE_CREDIT && t("card")}
                {selectedPaymentType === TRANSACTION_TYPE_MULTI &&
                  t("multi_payment")}
              </h4>
              {selectedPaymentType === TRANSACTION_TYPE_CASH &&
                cashCollected && (
                  <h4>
                    {t("return_back_amount")} :{" "}
                    <span
                      style={{
                        color: "red",
                      }}
                    >
                      {cashCollected <
                      (discountValue === 0 ? totalAmount : grandTotalAmount)
                        ? t("collect_more_cash")
                        : (
                            cashCollected -
                            (discountValue === 0
                              ? totalAmount
                              : grandTotalAmount)
                          ).toFixed(2) + " SAR"}
                    </span>
                  </h4>
                )}
              <Form
                name="checkout-form"
                layout="vertical"
                onFinish={handleCheckout}
                style={{ width: "100%", margin: "0" }}
                form={checkoutForm}
                initialValues={checkoutFormInitialValues}
              >
                <Form.Item name="payment_type">
                  <Radio.Group
                    onChange={(e) => setSelectedPaymentType(e.target.value)}
                    size="small"
                  >
                    <Space size={[20, 20]} wrap>
                      <Radio.Button
                        style={{ height: "auto" }}
                        value={TRANSACTION_TYPE_CASH}
                      >
                        <img
                          src="/images/payment_type_cash.png"
                          alt="Payment Type Cash"
                        />
                      </Radio.Button>
                      <Radio.Button
                        style={{ height: "auto" }}
                        value={TRANSACTION_TYPE_CREDIT}
                      >
                        <img
                          src="/images/payment_type_cc.png"
                          alt="Payment Type Credit Card"
                        />
                      </Radio.Button>
                      <Radio.Button
                        style={{ height: "auto" }}
                        value={TRANSACTION_TYPE_MULTI}
                      >
                        <span style={{ display: "flex", alignItems: "center" }}>
                          <img
                            src="/images/payment_type_cash.png"
                            alt="Payment Type Cash"
                          />
                          +
                          <img
                            src="/images/payment_type_cc.png"
                            alt="Payment Type Credit Card"
                          />
                        </span>
                      </Radio.Button>
                    </Space>
                  </Radio.Group>
                </Form.Item>
                <Row
                  gutter={[20, 20]}
                  style={{ marginBottom: "10px", marginTop: "20px" }}
                >
                  <Col span={12}>
                    {branchDiscounts.length > 0 &&
                      authUser.allow_discount === true && (
                        <div
                          style={{
                            display: "flex",
                            columnGap: "15px",
                            marginBottom: "15px",
                          }}
                        >
                          <Dropdown
                            trigger={["click"]}
                            menu={{
                              items: branchDiscounts.map((branch) => ({
                                key: branch.id,
                                label: (
                                  <div
                                    onClick={() => {
                                      handleDiscount(branch.id);
                                    }}
                                  >
                                    {branch.discount_percentage} %
                                  </div>
                                ),
                              })),
                            }}
                          >
                            <Button style={{ width: "100%" }}>
                              {discountValue === 0
                                ? t("select_a_discount")
                                : discountValue + " %"}
                              <DownOutlined />
                            </Button>
                          </Dropdown>
                          {discountValue !== 0 && (
                            <Button onClick={discountReset} danger>
                              <CloseOutlined />
                            </Button>
                          )}
                        </div>
                      )}

                    {selectedPaymentType === TRANSACTION_TYPE_MULTI && (
                      <div style={{ display: "flex", columnGap: "20px" }}>
                        <Form.Item
                          name="collected_by_cash_multi"
                          label={t("collected_by_cash")}
                          rules={[
                            {
                              required: true,
                              message: t("validate_cash_required"),
                            },
                          ]}
                          style={{ width: "100%" }}
                        >
                          <Input
                            type="number"
                            onChange={handleCashMultiPayment}
                            onFocus={() =>
                              handleSelectField("collected_by_cash_multi")
                            }
                          />
                        </Form.Item>
                        <Form.Item
                          name="collected_by_card_multi"
                          label={t("collected_by_card")}
                          rules={[
                            {
                              required: true,
                              message: t("validate_card_required"),
                            },
                          ]}
                          style={{ width: "100%" }}
                        >
                          <Input
                            type="number"
                            onChange={handleCardMultiPayment}
                            onFocus={() =>
                              handleSelectField("collected_by_card_multi")
                            }
                          />
                        </Form.Item>
                      </div>
                    )}

                    <Form.Item
                      name="dynamic_field"
                      rules={[
                        {
                          required: true,
                          message:
                            selectedPaymentType === TRANSACTION_TYPE_CASH
                              ? t("validate_cash_required")
                              : t("validate_reference_required"),
                        },
                      ]}
                      label={
                        selectedPaymentType === TRANSACTION_TYPE_CASH
                          ? t("cash_collected")
                          : t("reference_no")
                      }
                    >
                      <Input
                        onChange={(e) => {
                          setCashCollected(e.target.value);
                        }}
                        onFocus={() => handleSelectField("dynamic_field")}
                      />
                    </Form.Item>

                    {is_customer_management_active &&
                      authUser.can_view_customer && (
                        <div
                          style={{
                            display: "flex",
                            alignItems: "flex-end",
                            columnGap: "15px",
                            marginBottom: "15px",
                          }}
                        >
                          <Form.Item
                            name="customer_id"
                            label={t("customer_name")}
                            hasFeedback
                            style={{ width: "100%" }}
                          >
                            <Select
                              showSearch
                              style={{
                                width: "100%",
                                height: "40px",
                                paddingTop: "5px",
                              }}
                              onChange={handleCustomerSelect}
                              options={customerData.map((customer) => {
                                return {
                                  label: customer.name_ar,
                                  value: customer.id,
                                };
                              })}
                              filterOption={(input, option) =>
                                (option?.label ?? "")
                                  .toLowerCase()
                                  .includes(input.toLowerCase())
                              }
                            />
                          </Form.Item>
                          {selectedCustomer && (
                            <Button
                              onClick={resetSelectedCustomer}
                              danger
                              style={{
                                marginBottom: "10px",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              <CloseOutlined />
                            </Button>
                          )}
                          <AddCustomer
                            reloadCallback={() =>
                              setReloadCustomerData(!reloadCustomerData)
                            }
                          />
                        </div>
                      )}
                    <Form.Item
                      name="buyer_company_name"
                      label={t("buyer_company_name")}
                    >
                      <Input
                        disabled={
                          is_customer_management_active
                            ? authUser.can_view_customer
                              ? true
                              : false
                            : false
                        }
                      />
                    </Form.Item>

                    <Form.Item
                      name="buyer_company_vat"
                      label={t("buyer_company_vat")}
                      rules={[
                        {
                          min: 15,
                          message: t("validate_vat_number_exact_limit"),
                        },
                        {
                          max: 15,
                          message: t("validate_vat_number_exact_limit"),
                        },
                      ]}
                      hasFeedback
                    >
                      <Input
                        disabled={
                          is_customer_management_active
                            ? authUser.can_view_customer
                              ? true
                              : false
                            : false
                        }
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Row gutter={[20, 20]} style={{ direction: "ltr" }}>
                      {[1, 2, 3, 4, 5, 6, 7, 8, 9, ".", 0, "Clear"].map(
                        (num) => (
                          <Col key={num} span={8}>
                            <Button
                              block
                              style={{ height: "50px" }}
                              onClick={() => handleButtonClick(num)}
                            >
                              {num === "Clear"
                                ? appConfig.direction === "ltr"
                                  ? num
                                  : t("clear")
                                : num}
                            </Button>
                          </Col>
                        )
                      )}
                    </Row>
                  </Col>
                </Row>
              </Form>
            </Col>

            <Col span={8}>
              <strong>{t("selected_item")} :</strong>
              {cartData?.items &&
                (cartData?.items).map((item) => (
                  <div
                    style={{
                      padding: "10px",
                      borderBottom: "1px solid #ebebeb",
                    }}
                    key={item.id}
                  >
                    <div style={{ display: "flex", columnGap: "10px" }}>
                      <img
                        alt={item.name_en}
                        src={
                          item.image
                            ? item.image
                            : "/images/image-not-found.png"
                        }
                        style={{
                          width: "50px",
                          height: "auto",
                          cursor: "pointer",
                          borderRadius: "1rem",
                          border: "1px solid #ebebeb",
                        }}
                      />
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "calc(100% - 100px)",
                        }}
                      >
                        <div
                          style={{
                            fontSize: "14px",
                            fontWeight: "600",
                            marginBottom: "10px",
                          }}
                        >
                          {item.quantity} x{" "}
                          {appConfig.direction === "rtl"
                            ? item.name
                            : item.name_en}
                        </div>
                        <div
                          style={{
                            fontSize: "12px",
                            lineHeight: "1",
                          }}
                        >
                          <div>
                            <CurrencyFormatter value={item.price} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            </Col>
          </Row>
        ) : (
          <div style={{ width: "500px" }}>
            {returnAmountMsg && (
              <Alert message={returnAmountMsg} type="info" showIcon />
            )}
            <TransactionSlip
              ref={transactionSlipRef}
              transaction={transaction}
              shop={{
                name: authUser.business_name,
                vat: authUser.vat,
                logo: authUser.logo,
                is_vat_exempt: authUser.is_vat_exempt,
              }}
            />
          </div>
        )}
        <Space>
          <Button
            key="proceed"
            type="primary"
            onClick={handleCheckout}
            style={transaction ? { display: "none" } : {}}
          >
            {t("proceed")}
          </Button>
          {transaction && (
            <ReactToPrint
              trigger={() => (
                <Button key="print" type="primary">
                  {t("print")}
                </Button>
              )}
              content={() => transactionSlipRef.current}
            />
          )}
          <Button key="back" onClick={() => window.history.back()}>
            {t("back")}
          </Button>
        </Space>
      </Card>
    </>
  );
};

export default Checkout;
