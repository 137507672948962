import "./style.css";
import React, { useEffect, useState } from "react";
import { getQRproducts } from "../../../network/network";
import QRmenu from "./QRmenu";
import { message } from "antd";

const QRordering = () => {
  const [products, setProducts] = useState([]);
  const [companyDetails, setCompanyDetails] = useState([]);

  useEffect(() => {
    if (window.location.search !== "") {
      let params = new URLSearchParams(window.location.search);
      if (
        params.get("src") &&
        params.get("table") &&
        params.get("is_drive_thru")
      ) {
        localStorage.setItem("src", params.get("src"));
        localStorage.setItem("table", params.get("table"));
        localStorage.setItem("is_drive_thru", params.get("is_drive_thru"));
        getQRproducts({ src: params.get("src"), table: params.get("table") })
          .then((response) => {
            let data = response.data || response.response.data;
            if (data.success === true) {
              setProducts(response.data.data.categories_products);
              setCompanyDetails(response.data.data);
            } else {
              // Error
              message.error(data.message);
            }
          })
          .finally(() => {});
      }
    }
  }, []);

  return (
    <>
      {products.length > 0 && (
        <QRmenu categoriesProducts={products} companyDetails={companyDetails} />
      )}
    </>
  );
};

export default QRordering;
