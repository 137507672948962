import "../AuthStyle.css";
import { Row, Col, Form, Input, message, Button, Radio } from "antd";
import { useContext } from "react";
import { AppContext } from "../../../context/AppContextProvider";
import { userLogin } from "../../../network/network";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  USER_TYPE_BUSINESS_OWNER,
  USER_TYPE_EMPLOYEE,
} from "../../../configs/constants";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

const Login = () => {
  const { appConfig, setAppConfigWrapper } = useContext(AppContext);
  const { setAuthUser, setFullPageLoading } = useContext(AppContext);
  let navigate = useNavigate();
  const { t } = useTranslation();

  const onFinish = (values) => {
    setFullPageLoading(true);
    userLogin(values)
      .then((response) => {
        if (
          response.data &&
          response.data.success &&
          (response.data.data.user.type === USER_TYPE_BUSINESS_OWNER ||
            response.data.data.user.type === USER_TYPE_EMPLOYEE)
        ) {
          localStorage.setItem(
            "access_token",
            response.data.data.user.access_token
          );
          setAuthUser(response.data.data.user);
          navigate("/home");
        } else {
          let error = response.response;
          if (error?.status === 422) {
            message.error(error.data.message);
          } else if (error?.status === 500) {
            let errorMessages = Object.values(error.data.data.errors);
            errorMessages.forEach(function (key, value) {
              let temp = Object.values(key);
              message.error(temp[0]);
            });
          } else {
            message.error(t("something_went_wrong"));
          }
        }
      })
      .finally(() => {
        setFullPageLoading(false);
      });
  };
  return (
    <div className="authSection ">
      <div className="lang_button">
        <Radio.Group
          value={appConfig.direction}
          onChange={(e) => {
            setAppConfigWrapper({ direction: e.target.value });
          }}
          buttonStyle="solid"
          defaultValue="ltr"
        >
          <Radio.Button value="ltr">English</Radio.Button>
          <Radio.Button value="rtl">عربي</Radio.Button>
        </Radio.Group>
      </div>
      <div style={{ width: "100%" }}>
        <Row>
          <Col
            xs={24}
            sm={24}
            md={6}
            lg={8}
            xl={8}
            xxl={8}
            className="authSection-left"
          >
            <img src="/images/pos-on-any-device.jpg" alt="AnyPOS Logo" />
            <div>
              <p>{t("dont_have_account")}</p>
              <Link to="/register">
                <Button>{t("sign_up")} </Button>
              </Link>
              <div style={{ marginTop: "20px" }}>
                <a href="https://anypos.app/" target="_blank" rel="noreferrer">
                  <Button type="primary">{t("visit_website")}</Button>
                </a>
              </div>
            </div>
          </Col>
          <Col
            xs={24}
            sm={24}
            md={18}
            lg={16}
            xl={16}
            xxl={16}
            className="authSection-right"
          >
            <Form
              name="login-user-form"
              layout="vertical"
              onFinish={onFinish}
              style={{
                maxWidth: "300px",
                Width: "100%",
                margin: "20px auto 0",
              }}
            >
              <h2 className="authSection-heading">{t("login")}</h2>
              <Form.Item
                name="phone"
                label={t("phone_number") + " eg: +966 5xxxxxxx"}
                rules={[
                  {
                    required: true,
                    message: t("validate_phone_required"),
                  },
                  {
                    min: 12,
                    message: t("validate_phone_exact_limit"),
                  },
                  {
                    max: 12,
                    message: t("validate_phone_exact_limit"),
                  },
                ]}
              >
                <PhoneInput
                  localization={["sa"]}
                  country={"sa"}
                  onlyCountries={["sa"]}
                  masks={{ sa: "(...) ..-..-.." }}
                  placeholder="+966 (123) 45-67-89"
                  style={{
                    width: "100%",
                    direction: "ltr",
                  }}
                />
              </Form.Item>
              <Form.Item
                label={t("password")}
                name="password"
                rules={[
                  {
                    required: true,
                    message: t("validate_password_required"),
                  },
                ]}
              >
                <Input.Password />
              </Form.Item>
              <Form.Item style={{ margin: "20px 0" }}>
                <Button size="large" block type="primary" htmlType="submit">
                  {t("login")}
                </Button>
              </Form.Item>
            </Form>
            <center>
              <Link to="/forgot">{t("forgot_password")}</Link>
            </center>{" "}
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Login;
