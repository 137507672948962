import { Pagination } from "antd";
import { useEffect, useState } from "react";
import { getClosedTickets } from "../network/network";
import TicketsTable from "./TicketsTable";

const ClosedTickets = () => {
  const [tickets, setTickets] = useState([]);
  const [loading, setLoading] = useState(false);
  const [paginationPage, setPaginationPage] = useState(1);
  const [paginationData, setPaginationData] = useState([]);

  useEffect(() => {
    setLoading(true);
    getClosedTickets(paginationPage)
      .then((response) => {
        setTickets(response.data.data.tickets);
        setPaginationData(response.data.data.pagination);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [paginationPage]);

  const handlePagination = (page, pageSize) => {
    setPaginationPage(page);
  };

  return (
    <>
      <TicketsTable tickets={tickets} loading={loading} />
      {paginationData.current_page &&
        paginationData.count > 0 &&
        paginationData.total && (
          <Pagination
            Current={paginationData.current_page}
            total={paginationData.total}
            pageSize={paginationData.per_page}
            onChange={handlePagination}
            showSizeChanger={false}
            style={{
              marginTop: "20px",
              textAlign: "center",
            }}
          />
        )}
    </>
  );
};

export default ClosedTickets;
