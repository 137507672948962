import { USER_TYPE_EMPLOYEE } from "./configs/constants";
import Forgot from "./pages/Auth/Forgot/Forgot";
import Login from "./pages/Auth/Login/Login";
import Register from "./pages/Auth/Register/Register";
import QRcart from "./pages/Content/QRorders/QRcart";
import QRorderHistory from "./pages/Content/QRorders/QRorderHistory";
import QRordering from "./pages/Content/QRorders/QRordering";
import TransactionsInvoice from "./pages/Content/Transactions/TransactionsInvoice";

export const currencyFormatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "SAR",
});

export const findPercentage = (discount, amount) => {
  return (discount / 100) * amount;
};

export const filterPagesByAddons = (ContentPages, authUser) => {
  if (authUser) {
    let TempContentPages;

    // if (authUser.active_addons && authUser.active_addons.length > 0) {
    TempContentPages = ContentPages.filter((page) => {
      // If addonName is not defined or null, always include the page
      if (page.addonName === undefined || page.addonName === null) {
        return true;
      }
      // If addonName is defined and not null, only include the page if the addonName exists in authUser.active_addons
      return authUser.active_addons.some(
        (addon) => addon.name === page.addonName
      );
    });
    // } else {
    //   TempContentPages = ContentPages;
    // }

    if (!authUser.can_see_transactions) {
      TempContentPages = TempContentPages.filter((page) => {
        if (page.name === "transactions") {
          return false;
        }
        return true;
      });
    }
    if (!authUser.can_view_customer) {
      TempContentPages = TempContentPages.filter((page) => {
        if (page.name === "customers") {
          return false;
        }
        return true;
      });
    }
    if (!authUser.can_view_sales_invoice) {
      TempContentPages = TempContentPages.filter((page) => {
        if (page.name === "sales_invoice") {
          return false;
        }
        return true;
      });
    }
    if (
      authUser &&
      authUser.features &&
      !authUser.features.some((feature) => {
        return feature.title === "External Integration" && feature.status === 1;
      })
    ) {
      TempContentPages = TempContentPages.filter((page) => {
        if (page.name === "external-integration") {
          return false;
        }
        return true;
      });
    }

    return TempContentPages.filter((page) => {
      if (
        authUser.type !== USER_TYPE_EMPLOYEE &&
        [
          "register-confirmed",
          "home",
          "pos",
          "checkout",
          "orders",
          "view_order",
          "edit_order",
          "transactions",
          "products_list",
          "products",
          "categories",
          "units",
          "inventory",
          "stock",
          "stock-adjustment",
          "view_stock_adjustment",
          "stock-transfer",
          "view_transfer",
          "edit_transfer",
          "branches",
          "tables",
          "customers",
          "view_customer",
          "sales_invoice",
          "view_sales_invoice",
          "employees",
          "discounts",
          "helpdesk",
          "reports",
          "sales-summary",
          "sales-by-items",
          "sales-by-categories",
          "refunds-by-items",
          "refunds-by-categories",
          "report-by-branch",
          "store-configuration",
          "subscriptions",
          "invoices",
          "devices",
          "payments",
          "addons",
          "addon_detail",
          "external-integration",
        ].includes(page.name)
      ) {
        return true;
      } else if (
        authUser.type === USER_TYPE_EMPLOYEE &&
        [
          "home",
          "pos",
          "checkout",
          "orders",
          "view_order",
          "edit_order",
          "transactions",
          "products_list",
          "products",
          "inventory",
          "stock",
          "stock-adjustment",
          "view_stock_adjustment",
          "stock-transfer",
          "view_transfer",
          "edit_transfer",
          "tables",
          "customers",
          "view_customer",
          "sales_invoice",
          "view_sales_invoice",
          "helpdesk",
          "reports",
          "sales-summary",
          "sales-by-items",
          "sales-by-categories",
          "refunds-by-items",
          "refunds-by-categories",
        ].includes(page.name)
      ) {
        return true;
      }
      return false;
    });
  } else {
    return [
      {
        path: "/login",
        name: "login",
        text: "login",
        component: Login,
        icon: false,
        showInSider: false,
      },
      {
        path: "/register",
        name: "register",
        text: "register",
        component: Register,
        icon: false,
        showInSider: false,
      },
      {
        path: "/forgot",
        name: "forgot",
        text: "forgot",
        component: Forgot,
        icon: false,
        showInSider: false,
      },
      {
        path: "/transactions/invoice",
        name: "transactions-invoice",
        text: "transactions_invoice",
        component: TransactionsInvoice,
        icon: false,
        showInSider: false,
      },
      {
        path: "*",
        name: "register",
        text: "register",
        component: Register,
        icon: false,
        showInSider: false,
      },
      {
        path: "/qr/ordering",
        name: "qr-ordering",
        text: "qr_ordering",
        component: QRordering,
        icon: false,
        showInSider: false,
      },
      {
        path: "/qr/cart",
        name: "qr-cart",
        text: "qr_cart",
        component: QRcart,
        icon: false,
        showInSider: false,
      },
      {
        path: "/qr/history",
        name: "qr-history",
        text: "qr_history",
        component: QRorderHistory,
        icon: false,
        showInSider: false,
      },
    ];
  }
};
